import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch, useStatus } from 'hooks';

import { getIncomingPods, actionTypes } from 'actions/incomingPodActions';

import EmptyState from 'components/common/EmptyState';
import PodsList from 'components/common/PodsList';
import IncomingPendingPodItems from 'components/pods/IncomingPendingPodItems';
import GeneralDataHandler from 'components/common/GeneralDataHandler';
import generalStyles from 'components/common/GeneralDataHandler/GeneralDataHandler.scss';
import GeneralPodItems from 'components/common/GeneralPodItems';
import SortSelect from 'components/pods/SortSelect';
import { POD_STATUS, OPENING_DATE_FILTER } from 'constants/constants';

import { sortByDateAttribute } from 'utils/helpers';
import messages from './constants';

const IncomingPodsComp = incomingPodsList => {
  const [pendingPods, setPendingPods] = useState(incomingPodsList?.pendingPods);
  const [openedPods, setOpenedPods] = useState(incomingPodsList?.openedPods);
  const [closedPods, setClosedPods] = useState(incomingPodsList?.closedPods);

  const handleSortChange = useCallback(
    newValue => {
      const sortedPending = [...pendingPods];
      const sortedOpened = [...openedPods];
      const sortedClosed = [...closedPods];

      const ascending = newValue.value === OPENING_DATE_FILTER;
      sortedPending.sort((a, b) => sortByDateAttribute(a, b, newValue.value, ascending));
      sortedOpened.sort((a, b) => sortByDateAttribute(a, b, newValue.value, ascending));
      sortedClosed.sort((a, b) => sortByDateAttribute(a, b, newValue.value, ascending));

      setPendingPods(sortedPending);
      setOpenedPods(sortedOpened);
      setClosedPods(sortedClosed);
    },
    [closedPods, openedPods, pendingPods]
  );

  const hasPods = pendingPods?.length || closedPods?.length || openedPods?.length;

  return (
    <div className={`${generalStyles.listContainer} container`}>
      <div className="d-flex justify-content-flex-start">
        <p className="text-gray text-weight-medium my-auto pr-3">SORT BY</p>
        <SortSelect handleChange={handleSortChange} placeholder="" />
      </div>

      {hasPods ? (
        <>
          {pendingPods && (
            <PodsList
              title="NEW PODS"
              list={pendingPods}
              ListComponent={IncomingPendingPodItems}
              status={POD_STATUS.NEW}
            />
          )}
          {openedPods && (
            <PodsList
              title="OPENED PODS"
              list={openedPods}
              ListComponent={GeneralPodItems}
              status={POD_STATUS.OPENED}
            />
          )}
          {closedPods && (
            <PodsList
              title="SEALED PODS"
              list={closedPods}
              ListComponent={GeneralPodItems}
              status={POD_STATUS.CLOSED}
            />
          )}
        </>
      ) : (
        <EmptyState
          className="mt-9 mt-md-12"
          title={messages.empty}
          subtitle={messages.emptySubtitle}
        />
      )}
    </div>
  );
};

const IncomingPods = () => {
  const incomingPodsList = useSelector(({ pods: { incomingPods } }) => incomingPods);

  const { status } = useStatus(actionTypes.GET_INCOMING_PODS);

  const incomingPods = useDispatch(getIncomingPods);
  useEffect(() => {
    incomingPods();
  }, [incomingPods]);

  return (
    <GeneralDataHandler
      status={status}
      SuccessComponent={() => IncomingPodsComp(incomingPodsList)}
    />
  );
};

export default IncomingPods;
