import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { useDispatchOnMount, useStatus } from 'hooks';

import { getIncomingPod, actionTypes } from 'actions/incomingPodActions';

import Header from 'components/common/Header';
import IncomingPod from 'components/pods/IncomingPod';
import GeneralDataHandler from 'components/common/GeneralDataHandler';
import { LABELS as LABELS_HOME } from 'pages/HomePage/constants';

import { replaceHomeTab } from 'utils/tabHelpers';

const OpenPodPage = () => {
  const { state: { selectedPodId } = {} } = useLocation();
  const { push } = useHistory();

  useDispatchOnMount(getIncomingPod, selectedPodId);
  const pod = useSelector(state => state.pods.incomingPod) || {};
  const { status } = useStatus(actionTypes.GET_INCOMING_POD);

  if (!selectedPodId) push(replaceHomeTab(LABELS_HOME.incoming));

  return (
    <div className="bg-gray min-vh-100 position-relative d-flex flex-column">
      <Header />
      <div className="container py-4 flex-grow-1 d-flex flex-column">
        <GeneralDataHandler
          status={status}
          SuccessComponent={IncomingPod}
          successComponentProps={{ pod }}
        />
      </div>
    </div>
  );
};

export default OpenPodPage;
