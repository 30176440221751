import React from 'react';

import cn from 'classnames';

import { Inputs } from 'components/common/Input';
import Button from 'components/common/Button';

import { checkPromoCode } from 'actions/podActions';

import { useTextInputProps, useDispatch } from 'hooks';

import styles from './ReviewOrder/ButtonPanel.scss';

const PromoCode = ({ error, onBlur, onChange, status, values }) => {
  const inputTextProps = useTextInputProps(onChange, onBlur, values);

  const checkPromo = useDispatch(checkPromoCode);

  const { promoCode } = values;

  return (
    <div className="row mb-4">
      <div className="col-lg-8">
        <Inputs
          customError={status === 'ERROR' && [error]}
          input={{ ...inputTextProps('promoCode') }}
          label="Enter discount code here:"
          type="text"
        />
      </div>
      <div className="col-lg-4">
        <Button
          className={cn(styles.button)}
          disabled={!promoCode}
          onClick={e => {
            e.preventDefault();
            checkPromo(promoCode);
          }}
          styleType="primary"
          type="submit"
        >
          Use code
        </Button>
      </div>
    </div>
  );
};

export default PromoCode;
