import React from 'react';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';

import { TIMEZONES, MOMENT_TIMEZONES } from 'constants/constants';
import { CREATEPOD_FIELDS } from 'constants/fields';

export const messages = {
  [CREATEPOD_FIELDS.date]: {
    label: 'Date',
    placeholder: 'MM/DD/YYYY',
    icon: <Calendar />
  },
  [CREATEPOD_FIELDS.time]: {
    label: 'Hour'
  }
};

export const timezones = [
  { value: MOMENT_TIMEZONES[TIMEZONES.CST], label: TIMEZONES.CST },
  { value: MOMENT_TIMEZONES[TIMEZONES.EST], label: TIMEZONES.EST },
  { value: MOMENT_TIMEZONES[TIMEZONES.PST], label: TIMEZONES.PST }
];
