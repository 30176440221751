import routes, { DEFAULT_CODE } from 'constants/routesPaths';

export const replaceHomeTab = tab => routes.home.replace(':tab', tab);

export const existsTab = (tab, allTabs) => {
  const keys = Object.keys(allTabs);
  return keys.some(key => allTabs[key] === tab);
};

export const replaceLandingTab = (tab, code = DEFAULT_CODE) => {
  return routes.campaign.replace(':code', code).replace(':tab?', tab);
};
