import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { createPodStatusReset, actionTypes } from 'actions/podActions';
import { useDispatch, useStatus, useAuth } from 'hooks';
import Header from 'components/common/Header';
import CreatePodWizard from 'components/createPod/CreatePodWizard';
import { messages } from 'components/createPod/ButtonPanel/constants';
import CreatePodProcessPage from 'pages/CreatePodProcessPage';
import routes from 'constants/routesPaths';
import { SUCCESS, ERROR } from 'constants/status';

const CreatePod = () => {
  const { status, error } = useStatus(actionTypes.CREATE);
  const resetCreatePodStatus = useDispatch(createPodStatusReset);
  const authenticated = useAuth();
  const uploadProgress = useSelector(({ pods }) => pods.uploadProgress);

  useEffect(() => {
    if (status === ERROR) {
      alert(error);
    }
    if (status === SUCCESS || status === ERROR) {
      resetCreatePodStatus();
    }
  }, [resetCreatePodStatus, error, status]);

  if (uploadProgress > 0 && status !== ERROR) {
    return <CreatePodProcessPage progress={uploadProgress} />;
  }

  if (status === SUCCESS) {
    return <Redirect to={routes.createSuccess} />;
  }

  if (
    !authenticated &&
    !!localStorage.getItem('register_token') &&
    !!localStorage.getItem('pod_id')
  ) {
    return <Redirect to={routes.signUp} />;
  }

  return (
    <div className="bg-gray d-flex flex-column min-vh-100">
      <Header confirm={messages.confirmationAlert} />
      <div className="container flex-fill d-lg-flex py-4">
        <CreatePodWizard />
      </div>
    </div>
  );
};

export default CreatePod;
