import React from 'react';
import { number } from 'prop-types';

const Business = ({ width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 148 148"
    {...props}
  >
    <path fill="#fff" d="M34 73H114V111H34z" />
    <path fill="#fff" d="M32 50h84v23c-19.5 5-29.701 9-42.5 9S45 76 32 73V50z" />
    <path
      fill="#2390F3"
      d="M84.57 34.353H63.637a5.87 5.87 0 00-5.864 5.863v2.695a1.719 1.719 0 103.438 0v-2.695a2.43 2.43 0 012.426-2.426H84.57a2.43 2.43 0 012.427 2.426v2.695a1.719 1.719 0 103.437 0v-2.695a5.87 5.87 0 00-5.864-5.863zM100.323 65.977a1.72 1.72 0 100-3.438 1.72 1.72 0 000 3.438zM47.055 65.977a1.719 1.719 0 100-3.438 1.719 1.719 0 000 3.438z"
    />
    <path fill="#C9ECFB" d="M69 79H79V89H69z" />
    <path
      fill="#2390F3"
      d="M109.609 48.239H38.391c-4.627 0-8.391 3.764-8.391 8.391v15.263c0 .762.502 1.433 1.233 1.649l1.047.308v36.006c0 2.091 1.7 3.792 3.791 3.792h75.858a3.796 3.796 0 003.791-3.792V74.104l1.038-.3A1.72 1.72 0 00118 72.153V56.63c0-4.627-3.764-8.391-8.391-8.391zM45.337 110.21h-9.266a.354.354 0 01-.354-.354v-9.266h4.944a4.682 4.682 0 014.676 4.677v4.943zm66.945-.354a.353.353 0 01-.353.354h-9.266v-4.943a4.682 4.682 0 014.676-4.677h4.943v9.266zm.001-12.703h-4.944c-4.474 0-8.114 3.64-8.114 8.114v4.943h-50.45v-4.943c0-4.474-3.64-8.114-8.114-8.114h-4.944V74.864l30.347 8.95v5.523c0 .95.769 1.719 1.718 1.719h12.436c.95 0 1.719-.77 1.719-1.719V83.86l30.346-8.764v22.057zm-42.782-9.535v-5.105-3.271h8.998v8.376h-8.998zm45.061-16.758l-32.626 9.422v-2.759c0-.949-.769-1.718-1.718-1.718H67.782c-.95 0-1.719.77-1.719 1.718v2.708l-32.626-9.623V56.63a4.96 4.96 0 014.954-4.954h6.946v5.95a1.719 1.719 0 103.438 0v-5.95h49.828v5.95a1.719 1.719 0 103.438 0v-5.95h7.568a4.96 4.96 0 014.953 4.954v14.23z"
    />
  </svg>
);

Business.propTypes = {
  height: number,
  width: number
};

Business.defaultProps = {
  height: 148,
  width: 148
};

export default Business;
