import { useCallback } from 'react';

export default (handleValueChange, handleBlur, values) =>
  useCallback(
    fieldKey => ({
      value: values[fieldKey],
      onChange: e => handleValueChange(fieldKey, e.target.checked),
      onBlur: () => handleBlur(fieldKey)
    }),
    [handleBlur, handleValueChange, values]
  );
