import React, { memo } from 'react';
import { string } from 'prop-types';
import cn from 'classnames';

import styles from './Description.scss';

const Description = ({ description, title }) => (
  <div className={cn(styles.container, 'pb-4')}>
    <h1 className="text-h2">{title}</h1>
    <p className={cn(styles.description, 'text-gray pt-2')}>{description}</p>
  </div>
);

Description.propTypes = {
  description: string,
  title: string
};

Description.defaultProps = {
  description: '',
  title: ''
};

export default memo(Description);
