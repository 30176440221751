import React from 'react';
import Home from 'components/common/Icons/Home';
import UploadCloud from 'components/common/Icons/UploadCloud';
import DownloadCloud from 'components/common/Icons/DownloadCloud';
import People from 'components/common/Icons/People';
import Gift from 'components/common/Icons/Gift';
import MyPods from 'components/common/Icons/MyPods';

import { primaryBlue } from 'styles/_constants.scss';

export const LABELS = {
  timeLine: 'timeLine',
  outgoing: 'outgoing',
  incoming: 'incoming',
  gift: 'gift',
  mypods: 'mypods',
  contacts: 'contacts'
};

export const TABS = [
  {
    id: LABELS.timeLine,
    label: 'Timeline',
    icon: <Home className="text-gray" />,
    focusedIcon: <Home color={primaryBlue} />
  },
  {
    id: LABELS.outgoing,
    label: 'Outgoing Pods',
    icon: <UploadCloud className="text-gray" />,
    focusedIcon: <UploadCloud color={primaryBlue} />
  },
  {
    id: LABELS.incoming,
    label: 'Incoming Pods',
    icon: <DownloadCloud className="text-gray" />,
    focusedIcon: <DownloadCloud color={primaryBlue} />
  },
  {
    id: LABELS.gift,
    label: 'Gift',
    icon: <Gift className="text-gray" />,
    focusedIcon: <Gift color={primaryBlue} />
  },
  {
    id: LABELS.mypods,
    label: 'Available Pods',
    icon: <MyPods className="text-gray" />,
    focusedIcon: <MyPods color={primaryBlue} />
  },
  {
    id: LABELS.contacts,
    label: 'Contacts',
    icon: <People className="text-gray" />,
    focusedIcon: <People color={primaryBlue} />
  }
];
