import React, { memo } from 'react';
import cn from 'classnames';

import { string, oneOfType, element, node, func, oneOf, bool } from 'prop-types';

import { BUTTON_STYLES } from 'constants/constants';

import styles from './Button.scss';

const getStyle = type => styles[`${type}`];

const Button = ({ children, type, className, styleType, onClick, route, disabled, link }) => {
  const button = (
    <button
      type={type}
      className={cn(getStyle(styleType), className, { 'opacity-muted': disabled })}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
  if (type === 'link') {
    return (
      <a href={route} className="m-auto" {...link}>
        {button}
      </a>
    );
  }
  return button;
};

Button.propTypes = {
  children: oneOfType([element, node]).isRequired,
  onClick: func,
  type: oneOf(['button', 'link', 'submit']),
  route: string,
  className: string,
  styleType: oneOf(Object.keys(BUTTON_STYLES).map(k => BUTTON_STYLES[k])),
  disabled: bool
};

Button.defaultProps = {
  type: 'button',
  className: '',
  onClick: () => {},
  styleType: BUTTON_STYLES.PRIMARY
};

export default memo(Button);
