import React, { useState, useEffect, useCallback, memo } from 'react';
import { object, func, number } from 'prop-types';
import cn from 'classnames';

import { getExtraTime, actionTypes } from 'actions/podActions';
import { useDispatch, useLoading } from 'hooks';

import { ReactComponent as Minus } from 'assets/icons/minus.svg';

import { truncateDecimal } from 'utils/helpers';
import withStripe from 'components/hocs/withStripe';
import { Inputs } from 'components/common/Input';
import Arrow from 'components/common/Icons/Arrow';
import Button from 'components/common/Button';
import Plus from 'components/common/Icons/Plus';

import Description from 'components/createPod/Description';
import PaymentForm from 'components/createPod/PaymentForm';
import Loader from 'components/common/Loader';

import { MIN_MONTHS } from './constants';
import styles from './GetExtraTimeComp.scss';

const GetExtraTimeComp = ({ stripe, handleBack, goBack, podPrice, id, minimumCost }) => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [extraMonths, setExtraMonths] = useState(MIN_MONTHS);
  const [totalPrice, setTotalPrice] = useState(0);

  const basePodPrice = podPrice?.price;

  const requestLoading = useLoading(actionTypes.GET_EXTRA_TIME);

  const getPodPrice = useCallback(
    newPodPrice => (newPodPrice < minimumCost ? minimumCost : newPodPrice),
    [minimumCost]
  );

  useEffect(() => {
    setTotalPrice(getPodPrice(basePodPrice));
  }, [basePodPrice, getPodPrice]);

  const handleExtraMonths = useCallback(
    value => {
      const newValue = value !== '' ? +value : value;
      setExtraMonths(newValue);
      setTotalPrice(
        newValue === '' ? getPodPrice(basePodPrice) : getPodPrice(basePodPrice * newValue)
      );
    },
    [basePodPrice, setExtraMonths, getPodPrice]
  );

  const submitPaymentRequest = useDispatch(getExtraTime);

  const submitPayment = useCallback(() => {
    submitPaymentRequest({
      id,
      months: extraMonths,
      totalPrice,
      stripe
    });
  }, [id, extraMonths, stripe, totalPrice, submitPaymentRequest]);

  if (!basePodPrice) {
    return null;
  }

  return (
    <div className="p-md-8 flex-fill">
      <div className="row">
        <div className="col-12 col-md-6">
          <div>
            <a onClick={handleBack}>
              <Arrow type="left" width={20} height={50} backArrow />
            </a>
          </div>
          <div className="px-0 px-md-4">
            <Description
              title="Get extra time"
              description="Extend your pod for more months to avoid losing your data"
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="col-12 mt-6 mt-md-0">
            Select amount
            <label className="text-x-small text-weight-semibold d-block mb-2 mt-4">
              Extra months
            </label>
            <div className="row col-12 mb-4">
              <Inputs
                type="number"
                className={cn('w-25 mr-2', styles.hideArrows)}
                input={{
                  readOnly: true,
                  value: extraMonths,
                  onChange: e => handleExtraMonths(e.target.value),
                  min: MIN_MONTHS,
                  onBlur: () => extraMonths < MIN_MONTHS && handleExtraMonths(MIN_MONTHS)
                }}
              />
              <Button
                disabled={extraMonths === MIN_MONTHS}
                onClick={() => extraMonths > MIN_MONTHS && handleExtraMonths(extraMonths - 1)}
                type="submit"
                styleType="inverted"
                className="d-flex align-items-center px-3 mr-2"
              >
                <Minus />
              </Button>
              <Button
                onClick={() => handleExtraMonths(extraMonths + 1)}
                type="submit"
                styleType="inverted"
                className="d-flex align-items-center px-3 mr-2"
              >
                <Plus />
              </Button>
            </div>
            <p className="text-weight-semibold pb-4 pt-5 border-bottom">
              Total Price ${truncateDecimal(totalPrice, 2)}
            </p>
          </div>
          <PaymentForm setSubmitDisabled={setSubmitDisabled} className="pt-7" />
        </div>
      </div>
      <div className="row justify-content-center align-items-center mt-10">
        <a
          className="col-12 col-md-6 text-small text-underline d-block text-center text-md-left order-2 order-md-1"
          onClick={goBack}
        >
          Cancel
        </a>
        <div className="col-12 col-md-6 order-1 order-md-2">
          <div className="col pb-8 pb-md-0">
            <Button
              disabled={submitDisabled || requestLoading}
              onClick={submitPayment}
              type="submit"
              styleType="primary"
              className="col py-3"
            >
              {requestLoading ? <Loader className="mt-n4 mb-4" /> : 'Purchase extra time'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

GetExtraTimeComp.propTypes = {
  stripe: object,
  handleBack: func.isRequired,
  goBack: func.isRequired,
  id: number.isRequired,
  podPrice: object,
  minimumCost: number
};

export default memo(withStripe(GetExtraTimeComp));
