export default (query, namesParams) => {
  const params = new URLSearchParams(query);
  let valuesParams = {};
  namesParams.forEach(nameParam => {
    valuesParams = {
      ...valuesParams,
      [nameParam]: params.get(nameParam)
    };
  });
  return valuesParams;
};
