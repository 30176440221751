import createReducer from 'reducers/createReducer';
import { getCampaignSuccess } from 'actions/campaignActions';

const initialState = {
  campaign: null
};

export default createReducer(initialState, {
  [getCampaignSuccess]: (state, { campaign }) => {
    state.campaign = campaign;
  }
});
