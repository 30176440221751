import React, { memo, useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { createPod, getPodPricing } from 'actions/podActions';
import { getContacts } from 'actions/contactsActions';

import { useDispatch, useWizard, useDispatchOnMount, useLoading, usePriceForSize } from 'hooks';

import withStripe from 'components/hocs/withStripe';
import Arrow from 'components/common/Icons/Arrow';
import Loader from 'components/common/Loader';
import Paper from 'components/common/Paper';
import BasicInfoPodForm from 'components/createPod/BasicInfoPodForm';
import AddFiles from 'components/createPod/AddFiles';
import ButtonPanel from 'components/createPod/ButtonPanel';
import Description from 'components/createPod/Description';
import FreePod from 'components/createPod/FreePod';
import OpenDateForm from 'components/createPod/OpenDateForm';
import PaymentForm from 'components/createPod/PaymentForm';
// import Progress from 'components/createPod/Progress';
import RecipientsForm from 'components/createPod/RecipientsForm';
import ReviewOrder from 'components/createPod/ReviewOrder';
import Summary from 'components/createPod/Summary';
import PricingPlan from 'components/createPod/PricingPlan';
import { monthsFromToday, timeToString } from 'utils/dateHelpers';

import validators from 'validations/createPodValidations';
import { steps, INDEX, initialValues } from './constants';

const CreatePodWizard = ({ stripe }) => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [priceDisclaimer, setPriceDisclaimer] = useState(false);
  const [plan, setPlan] = useState('bundle');

  const numSteps = steps[plan].length;
  const formRef = useRef();

  const createPodRequest = useDispatch(createPod);

  useDispatchOnMount(getPodPricing);
  useDispatchOnMount(getContacts);

  const isLoadingPricing = useLoading(getPodPricing);
  const isLoadingContacts = useLoading(getContacts);

  const contacts = useSelector(({ contacts: { contactsList } }) => contactsList);
  const discount = useSelector(({ pods: { discount } }) => discount);
  const priceList = useSelector(({ pods }) => pods.pricings.sizeBased, shallowEqual);
  const bundlePods = useSelector(({ pods }) => pods.pricings.bundleBased);
  const giftPods = useSelector(({ pods }) => pods.actualPod);
  const giftPodsCount = useSelector(({ pods }) => pods.actualPodCount);
  const actualBundle = useSelector(({ pods }) => pods.actualBundle);
  const minimumCost = useSelector(({ pods: { minimumCost } }) => minimumCost);

  const actualPodId = {
    gift: giftPods?.id,
    bundle: actualBundle?.id
  }[plan];

  const submitPayment = useCallback(
    async values => {
      setSubmitDisabled(true);
      createPodRequest({
        ...values,
        size: values.files.totalSize,
        totalPrice: plan === 'bundle' ? 0 : totalPrice,
        stripe,
        actualPodId
      });
    },
    [createPodRequest, stripe, totalPrice, actualPodId]
  );
  const {
    values,
    errors,
    currentStep,
    handleValueChange,
    handleSubmit,
    handleBack,
    backToStep
  } = useWizard(
    {
      initialValues,
      numSteps,
      validators: validators[plan],
      onSubmit: submitPayment,
      validateOnBlur: true,
      formRef
    },
    [createPodRequest]
  );
  const {
    title,
    description,
    recipients,
    date,
    time,
    coverImage,
    files,
    checkViewableDays,
    checkNoRefunds,
    checkPodAccepted,
    promoCode,
    pricingPlan,
    bundlePod,
    email
  } = values;

  const selectedBundle = bundlePods?.find(item => item.id == bundlePod);

  const { title: stepTitle, description: stepDescription } = steps[plan][currentStep];

  const selectedPrice = usePriceForSize(files.totalSize, priceList);
  const timePeriod = timeToString(time.hour?.value, time.minutes?.value, time.period?.value);

  const datePrice = {
    value: { date, time: timePeriod, timezone: `(${time.timezone?.label})` },
    multiplicate: date ? monthsFromToday(date, timePeriod, time.timezone?.value) : 1
  };
  const discountPrice =
    discount?.type === 'amount' ? discount?.discount : discount?.percentage || 0;
  const sizePrice = useMemo(
    () => ({
      value: `${selectedPrice?.storageLimitInMegabytes}MB Pod`,
      price: selectedPrice?.price
    }),
    [selectedPrice]
  );

  useEffect(() => {
    const subTotalPrice =
      pricingPlan === 'size' ? sizePrice.price * datePrice.multiplicate : selectedBundle?.price;
    setSubTotalPrice(subTotalPrice);

    const diffDiscount =
      discount?.type === 'amount'
        ? subTotalPrice - discountPrice
        : subTotalPrice * (1 - discountPrice / 100);

    if (currentStep <= 1 && localStorage.getItem('pod_id') && giftPods?.id) {
      handleValueChange('pricingPlan', 'gift');
      setPlan('gift');
      handleSubmit(new Event(''));
    }

    if (diffDiscount <= 0) setTotalPrice(0);
    else if (pricingPlan === 'size')
      setTotalPrice(diffDiscount < minimumCost ? minimumCost : diffDiscount);
    else if (pricingPlan === 'gift') setTotalPrice(0);
    else if (pricingPlan === 'bundle' && bundlePod)
      setTotalPrice(diffDiscount < 0 ? 0 : diffDiscount);

    diffDiscount > 0 && diffDiscount < minimumCost
      ? setPriceDisclaimer(true)
      : setPriceDisclaimer(false);
  }, [
    minimumCost,
    datePrice.multiplicate,
    discount,
    discountPrice,
    sizePrice.price,
    pricingPlan,
    bundlePod
  ]);

  const reviewSteps = [
    {
      step: INDEX[pricingPlan].FILES,
      values: {
        size:
          pricingPlan === 'size'
            ? sizePrice
            : { value: `${giftPods.storageLimitInMegabytes / 1000}GB Pod`, price: 0 }
      }
    },
    {
      step: INDEX[pricingPlan].RECIPIENTS,
      values: {
        recipients: {
          value: recipients.map(({ email }) => ({
            email: email?.isNew ? email?.value : email?.value?.email || ''
          }))
        }
      }
    },
    {
      step: INDEX[pricingPlan].DATE,
      values: { date: datePrice }
    }
  ];

  const isLoading = isLoadingPricing || isLoadingContacts || !(bundlePods && bundlePods.length);

  const renderButtonPanel = className => (
    <ButtonPanel
      className={className}
      currentStep={currentStep}
      disabled={submitDisabled}
      setSubmitDisabled={setSubmitDisabled}
      lastStep={currentStep === numSteps - 1}
      onSubmit={handleSubmit}
      pricingPlan={pricingPlan}
      promoCode={promoCode}
      selectedBundle={selectedBundle}
      stripe={stripe}
      totalPrice={totalPrice}
      email={email}
    />
  );

  return (
    <div className="row flex-fill small-gutters">
      <a onClick={handleBack}>
        <div className="d-inline-flex d-md-none ml-3">
          <Arrow type="left" width={20} height={50} backArrow />
        </div>
      </a>
      {isLoading ? (
        <Loader type="blue" />
      ) : (
        <>
          <div className="col-12 order-2 order-lg-1 col-lg-9">
            <Paper className="h-100 d-flex flex-column">
              <div className="mx-8 mt-8 mb-1 flex-fill">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div>
                      <a onClick={handleBack}>
                        <div className="d-none d-md-inline-flex">
                          <Arrow type="left" width={20} height={50} backArrow />
                        </div>
                        {/* <Arrow className="" type="left" width={20} height={50} backArrow /> */}
                      </a>
                    </div>
                    <div className="px-0 px-md-4">
                      <Description title={stepTitle} description={stepDescription} />
                      {/* <Progress numSteps={numSteps} currentStep={currentStep} /> */}
                    </div>
                  </div>
                  <form ref={formRef} className="col-12 col-md-6">
                    {currentStep === INDEX[pricingPlan].SELECT && (
                      <PricingPlan
                        bundlePod={bundlePod}
                        bundlePods={bundlePods}
                        errors={errors}
                        giftPodsCount={giftPodsCount}
                        handleValueChange={handleValueChange}
                        pricingPlan={pricingPlan}
                        setPlan={setPlan}
                      />
                    )}
                    {currentStep === INDEX[pricingPlan].BASIC && (
                      <BasicInfoPodForm
                        onChange={handleValueChange}
                        values={{ title, description, coverImage }}
                        errors={errors}
                        setSubmitDisabled={setSubmitDisabled}
                      />
                    )}
                    {currentStep === INDEX[pricingPlan].FILES && (
                      <AddFiles
                        bundlePod={bundlePod}
                        onChange={handleValueChange}
                        values={{ files }}
                        errors={errors}
                        pricingPlan={pricingPlan}
                        setSubmitDisabled={setSubmitDisabled}
                      />
                    )}
                    {currentStep === INDEX[pricingPlan].RECIPIENTS && (
                      <RecipientsForm
                        onChange={handleValueChange}
                        values={{ recipients }}
                        contacts={contacts}
                        errors={errors}
                      />
                    )}
                    {currentStep === INDEX[pricingPlan].DATE && (
                      <OpenDateForm
                        onChange={handleValueChange}
                        values={{ date, time }}
                        errors={errors}
                      />
                    )}
                    {currentStep === INDEX[pricingPlan].REVIEW && (
                      <ReviewOrder
                        discount={discount && (discount.discount || discount.percentage)}
                        goBack={backToStep}
                        onChange={handleValueChange}
                        pricingPlan={pricingPlan}
                        setSubmitDisabled={setSubmitDisabled}
                        steps={reviewSteps}
                        values={{ checkViewableDays, checkNoRefunds, checkPodAccepted, promoCode }}
                      />
                    )}
                    {currentStep === INDEX[pricingPlan].PAYMENT &&
                      (totalPrice == 0 || pricingPlan === 'gift' ? (
                        <FreePod
                          currentStep={currentStep}
                          disabled={submitDisabled}
                          pricingPlan={pricingPlan}
                          promoCode={promoCode}
                          selectedBundle={selectedBundle}
                          stripe={stripe}
                          totalPrice={totalPrice}
                        />
                      ) : (
                        <PaymentForm
                          currentStep={currentStep}
                          disabled={submitDisabled}
                          discount={discount && (discount.discount || discount.percentage)}
                          errors={errors}
                          onChange={handleValueChange}
                          pricingPlan={pricingPlan}
                          promoCode={promoCode}
                          selectedBundle={selectedBundle}
                          setSubmitDisabled={setSubmitDisabled}
                          stripe={stripe}
                          totalPrice={totalPrice}
                          values={values}
                        />
                      ))}
                    {renderButtonPanel('d-md-none')}
                  </form>
                </div>
              </div>
              {renderButtonPanel('d-none d-md-inline px-8')}
            </Paper>
          </div>
          <div className="col-12 order-1 order-lg-2 col-lg-3 pb-4">
            {minimumCost && (
              <Summary
                size={
                  pricingPlan === 'size' || !bundlePod
                    ? sizePrice
                    : {
                        value: `Number of pod(s): ${selectedBundle.bundleSize}`,
                        price: selectedBundle.price
                      }
                }
                date={datePrice}
                discount={discount && (discount.discount || discount.percentage)}
                discountType={discount && discount.type}
                subTotal={subTotalPrice}
                total={totalPrice}
                priceDisclaimer={priceDisclaimer}
                minimumCost={minimumCost}
                pricingPlan={pricingPlan}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default memo(withStripe(CreatePodWizard));
