import React from 'react';
import { shape, string, bool } from 'prop-types';
import cn from 'classnames';

import { formatDateExtended } from 'utils/dateHelpers';

const Post = ({ event, className, last }) => {
  const { message, createdAt } = event;
  return (
    <div className={cn({ 'border-bottom': !last }, className)}>
      <p className="text-gray mb-2">{formatDateExtended(createdAt)}</p>
      <p className="overflow-wrap">{message}</p>
    </div>
  );
};

Post.propTypes = {
  last: bool,
  className: string,
  event: shape({
    message: string,
    createdAt: string
  }).isRequired
};

Post.defaultProps = {
  className: '',
  last: false
};

export default Post;
