import React, { memo } from 'react';
import { node, string } from 'prop-types';
import cn from 'classnames';

import styles from './Card.scss';

const Card = ({ children, className }) => (
  <div className={cn('bg-white', styles.shadow, className)}>{children}</div>
);

Card.propTypes = {
  children: node.isRequired,
  className: string
};

Card.defaultProps = {
  className: ''
};

export default memo(Card);
