import { SIGNUP_FIELDS } from 'constants/fields';

const { password, passwordConfirmation } = SIGNUP_FIELDS;

export default {
  [password]: {
    presence: true,
    length: { minimum: 8 }
  },
  [passwordConfirmation]: {
    presence: true,
    equality: { attribute: password }
  }
};
