import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { messages } from 'components/createPod/ButtonPanel/constants';
import routes from 'constants/routesPaths';
import validate from 'validations/validate';

const useWizard = ({ initialValues, numSteps, onSubmit, validators = [], formRef }) => {
  const { push } = useHistory();

  const [values, setValues] = useState({ ...initialValues });
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({});

  const validateFields = useCallback(values => validate(values, validators[currentStep]), [
    currentStep,
    validators
  ]);

  const isValid = errors => {
    const flatErrors = Object.values(errors).flat();
    const arrayErrors = [];
    flatErrors.forEach(obj => {
      Object.keys(obj).forEach(key => {
        if (obj[key]) arrayErrors.push(obj[key]);
      });
    });
    return !flatErrors.length || !arrayErrors.length;
  };

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      const newErrors = validateFields(values) || {};
      const valid = isValid(newErrors);
      setErrors(newErrors);
      if (valid) {
        if (currentStep === numSteps - 1) {
          onSubmit(values);
        } else {
          setCurrentStep(currentStep + 1);
        }
      } else {
        const invalidInput = formRef.current?.querySelector(`#${Object.keys(newErrors)[0]}`);
        invalidInput?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    [validateFields, values, currentStep, numSteps, onSubmit, formRef]
  );

  const handleBack = useCallback(() => {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
    else if (window.confirm(messages.confirmationAlert)) {
      push(routes.index);
    }
  }, [currentStep, push]);

  const runValidations = useCallback(
    (newValues, key) => {
      const validations = validateFields(newValues) || {};
      if (key) {
        setErrors({ ...errors, [key]: validations[key] });
      } else {
        setErrors(validations);
      }
    },
    [validateFields, errors]
  );

  const handleValueChange = useCallback(
    (key, currentValue, validate) => {
      const newValues = {
        ...values,
        [key]: currentValue
      };
      setValues(newValues);
      if (validate && !isEmpty(errors)) runValidations(newValues);
    },
    [errors, runValidations, values]
  );

  const backToStep = useCallback(
    step => {
      if (step > 0 && step < currentStep) {
        setCurrentStep(step);
      }
    },
    [currentStep]
  );

  return {
    values,
    currentStep,
    setValues,
    errors,
    setErrors,
    handleValueChange,
    handleSubmit,
    handleBack,
    backToStep
  };
};

export default useWizard;
