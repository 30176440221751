import createReducer from 'reducers/createReducer';
import { actionTypes } from 'actions/userActions';

const initialState = {
  user: null,
  info: null
};

const handleLoginSuccess = (state, { user }) => {
  state.user = user;
};

const handleLogoutSuccess = () => ({ ...initialState });

const handleUpdateSession = (state, { session }) => {
  state.info = session;
};

const handleProfileUpdate = (state, user) => {
  state.user = user;
};

export default createReducer(initialState, {
  [actionTypes.LOGIN_SUCCESS]: handleLoginSuccess,
  [actionTypes.UPDATE_SUCCESS]: handleLoginSuccess,
  [actionTypes.LOGOUT_SUCCESS]: handleLogoutSuccess,
  [actionTypes.UPDATE_SESSION]: handleUpdateSession,
  [actionTypes.GET_PROFILE_SUCCESS]: handleProfileUpdate,
  [actionTypes.USER_AUTO_LOGIN_SUCCESS]: handleLoginSuccess
});
