import React, { memo } from 'react';
import { string, node, number } from 'prop-types';
import cn from 'classnames';
import { useLoading, useSession } from 'hooks';
import { actionTypes } from 'actions/podActions';
import { useParams } from 'react-router-dom';

import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import Loader from 'components/common/Loader';
import Button from 'components/common/Button';
import { isMobile } from 'utils/helpers';
import getDownloadLink from 'utils/getDownloadLink';

import styles from './PodExpiration.scss';

const PodExpiration = ({ expirationDate, podId, backIcon }) => {
  const loading = useLoading(actionTypes.DOWNLOAD_POD);

  const { info } = useSession();
  const { token: accessToken } = useParams();

  const mobile = isMobile();

  const authInfo = accessToken || {
    ...info,
    'access-token': info.token
  };

  return (
    <div className="w-100 h-100 col-12 d-flex flex-column">
      {backIcon}
      <div className="d-flex flex-column justify-content-center align-items-center px-lg-12">
        <p className="m-auto text-gray text-weight-semibold pb-12">POD EXPIRATION DATE</p>
        <p className="text-center pb-5">Your pod expires on: {expirationDate}</p>
        <p className="text-center text-gray pb-9">
          Keep your pod active to access from anywhere at anytime by getting extra time.
        </p>
        <div className={cn('w-100 row d-flex justify-content-center', { 'pb-12': !mobile })}>
          <Button styleType="inverted" className="py-3 mb-5 order-2 col-12 col-lg ml-lg-9 mr-lg-3">
            <a href={getDownloadLink(podId, authInfo)} className="text-blue">
              {loading ? <Loader type="blue" className="mt-n4 mb-4" /> : 'Download pod content'}
            </a>
          </Button>
        </div>
        {mobile && (
          <div
            className={cn(
              'd-flex justify-content-center mt-6 py-3 px-3 mx-4',
              styles.disclaimerContainer
            )}
          >
            <Warning className={cn('my-auto mr-2', styles.icon)} />
            <p className={cn('text-gray text-x-small my-auto', styles.disclaimer)}>
              You will download a compressed folder in
              <span className="text-gray text-x-small text-weight-bold"> .zip format.</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

PodExpiration.propTypes = {
  expirationDate: string.isRequired,
  podId: number.isRequired,
  backIcon: node.isRequired
};

export default memo(PodExpiration);
