import validate from 'validate.js';
import isEmpty from 'lodash-es/isEmpty';
import { CREATEPOD_FIELDS } from 'constants/fields';
import { RECIPIENTS_ERROR, FILES_ERROR } from './errorMessages';

validate.validators.email.message = 'is not valid';
validate.validators.arrayEmails = (arrayItems, itemConstraints) => {
  const allEmails = [];

  const arrayItemErrors = arrayItems.map(item => {
    const { email } = item;
    const currentEmail = email?.label || {};

    const error = validate(item, itemConstraints) || {};
    const hasDuplicate = allEmails.some(label => label && label === currentEmail);

    if (hasDuplicate) {
      if (!error[CREATEPOD_FIELDS.emailLabel]) error[CREATEPOD_FIELDS.emailLabel] = [];
      error[CREATEPOD_FIELDS.emailLabel].push(RECIPIENTS_ERROR.duplicated);
    }

    allEmails.push(currentEmail);

    if (error && !isEmpty(error)) {
      if (error[CREATEPOD_FIELDS.emailLabel]) {
        return { email: [...error[CREATEPOD_FIELDS.emailLabel]] };
      }
      return error;
    }

    const newerr = { ...item };
    Object.keys(item).forEach(key => {
      newerr[key] = null;
    });
    return newerr;
  });

  return isEmpty(arrayItemErrors) ? null : arrayItemErrors;
};

validate.validators.files = (filesArray, itemConstraints) => {
  const error = validate(filesArray, itemConstraints);
  return isEmpty(error) ? null : `^${FILES_ERROR.empty}`;
};

validate.validators.presence.options = { allowEmpty: false };

export default validate;
