import React from 'react';
import { string, node } from 'prop-types';
import cn from 'classnames';
import styles from './PodDescription.scss';

const PodDescription = ({ description, backIcon }) => (
  <div className={cn('col-12 d-flex flex-column', styles.description)}>
    <div className="d-flex flex-column">
      {backIcon}
      <div className="d-flex flex-column mx-auto">
        <p className="m-auto pt-12 text-gray text-weight-semibold">MESSAGE</p>
        <p className="m-auto text-center pt-11">{description}</p>
      </div>
    </div>
  </div>
);

PodDescription.propTypes = {
  description: string.isRequired,
  backIcon: node
};

export default PodDescription;
