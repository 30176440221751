import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import Loader from 'components/common/Loader';

import { LOADING } from 'constants/status';
import GeneralDataHandler from 'components/common/GeneralDataHandler';
import { useFetchAndPaginate, useStatus, useSession } from 'hooks';
import { getEvents, GET_EVENTS } from 'actions/eventActions';
import EmptyState from 'components/common/EmptyState';
import Referral from 'components/homepage/ReferralLink';
import GiftBanner from 'components/homepage/GiftBanner';
import { eventPaginationSelector } from 'selectors/eventSelectors';
import Post from './Post';
import messages from './constants';

const Timeline = () => {
  const { status } = useStatus(GET_EVENTS);
  const loading = status === LOADING;
  const getNextPage = useFetchAndPaginate(getEvents, eventPaginationSelector, loading);
  useBottomScrollListener(getNextPage);
  const events = useSelector(({ events }) => events.timeline);

  const {
    user: { referral, giftCount }
  } = useSession();

  const renderPosts = () => (
    <>
      {!!events.length && (
        <span className="d-md-none text-gray text-uppercase text-weight-bold">
          {messages.timeline}
        </span>
      )}
      <div
        className={cn(
          'd-flex flex-column bg-white rounded-5 mt-3 pt-3 pb-5 px-3 px-md-6 flex-fill'
        )}
      >
        {!events.length && !loading && (
          <EmptyState
            className="py-md-10"
            title={messages.empty}
            subtitle={messages.emptySubtitle}
          />
        )}
        {events.map(({ id, ...event }, index) => (
          <Post last={events.length === index + 1} className="py-3" key={id} event={event} />
        ))}
        {!!events.length && loading && <Loader type="blue" className="mb-8" />}
      </div>
    </>
  );

  return (
    <>
      {referral?.code && <Referral className="mb-4" amount={referral?.amount} />}
      {!!giftCount && <GiftBanner className="mb-4" amount={giftCount} />}
      <GeneralDataHandler bypass={!!events.length} status={status} SuccessComponent={renderPosts} />
    </>
  );
};

export default Timeline;
