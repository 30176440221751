import React, { memo } from 'react';
import cn from 'classnames';
import { object, string, oneOf } from 'prop-types';

import { colorTypes } from './constants';
import styles from './Badge.scss';

const getStyle = type => styles[`${type}`];

const Badge = ({ children, type, className }) => (
  <div className={cn(styles.container, { [getStyle(type)]: !!type }, className)}>{children}</div>
);

Badge.propTypes = {
  children: object.isRequired,
  className: string,
  type: oneOf([colorTypes.GREEN])
};

Badge.defaultProps = {
  className: ''
};

export default memo(Badge);
