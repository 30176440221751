import React, { memo, useState } from 'react';
import { func, bool } from 'prop-types';
import cn from 'classnames';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { actionTypes, fbLogin, skypodSocialLogin } from 'actions/userActions';
import { useForm, useStatus, useTextInputProps, useValidation, useDispatch } from 'hooks';
import { Inputs } from 'components/common/Input';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import { LOADING } from 'constants/status';
import { LOGIN_FIELDS } from 'constants/fields';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import loginValidations from 'validations/loginValidations';
import googleImage from '../../../assets/images/google.png';
import facebookImage from '../../../assets/images/facebook.png';
import { messages } from './constants';
import styles from './LoginForm.scss';

<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
  integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA=="
  crossorigin="anonymous"
/>;
export const LoginForm = ({ onSubmit, onForgotPassword, blackAccents }) => {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { status, error } = useStatus(actionTypes.LOGIN);

  const validator = useValidation(loginValidations);
  const { values, errors, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true
    },
    [onSubmit]
  );
  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);

  const isLoading = status === LOADING;
  const fbl = useDispatch(fbLogin, {});
  const skypodLogin = useDispatch(skypodSocialLogin, {});
  const [callQueryLogin, setCallQueryLogin] = useState(true);
  const query = new URLSearchParams(window.location.search);
  const data = query != undefined ? query.get('code') : '';

  const skypodSocialLoginData = query != undefined ? query.get('skypodLogin') : null;
  if (skypodSocialLoginData && callQueryLogin) {
    setCallQueryLogin(false);
    skypodLogin(skypodSocialLoginData);
  }

  function responseFacebook(response) {
    console.log(response);
    if (response.email) {
      let formData = {};
      formData = {
        email: response.email,
        referral: data,
        name: response.name
      };
      fbl(formData);
    } else {
    }
  }

  function responseGoogle(response) {
    console.log('google', response);
    let formData = {};
    if (response.profileObj.email) {
      formData = {
        email: response.profileObj.email,
        referral: data,
        name: response.profileObj.name
      };
      fbl(formData);
    } else {
      // error = ""
    }
  }

  const triggerfb = () => {
    document.getElementsByClassName('fb-login')[0].childNodes[0].childNodes[1].click();
  };
  const triggergoogle = () => {
    document.getElementsByClassName('google-login')[0].childNodes[0].click();
  };
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get('ufw');
  const openModal = () => {
    if (document.getElementById('modal')) document.getElementById('modal').childNodes[0].click();
    else setTimeout(openModal, 300);
  };

  if (userId) {
    // openModal();
  }

  function showhidepswd() {
    const x = document.getElementById('password');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
    // toggle icon
    const checkIcon = document.getElementById('eye').className == 'fa fa-eye-slash';
    if (checkIcon) {
      document.getElementById('eye').className = 'fa fa-eye';
    } else {
      document.getElementById('eye').className = 'fa fa-eye-slash';
    }
  }

  return (
    <form onSubmit={handleSubmit} className="d-flex flex-column flex-grow-1">
      <div style={{ display: 'none' }} id="modal">
        <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Body>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 945.81 979.77">
            <defs></defs>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <ellipse class="cls-1" cx="472.91" cy="489.88" rx="472.91" ry="489.88" />
                <ellipse class="cls-2" cx="472.91" cy="489.88" rx="416.49" ry="431.44" />
                <path
                  class="cls-3"
                  d="M427.91,233.88a60,60,0,1,0,60,60A60.07,60.07,0,0,0,427.91,233.88Zm0,90a30,30,0,1,1,30-30A30,30,0,0,1,427.91,323.88Z"
                />
                <path
                  class="cls-3"
                  d="M623.91,233.88a105,105,0,1,0,105,105A105.12,105.12,0,0,0,623.91,233.88Zm0,180a75,75,0,1,1,75-75A75.08,75.08,0,0,1,623.91,413.88Z"
                />
                <path
                  class="cls-3"
                  d="M653.91,323.88h-15v-15a15,15,0,0,0-30,0v30a15,15,0,0,0,15,15h30a15,15,0,0,0,0-30Z"
                />
                <path
                  class="cls-3"
                  d="M563.91,504.88h-76v-91a60,60,0,0,0-120,0v136a44.74,44.74,0,0,0,2.58,15H331.4s-16-127.74-24.5-197a45,45,0,0,0-90,1,16.57,16.57,0,0,0,.11,1.86l29.9,240.17a45.08,45.08,0,0,0,30,41.4v78.57a15,15,0,0,0,30,0v-76h121v76a15,15,0,0,0,30,0V652.3a45,45,0,0,0,27.42-57.42h33.58v106a45,45,0,0,0,90,0v-151A45.05,45.05,0,0,0,563.91,504.88Zm-121,120h-151a15,15,0,0,1-15-15c0-1.4,1.63,12.17-30-241.77a15,15,0,0,1,30,.77c0,1.87,26.36,212.86,26.36,212.86a15,15,0,0,0,14.89,13.14H442.91a15,15,0,0,1,0,30Zm136,76a15,15,0,0,1-30,0v-121a15,15,0,0,0-15-15h-121a15,15,0,0,1-15-15v-136a30,30,0,0,1,60,0v106a15,15,0,0,0,15,15h91a15,15,0,0,1,15,15Z"
                />
              </g>
            </g>
          </svg>
          Please wait while we redirect you to your dashboard
        </Modal.Body>
      </Modal>
      <div>
        <Inputs
          label={messages[LOGIN_FIELDS.email]}
          type="text"
          placeholder="Enter your email"
          customError={errors[LOGIN_FIELDS.email]}
          className="mb-4"
          input={{ ...inputProps(LOGIN_FIELDS.email) }}
        />
      </div>
      <div style={{ position: 'relative' }}>
        <Inputs
          label={messages[LOGIN_FIELDS.password]}
          type="password"
          id="password"
          onClick={onForgotPassword}
          placeholder="Enter your password"
          customError={errors[LOGIN_FIELDS.password]}
          input={{ ...inputProps(LOGIN_FIELDS.password) }}
        />
        <div style={{ float: 'right', position: 'absolute', right: '10px', top: '40px' }}>
          <i className="fa fa-eye-slash" onClick={showhidepswd} id="eye"></i>
        </div>
      </div>
      <div
        onClick={onForgotPassword}
        className="d-flex justify-content-md-end justify-content-center pasword_mobile mt-3 mb-md-3"
      >
        <u className="clickable canceButton">{messages.forgetPassword}</u>
      </div>
      <div className="mb-0 mt-2">
        <label className="text-x-small text-weight-semibold text-alert text-center mb-2">
          {error}
        </label>
      </div>
      <div className="mt-2 mt-sm-auto">
        <Button
          type="submit"
          styleType={blackAccents ? 'black' : 'primary'}
          className={cn('w-100', styles.button)}
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <span className="text-inverted text-h5">{messages.submit}</span>
          )}
        </Button>
      </div>
      <div className="social-container">
        <a onClick={triggergoogle}>
          <img alt="Google Login" id="cwg" src={googleImage} width="50%"></img>
        </a>
        <a onClick={triggerfb}>
          <img id="cwf" alt="Facebook Login" src={facebookImage} width="50%"></img>
        </a>

        <div style={{ display: 'none' }} className="fb-login">
          <FacebookLogin
            appId="845717646203155"
            autoLoad={false}
            fields="name,email,picture"
            cssClass="my-facebook-button-class"
            icon="fa-facebook"
            textButton="Continue with Facebook"
            callback={responseFacebook}
            state={1}
            isMobile={false}
          />
        </div>
        <div style={{ display: 'none' }} className="google-login">
          <GoogleLogin
            clientId="236262822872-21cl8936en7rh7le5bek6tm8oqk6f7ra.apps.googleusercontent.com"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy="single_host_origin"
            buttonText="Continue with Google"
            className="google-button"
          />
        </div>
      </div>
    </form>
  );
};
LoginForm.propTypes = {
  onSubmit: func.isRequired,
  onForgotPassword: func.isRequired,
  blackAccents: bool
};
LoginForm.defaultProps = {
  blackAccents: false
};
export default memo(LoginForm);
