import { inputBorder } from 'styles/_constants.scss';

export const stylesSelect = {
  control: (base, state) => ({
    ...base,
    'max-height': '4.8rem',
    'border-color': inputBorder,
    boxShadow: state.isFocused ? inputBorder : inputBorder,
    '&:hover': {
      'border-color': inputBorder
    }
  }),
  valueContainer: base => ({ ...base, padding: '0 8px', minWidth: '48%' }),
  indicatorsContainer: () => ({}),
  input: base => ({ ...base, margin: 0, paddingBottom: '0', paddingTop: '0' })
};
