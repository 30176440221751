import React, { useMemo, useRef, useEffect, useState, useCallback } from 'react';
import { func, array, bool, object } from 'prop-types';
import cn from 'classnames';

import Option from './Option';
import styles from './ContactSuggest.scss';
import { messages } from './constants';

const ContactSuggest = ({ onChange, contacts, error, value, isLast }) => {
  const inputRef = useRef();
  const dropdownRef = useRef();

  const [filteredOptions, setFilteredOptions] = useState();
  const [showOptions, setShowOptions] = useState();
  const [outsideClicked, setOutsideClicked] = useState();

  const allOptions = useMemo(
    () => contacts.map(contact => ({ value: contact, label: contact.email })),
    [contacts]
  );

  const onClickOutside = useCallback(({ target }) => {
    if (target !== dropdownRef.current && target !== inputRef.current) {
      setOutsideClicked(true);
    }
  }, []);

  useEffect(() => {
    isLast && inputRef.current.focus();
    document.addEventListener('click', onClickOutside, false);

    return () => {
      document.removeEventListener('click', onClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (outsideClicked) {
      setShowOptions(false);
      setOutsideClicked(false);
    }
  }, [outsideClicked]);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      if (value.trim() !== '') {
        setFilteredOptions(
          allOptions.filter(
            ({ value: { email, name } }) =>
              email.toLowerCase().includes(value.toLowerCase()) ||
              name?.toLowerCase().includes(value.toLowerCase())
          )
        );
        onChange({ label: value, value, isNew: true });
      } else {
        setFilteredOptions(null);
        onChange(null);
      }
      if (!showOptions) setShowOptions(true);
    },
    [allOptions, onChange, showOptions]
  );

  const handleSelect = useCallback(
    selectedId => {
      setFilteredOptions(null);
      const selected = allOptions.find(({ value: { id } }) => id === selectedId);
      onChange(selected || null);
    },
    [allOptions, onChange]
  );

  const handleFocus = useCallback(() => setShowOptions(true), []);

  return (
    <div className="pt-2 w-100">
      <input
        type="email"
        ref={inputRef}
        onFocus={handleFocus}
        value={value?.label || ''}
        className="w-100 pl-2"
        placeholder={messages.placeholder}
        onChange={handleChange}
      />
      {filteredOptions && showOptions && (
        <div className={cn('mt-2 bg-white w-100', styles.dropdown)} ref={dropdownRef}>
          {filteredOptions.map(op => (
            <Option key={op.value.id} data={op} onClick={handleSelect} />
          ))}
        </div>
      )}
      {error && !!error.length && (
        <p className="text-x-small text-alert text-weight-semibold mt-2">{error[0]}</p>
      )}
    </div>
  );
};

ContactSuggest.propTypes = {
  onChange: func.isRequired,
  contacts: array.isRequired,
  isLast: bool,
  value: object,
  error: array
};

export default ContactSuggest;
