// App common constants
const PHANTOM = 'phantom';
const PRIMARY = 'primary';
const UNDERLINED = 'underlined';
const BLACK = 'black';
const INVERTED = 'inverted';

export const SUPPORTED_LANGUAGES = ['en', 'es'];
export const DEFAULT_LANGUAGE = 'en';

export const BUTTON_STYLES = {
  PHANTOM,
  PRIMARY,
  UNDERLINED,
  BLACK,
  INVERTED
};

export const CONTACT_INFO = {
  ADDRESS: '116 New Montgomery Street, Suite 400, Miami FL 94105',
  BRAND: `@${new Date().getFullYear()} SKYPOD`
};

export const DATE_FORMAT = 'MM/DD/YYYY';

export const LIST_ERROR_MESSAGE = 'Sorry, there was a problem. Please try again later.';

export const ERROR_MESSAGE = 'Oops, something went wrong. Please try again later.';

export const CARD_ERROR_MESSAGE = 'Sorry, the payment was declined. Try another card.';

export const POD_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  OPENED: 'OPENED',
  CLOSED: 'CLOSED',
  NEW: 'NEW'
};

export const PERIOD = {
  AM: 'AM',
  PM: 'PM'
};

export const TIME_FORMATS = {
  NO_TIMEZONE: 'YYYY-MM-DDTHH:mm:ss',
  EXTENDED_TIMEZONE: 'MMM DD, YYYY - h:mm a ',
  NORMAL_TIMEZONE: 'MM/DD/YYYY hh:mm',
  TWELVE: 'hh:mm a',
  TWELVE_SINGLE_POINT: ' h.mm a',
  TWENTY_FOUR: 'HH:mm',
  UTC: ' MM/DD/YYYY hh:mm A [UTC]'
};

export const TIMEZONES = {
  EST: 'EST',
  PST: 'PST',
  CST: 'CST'
};

export const MOMENT_TIMEZONES = {
  EST: 'America/New_York',
  PST: 'America/Los_Angeles',
  CST: 'America/Chicago'
};

export const POD_FILES_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  TEXT: 'text',
  APPLICATION: 'application',
  PDF: 'application/pdf'
};

export const SORT_PODS_CRITRIA = {
  DATE_SENT: 'Date sent',
  OPENING_DATE: 'Opening date'
};

export const CREATED_AT_FILTER = 'createdAt';
export const OPENING_DATE_FILTER = 'openingDate';

export const BACKEND_RETRIES = 1;
export const LAST_RETRY = 0;
