import api from 'api';

class ContactsService {
  static getContacts() {
    return api.get('/contacts');
  }

  static removeContact(id) {
    return api.delete(`/contacts/${id}`);
  }
}

export default ContactsService;
