import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { string, arrayOf, shape, number } from 'prop-types';
import { Link } from 'react-router-dom';

import { useBreakpoint } from 'hooks';
import { truncateString, customBackground, joinWithComma } from 'utils/helpers';

import routesPaths from 'constants/routesPaths';
import { DATE_FORMAT, POD_STATUS, MOMENT_TIMEZONES } from 'constants/constants';

import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import defaultImage from 'assets/images/default-image.svg';
import UnlockIcon from 'components/common/Icons/Unlock';
import Edit from 'components/common/Icons/Edit';
import Lock from 'components/common/Icons/Lock';

import { grayGradient, blackGradient, lightGray, md } from 'styles/_constants.scss';

import styles from './GeneralPodItems.scss';
import { MAX_RECIPIENTS_CHAR, LABELS } from './constants';

const GeneralPodItems = ({ list, status }) => {
  const { PENDING, ACCEPTED, CLOSED, OPENED } = POD_STATUS;
  const { TO, FROM, OPEN_ON } = LABELS;

  const statusClassName = cn(
    styles.podStatus,
    { 'bg-gray': status === PENDING },
    { [styles.accepted]: status === ACCEPTED }
  );

  let backgroundGradient;
  status === PENDING && (backgroundGradient = grayGradient);
  status === CLOSED && (backgroundGradient = blackGradient);

  const outgoing = status === PENDING || status === ACCEPTED;
  const pathname = routesPaths[outgoing ? 'outgoingPod' : 'openPod'];

  const isMd = useBreakpoint(md);

  return (
    list &&
    list.map(({ id, title, openingDate, coverImage, recipients, userName, description }) => {
      const date = new Date(openingDate);

      const time = {
        hour: {
          value: moment(openingDate).format('hh'),
          label: moment(openingDate).format('hh')
        },
        minutes: {
          value: moment(openingDate).format('mm'),
          label: moment(openingDate).format('mm')
        },
        period: {
          value: moment(openingDate).format('a'),
          label: moment(openingDate)
            .format('a')
            .toUpperCase()
        },
        timezone: {
          value: MOMENT_TIMEZONES.EST,
          label: 'EST'
        }
      };

      return (
        <div key={id} className="col-md-6 col-lg-6 col-xl-4">
          <div className={styles.podItem}>
            <Link
              to={{
                pathname,
                state: { selectedPodId: id }
              }}
            >
              <div
                style={customBackground(
                  coverImage || defaultImage,
                  backgroundGradient,
                  'center',
                  coverImage ? 'cover' : 'inherit'
                )}
                className="sixteen-nine"
              >
                {status === CLOSED && <Lock />}
              </div>
              <div className={styles.podData}>
                <div className="px-5 py-2">
                  <div className="d-flex justify-content-between">
                    <p
                      className={cn(
                        'text-h5 text-weight-semibold m-0 pb-2 overflow-wrap',
                        { 'text-ellipsis-2': isMd },
                        { 'text-ellipsis': !isMd },
                        styles.titlePod
                      )}
                    >
                      {title}
                    </p>
                    {status === PENDING && (
                      <Link
                        to={{
                          pathname: routesPaths.updatePod,
                          state: { pod: { id, coverImage, title, description, date, time } }
                        }}
                      >
                        <Edit />
                      </Link>
                    )}
                    {status === OPENED && <UnlockIcon />}
                    {status === CLOSED && <Lock width={24} height={24} stroke={lightGray} />}
                  </div>
                  <p className="text-gray text-small text-ellipsis">
                    {[PENDING, ACCEPTED].includes(status) &&
                      TO +
                        truncateString(
                          joinWithComma(recipients.map(({ userName }) => userName)),
                          MAX_RECIPIENTS_CHAR
                        )}
                    {[CLOSED, OPENED].includes(status) && FROM + userName}
                  </p>
                </div>
                <div className={styles.podItemFooter}>
                  {[PENDING, ACCEPTED].includes(status) && (
                    <div className={statusClassName}>
                      {status === PENDING ? <ClockIcon /> : <CheckIcon />}
                      <p className="m-0 ml-2">{status}</p>
                    </div>
                  )}
                  <p className={styles.date}>
                    {OPEN_ON}
                    <span>{moment(openingDate).format(DATE_FORMAT)}</span>
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    })
  );
};

GeneralPodItems.propTypes = {
  list: arrayOf(
    shape({
      id: number,
      title: string,
      openingDate: string,
      recipients: arrayOf(shape({ email: string, userName: string })),
      coverImage: string,
      userName: string
    })
  ).isRequired,
  status: string
};

export default GeneralPodItems;
