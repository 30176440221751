import React, { memo, useState } from 'react';
import { string, arrayOf, shape } from 'prop-types';
import cn from 'classnames';

import useOnClickOutside from 'hooks/useOnClickOutside';

import Arrow from 'components/common/Icons/Arrow';
import Dots from 'components/common/Icons/Dots';
import { DROPDOWN_ITEM } from 'constants/shapes';

import styles from './DropDown.scss';

const DropDown = ({ label, items, className }) => {
  const [visible, setVisible] = useState(false);

  const ref = useOnClickOutside(() => setVisible(false));

  return (
    <div ref={ref} className={cn(['clickable', className])} onClick={() => setVisible(!visible)}>
      <div className="d-none d-lg-block text-right">
        <span className="mr-3">{label}</span> <Arrow type={visible ? 'up' : 'down'} />
      </div>
      <Dots className="d-block d-lg-none" />
      {visible && (
        <div className={cn([styles.dropDown, 'position-absolute bg-white'])}>
          {items.map(({ id, onClick, icon, label }) => (
            <div
              key={id}
              className="d-flex justify-content-center my-3 mx-2 mx-md-3 clickable"
              onClick={onClick}
            >
              {icon}
              <span className="text-small text-center ml-2">{label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DropDown.propTypes = {
  label: string,
  items: arrayOf(shape(DROPDOWN_ITEM)),
  className: string
};

export default memo(DropDown);
