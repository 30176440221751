import React from 'react';
import Exit from 'assets/icons/exit';

export const DROPDOWN_FIELDS = {
  logout: 'logout'
};

export const itemsCreator = onClickFuncs => [
  {
    id: DROPDOWN_FIELDS.logout,
    label: 'Log out',
    icon: <Exit />,
    onClick: onClickFuncs[DROPDOWN_FIELDS.logout]
  }
];

export const messages = {
  createPod: 'Create Pod',
  myAccount: 'My account'
};
