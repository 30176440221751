import React, { memo } from 'react';
import cn from 'classnames';
import { string, bool } from 'prop-types';

import { CONTACT_INFO } from 'constants/constants';

const Footer = ({ className, inverted }) => (
  <div className={cn({ 'text-inverted': inverted }, className)}>
    <div className="d-flex justify-content-between align-items-center my-3">
      <div>
        <p className="text-x-small">{CONTACT_INFO.BRAND}</p>
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  className: string,
  inverted: bool
};

Footer.defaultProps = {
  className: '',
  inverted: false
};

export default memo(Footer);
