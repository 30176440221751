import { string, object, func } from 'prop-types';

export const DROPDOWN_ITEM = {
  id: string.isRequired,
  label: string.isRequired,
  icon: object,
  onClick: func
};

export const TAB = {
  id: string.isRequired,
  label: string.isRequired,
  icon: object,
  focusedIcon: object
};
