import React from 'react';
import { string, number } from 'prop-types';

import { white } from 'styles/_constants.scss';

const Lock = ({ className, height, width, strokeWidth, stroke }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M31.667 18.334H8.333A3.333 3.333 0 005 21.666v11.666a3.333 3.333 0 003.333 3.334h23.334A3.333 3.333 0 0035 33.333V21.668a3.333 3.333 0 00-3.333-3.334zM11.667 18.334v-6.667a8.333 8.333 0 0116.666 0v6.667"
    />
  </svg>
);

Lock.propTypes = {
  className: string,
  height: number,
  width: number,
  strokeWidth: number,
  stroke: string
};

Lock.defaultProps = {
  className: '',
  height: 40,
  width: 40,
  strokeWidth: 2,
  stroke: white
};

export default Lock;
