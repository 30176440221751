import React from 'react';
import { number } from 'prop-types';

const PlusCircle = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      width={width}
      height={height}
      d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 16V32"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 24H32"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

PlusCircle.propTypes = {
  width: number,
  height: number
};

PlusCircle.defaultProps = {
  width: 48,
  height: 48
};

export default PlusCircle;
