import createAction from 'actions/createAction';
import EventsService from 'services/eventsService';

export const GET_EVENTS = 'GET_EVENTS';

const getEventsRequest = createAction(`${GET_EVENTS}_REQUEST`);

export const getEventsSuccess = createAction(`${GET_EVENTS}_SUCCESS`);

const getEventsError = createAction(`${GET_EVENTS}_ERROR`);

export const getEvents = (page = 1) => async (dispatch, getState) => {
  try {
    dispatch(getEventsRequest());
    const {
      events: { pagination }
    } = getState();
    const { data } = await EventsService.getTimeline({ ...pagination, page });
    dispatch(getEventsSuccess(data));
  } catch (err) {
    dispatch(getEventsError(err));
  }
};
