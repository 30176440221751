import createReducer from 'reducers/createReducer';
import { getEventsSuccess } from 'actions/eventActions';
import { actionTypes as userActionTypes } from 'actions/userActions';

const initialState = {
  timeline: [],
  pagination: {
    page: 0,
    perPage: process.env.PER_PAGE || 30
  }
};

const handleLogOut = () => ({ ...initialState });

export default createReducer(initialState, {
  [getEventsSuccess]: (state, { timeline, pagination }) => {
    if (state.pagination.page + 1 === pagination.page) {
      state.timeline = state.timeline.concat(timeline);
    } else {
      state.timeline = timeline;
    }
    state.pagination = pagination;
  },
  [userActionTypes.LOGOUT_REQUEST]: handleLogOut
});
