import React from 'react';
import { Redirect } from 'react-router-dom';

import { useSession } from 'hooks';
import Header from 'components/common/Header';
import ReferralCard from 'components/referral/ReferralCard';
import routesPaths from 'constants/routesPaths';

const ReferralPage = () => {
  const {
    user: { referral }
  } = useSession();

  if (!referral?.code) {
    return <Redirect to={routesPaths.signUp} />;
  }

  return (
    <div className="bg-gray d-flex flex-column min-vh-100">
      <Header />
      <div className="container">
        <ReferralCard
          code={referral.code}
          amount={referral.amount}
          className="col col-sm-10 offset-sm-1 mt-5 mt-sm-9 mb-9"
        />
      </div>
    </div>
  );
};

export default ReferralPage;
