import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { object, number } from 'prop-types';
import cn from 'classnames';

import { useBreakpoint } from 'hooks';

import { POD_FILES_TYPES } from 'constants/constants';
import { sm } from 'styles/_constants.scss';
import { truncateFileName } from 'utils/helpers';
import routes from 'constants/routesPaths';

import ImagePreview from './ImagePreview';
import VideoPreview from './VideoPreview';
import AudioPreview from './AudioPreview';
import PdfPreview from './PdfPreview';
import DefaultPreview from './DefaultPreview';
import { MAX_FILE_NAME, fileDisclaimer, formatViewingSuggestions } from './constants';

import styles from './MediaPreview.scss';

const ViewingSuggestionsLink = ({ className }) => (
  <Link
    to={routes.formatViewingSuggestions}
    className={cn(className, 'text-inverted text-underline')}
  >
    {formatViewingSuggestions}
  </Link>
);

const MediaPreview = ({ file: { name, type, ...file }, currentSlide }) => {
  let preview;
  let showName = true;

  const isDesktop = useBreakpoint(sm);

  switch (true) {
    case type.includes(POD_FILES_TYPES.IMAGE):
      preview = <ImagePreview file={file} isDesktop={isDesktop} />;
      break;
    case type.includes(POD_FILES_TYPES.VIDEO):
      preview = <VideoPreview file={file} currentSlide={currentSlide} />;
      break;
    case type.includes(POD_FILES_TYPES.AUDIO):
      preview = <AudioPreview file={file} currentSlide={currentSlide} />;
      break;
    case type.includes(POD_FILES_TYPES.PDF):
      preview = <PdfPreview file={file} />;
      break;
    default:
      showName = false;
      preview = <DefaultPreview name={name} />;
  }

  const fileName = truncateFileName(name, MAX_FILE_NAME);

  return (
    <div className="d-inline-flex flex-column align-items-center justify-content-center h-100 pb-md-12 pt-md-10 w-100">
      <div
        className={cn('bg-dark-blue p-2 rounded-5 position-absolute d-sm-none text-inverted', {
          invisible: !showName
        })}
        style={{ top: '7%', zIndex: 1 }}
      >
        {fileName}
      </div>
      <div
        className={cn('pb-md-10 text-inverted d-md-flex align-items-center', {
          invisible: !showName || !isDesktop
        })}
      >
        {fileName}
      </div>
      <div className="row no-gutters h-100 w-100">
        <div
          className="col col-md-8 offset-md-2 h-100 position-relative"
          style={{ top: !isDesktop && '11%' }}
        >
          {preview}
        </div>
        {!type.includes(POD_FILES_TYPES.PDF) && (
          <>
            <div className={cn('position-absolute w-100', styles.disclaimer)}>
              <div className="d-md-none text-inverted text-gray text-center text-x-small bg-dark-blue p-2 rounded-5 w-75 mx-auto wrap">
                <p className="pb-1">{fileDisclaimer}</p>
                <ViewingSuggestionsLink className="text-x-small pt-2" />
              </div>
            </div>
            <div
              className={cn(
                'd-none d-flex flex-column align-items-center mx-auto mt-4 wrap',
                styles.disclaimerMd
              )}
            >
              <p className="d-none d-md-block text-gray text-center text-small text-break mx-auto">
                {fileDisclaimer}
              </p>
              {/* <ViewingSuggestionsLink className="d-none d-md-block text-small mt-3" /> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

MediaPreview.propTypes = {
  file: object.isRequired,
  currentSlide: number.isRequired
};

export default memo(MediaPreview);
