import React from 'react';
import { object, string, func, node } from 'prop-types';

import Alert from 'components/common/Icons/Alert';
import Button from 'components/common/Button';

export const ErrorMessage = ({ children, message, disableOnClick, cancel }) => (
  <div className="d-flex flex-column align-items-center justify-content-center">
    <Alert />
    <p className="pt-2 text-center">{message}</p>
    {children}
    <Button type="button" className="bg-gray text-blue py-2 px-7 mt-4 mb-3">
      Try another
    </Button>
    <div {...disableOnClick}>
      <Button type="button" styleType="underlined" onClick={cancel}>
        Cancel
      </Button>
    </div>
  </div>
);

ErrorMessage.propTypes = {
  children: node,
  message: string.isRequired,
  disableOnClick: object,
  cancel: func.isRequired
};
