import React, { useCallback } from 'react';
import { arrayOf, number, string, shape } from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/common/Button';
import defaultImage from 'assets/images/default-image-big.svg';
import { customBackground } from 'utils/helpers';
import { blurredBlackGradient } from 'styles/_constants.scss';
import { useDispatch, useStatus } from 'hooks';
import {
  acceptPod,
  rejectPod,
  acceptPodClearStatus,
  rejectPodClearStatus,
  actionTypes
} from 'actions/incomingPodActions';
import { ERROR, LOADING } from 'constants/status';
import { ERROR_MESSAGE } from 'constants/constants';
import styles from './IncomingPendingPodItems.scss';
import { WARNING_REJECT_MESSAGE } from './constants';

const IncomingPendingPodItems = ({ list }) => {
  const acceptPodRequest = useDispatch(acceptPod);
  const rejectPodRequest = useDispatch(rejectPod);

  const acceptClearStatus = useDispatch(acceptPodClearStatus);
  const rejectClearStatus = useDispatch(rejectPodClearStatus);

  const statusAccept = useStatus(actionTypes.ACCEPT_POD);
  const statusReject = useStatus(actionTypes.REJECT_POD);

  const warningMessage = useCallback(
    id => {
      if (window.confirm(WARNING_REJECT_MESSAGE)) {
        rejectPodRequest(id);
      }
    },
    [rejectPodRequest]
  );

  if (statusAccept.status === ERROR) {
    window.alert(ERROR_MESSAGE);
    acceptClearStatus();
  }
  if (statusReject.status === ERROR) {
    window.alert(ERROR_MESSAGE);
    rejectClearStatus();
  }

  return list.map(({ id, userName, coverImage }) => (
    <div key={id} className="col-md-6 col-lg-6 col-xl-4">
      <div
        style={
          coverImage
            ? customBackground(coverImage, blurredBlackGradient, 'center top', 'contain')
            : customBackground(defaultImage, blurredBlackGradient, 'center')
        }
        className={styles.podItemIncoming}
      >
        <p className="text-inverted text-weight-medium text-center">
          {userName} wants to share a Pod with you
        </p>
        <Button
          type="button"
          disabled={statusAccept.status === LOADING}
          styleType="primary"
          className="text-small my-4 py-3 px-6"
          onClick={() => acceptPodRequest(id)}
        >
          Accept Pod
        </Button>
        <Link className="text-inverted text-small" onClick={() => warningMessage(id)}>
          Reject Pod
        </Link>
      </div>
    </div>
  ));
};

IncomingPendingPodItems.propTypes = {
  list: arrayOf(shape({ id: number, userName: string, coverImage: string })).isRequired
};

export default IncomingPendingPodItems;
