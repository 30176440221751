import React from 'react';
import { number, string } from 'prop-types';

const Imago = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.4998 15.7087C15.1672 15.7087 15.7082 15.1677 15.7082 14.5003C15.7082 13.833 15.1672 13.292 14.4998 13.292C13.8325 13.292 13.2915 13.833 13.2915 14.5003C13.2915 15.1677 13.8325 15.7087 14.4998 15.7087Z"
      stroke="#06223C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4998 7.24967C15.1672 7.24967 15.7082 6.70869 15.7082 6.04134C15.7082 5.374 15.1672 4.83301 14.4998 4.83301C13.8325 4.83301 13.2915 5.374 13.2915 6.04134C13.2915 6.70869 13.8325 7.24967 14.4998 7.24967Z"
      stroke="#06223C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4998 24.1667C15.1672 24.1667 15.7082 23.6257 15.7082 22.9583C15.7082 22.291 15.1672 21.75 14.4998 21.75C13.8325 21.75 13.2915 22.291 13.2915 22.9583C13.2915 23.6257 13.8325 24.1667 14.4998 24.1667Z"
      stroke="#06223C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Imago.propTypes = {
  width: number,
  height: number,
  className: string
};

Imago.defaultProps = {
  width: 29,
  height: 29,
  className: ''
};

export default Imago;
