import React, { useContext } from 'react';
import cn from 'classnames';

import { useBreakpoint, useToggle } from 'hooks';

import VersionDisclaimer from 'components/common/VersionDisclaimer';
import { xxl } from 'styles/_constants.scss';
import Business from 'components/common/Icons/Business';
import Family from 'components/common/Icons/Family';
import Friends from 'components/common/Icons/Friends';
import Footer from 'components/common/Footer';
import TabNav from 'components/common/TabNav';
import SignUpForm from 'components/user/SignUpForm';
import LoginForm from 'components/user/LoginForm';
import ForgotPasswordForm from 'components/user/ForgotPasswordForm';
import { ReactComponent as Logo } from 'assets/images/white-logo.svg';

import { LandingContext } from '../index';
import { LABELS, authFilteredTabs } from '../tabs';
import styles from '../SignUpPage.scss';

const Desktop = () => {
  const isExtraLarge = useBreakpoint(xxl);
  const [showForgoPassword, toggleForgotPassword] = useToggle();
  const onClick = () => {
    window.location.href = 'https://skypod.com';
  };
  const { handleSignup, handleLogin, handleTabChange, tab } = useContext(LandingContext);
  const columnStyling = tab === LABELS.signup ? 'col-4' : '';
  const iconSize = isExtraLarge ? 180 : 148;

  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      {tab === LABELS.signup && (
        <div className={columnStyling} style={{ marginBottom: 400 }}>
          <div className="ml-8">
            <h3 className="mb-5">Thank you, your purchase is complete.</h3>
            <h4 className="mb-2">
              Please fill out the information below to log-in & fill your pod.
            </h4>
            <h4 className="h4">
              Creating a pod is meaningful and may take time. If you prefer to come back later, you
              can return to this page whenever you are ready to create your pod.
            </h4>
          </div>
        </div>
      )}

      <div className={`custom_form_holder ${columnStyling}`}>
        {process.env.VERSION_DISCLAIMER && <VersionDisclaimer />}
        <div className={styles.desktopCover} />
        <div className="container">
          <div className="logo_holder">
            <a href="https://www.skypod.com">
              <img
                alt=""
                src="https://uploads-ssl.webflow.com/5edcdd00accb0b8dbabf6910/5f7c9dc6434b80dc36eeeaf7_Logo%20Horizontal.svg"
              ></img>
            </a>
          </div>

          <div className="w-50 h-100  flex-column d-none">
            <div className={styles.desktopTop}>
              <Logo className="mt-10" onClick={onClick} />
              <h3 className="text-h3 text-weight-semibold text-inverted mt-2 mb-1">
                Things that Matter
              </h3>
            </div>

            <div>
              <div className="row my-9">
                <div className="col-12 col-lg-4 pb-10">
                  <div className={cn([styles.image, 'm-auto mr-lg-3 m-xxl-0 d-flex'])}>
                    <Family className="m-auto" height={iconSize} width={iconSize} />
                  </div>
                  <p className="text-weight-semibold mt-8 mb-3 text-lg-left text-center">
                    For Family
                  </p>
                  <p className="text-small text-lg-left text-center">
                    Record messages for loved ones that can live forever.
                  </p>
                </div>
                <div className="col-12 col-lg-4 pb-10">
                  <div className={cn([styles.image, 'm-auto mr-lg-3 m-xxl-0 d-flex'])}>
                    <Friends className="m-auto" height={iconSize} width={iconSize} />
                  </div>
                  <p className="text-weight-semibold mt-8 mb-3 text-lg-left text-center">
                    For Friends
                  </p>
                  <p className="text-small text-lg-left text-center">
                    Photos and videos of your favorite memories delivered in the future.
                  </p>
                </div>
                <div className="col-12 col-lg-4 pb-10">
                  <div className={cn([styles.image, 'm-auto mr-lg-3 m-xxl-0 d-flex'])}>
                    <Business className="m-auto" height={iconSize} width={iconSize} />
                  </div>
                  <p className="text-weight-semibold mt-8 mb-3 text-lg-left text-center">
                    For Business
                  </p>
                  <p className="text-small text-lg-left text-center">
                    Ensure your company is prepared for the future.
                  </p>
                </div>
              </div>
            </div>
            <Footer className="mt-auto mb-6" />
          </div>

          <div className={cn('bg-white rounded-5 SignUpPage__formContainer', styles.formContainer)}>
            {showForgoPassword ? (
              <ForgotPasswordForm onCancel={toggleForgotPassword} />
            ) : (
              <>
                <TabNav
                  tabs={authFilteredTabs()}
                  tabClass="mr-7 mb-7"
                  focused={tab}
                  handleChange={handleTabChange}
                  signUpTabs
                />
                {tab === LABELS.signup && <SignUpForm onSubmit={handleSignup} />}
                {tab === LABELS.login && (
                  <LoginForm onForgotPassword={toggleForgotPassword} onSubmit={handleLogin} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={columnStyling}></div>
    </div>
  );
};

export default Desktop;
