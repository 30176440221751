export const messages = {
  title: 'Forgot your password?',
  sentTitle: 'Please check your email.',
  subtitle: "Enter your email and we'll send you a link to reset your password",
  sentSubtitle: email =>
    `We sent an email to ${email}, which contains a link to reset your SKYPOD password.`,
  email: 'Email',
  button: 'Reset password',
  cancel: 'Cancel',
  back: 'Back to login'
};
