import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { actionTypes, updateSession } from 'actions/userActions';
import { useStatus, useDispatchOnMount } from 'hooks';
import { SUCCESS } from 'constants/status';
import getSessionInfoFromQuery from 'utils/getSessionInfoFromQuery';

import UpdatePasswordForm from 'components/user/UpdatePasswordForm/index';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import PasswordChanged from './PasswordChanged';

const UpdatePasswordPage = () => {
  const { status } = useStatus(actionTypes.CHANGE_PASSWORD);
  const { search } = useLocation();

  const session = useMemo(() => ({ session: getSessionInfoFromQuery(search) }), [search]);

  useDispatchOnMount(updateSession, session);

  return (
    <div className="bg-gray min-vh-100 d-flex flex-column">
      <Header />
      <div className="container py-4 col-md-10 col-lg-7">
        <div className="bg-white rounded-5 d-flex align-items-center flex-column pt-2 pt-md-9">
          {status === SUCCESS ? (
            <PasswordChanged />
          ) : (
            <UpdatePasswordForm className="p-3 col col-md-10 col-lg-9 px-md-8" />
          )}
        </div>
      </div>
      <div className="d-none d-md-block align-items-end mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default UpdatePasswordPage;
