import { applyQueryParams } from 'utils/helpers';
import { isString } from 'lodash';

export default (id, authInfo) => {
  if (isString(authInfo)) {
    return applyQueryParams(`${process.env.API_URL}/pods/${id}/guest_download`, {
      accessToken: authInfo
    });
  }
  return applyQueryParams(`${process.env.API_URL}/pods/${id}/download`, authInfo);
};
