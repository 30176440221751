import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { getMyPods, actionTypes } from 'actions/podActions';
import { useDispatch, useStatus } from 'hooks';
import { formatDateNormal } from 'utils/dateHelpers';
import moment from 'moment';

import GeneralDataHandler from 'components/common/GeneralDataHandler';
import Paper from 'components/common/Paper';
import EmptyState from 'components/common/EmptyState';
import GiftIcon from 'assets/icons/gift-box.svg';
import cn from 'classnames';

import styles from './MyPods.scss';

const MyPodsComp = ({ availableMyPods }) => {
  return (
    <>
      <Paper className="bg-white h-100 d-flex flex-column rounded-5 mt-3 pt-3 pb-5 px-3 px-md-6 row">
        <div className="p-8 flex-fill">
          <div className="row">
            <div className="col-12 col-md-4 mb-8">
              <h1 className={cn(styles.title)}>Use Your Skypods</h1>
              {/* <h1 className={cn(styles.title)}>My available pods for use</h1> */}
              <p>
                To fill an available pod, click on the “Create Pod” button.
                {/* You can see the purchased or received pods that are ready for you to use or to gift. */}
              </p>
            </div>
            <div className="col-12 col-md-8">
              {availableMyPods && availableMyPods.length > 0 ? (
                <>
                  {availableMyPods.map((pod, index) => (
                    <div className={cn(styles.box)} key={index}>
                      <p className="align-items-center border-bottom d-flex justify-content-between mb-4 pb-2 text-weight-bold">
                        <span>Bundle {index + 1}</span>
                        {pod.podType === 'gift' && (
                          <div className="align-items-center d-flex">
                            <img alt="Gift icon" className="mr-3" src={GiftIcon} />
                            <span>Gift</span>
                          </div>
                        )}
                      </p>
                      <div className="row">
                        <div className="col-lg-3">Total Pods: {pod.bundleSize}</div>
                        <div className="col-lg-3">Available Pods: {pod.availablePods}</div>
                        <div className="col-lg-3">
                          Exp. Time:{' '}
                          {moment(pod.expirationDate).isValid()
                            ? formatDateNormal(pod.expirationDate)
                            : pod.expirationDate}
                        </div>
                        <div className="col-lg-3 text-lg-right">
                          Storage Limit: {pod.storageLimitInMegabytes / 1000} GB
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <EmptyState
                  className="mb-9 mt-9 mb-md-12 mt-md-12"
                  title="You don’t have any available pods yet"
                  subtitle='You can purchase pods to use by clicking "Create pod" or receive them as a gift'
                />
              )}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

const MyPods = () => {
  const myPods = useDispatch(getMyPods);
  const availableMyPods = useSelector(({ pods }) => pods.myPods);

  useEffect(() => {
    myPods();
  }, [myPods]);

  const { status } = useStatus(actionTypes.GET_MY_PODS);

  return (
    <GeneralDataHandler
      status={status}
      SuccessComponent={MyPodsComp}
      successComponentProps={{ availableMyPods }}
    />
  );
};

export default MyPods;
