const ACCESS_TOKEN = 'access-token';
const UID = 'uid';
const CLIENT = 'client';

export default query => {
  const params = new URLSearchParams(query);
  return {
    token: params.get(ACCESS_TOKEN),
    uid: params.get(UID),
    client: params.get(CLIENT)
  };
};
