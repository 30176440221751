import React from 'react';
import { number } from 'prop-types';

const Alert = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9998 45.8337C36.5058 45.8337 45.8332 36.5063 45.8332 25.0003C45.8332 13.4944 36.5058 4.16699 24.9998 4.16699C13.4939 4.16699 4.1665 13.4944 4.1665 25.0003C4.1665 36.5063 13.4939 45.8337 24.9998 45.8337Z"
      stroke="#F35F68"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 16.667V25.0003"
      stroke="#F35F68"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 33.333H25.0214"
      stroke="#F35F68"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Alert.propTypes = {
  width: number,
  height: number
};

Alert.defaultProps = {
  width: 50,
  height: 50
};

export default Alert;
