import React from 'react';
import { oneOf, number, string, func, bool } from 'prop-types';
import { white, darkBlue } from 'styles/_constants.scss';
import styles from './Arrow.scss';

const Arrow = ({ type, height, width, colorType, className, onClick, backArrow }) => {
  const color = {
    light: white,
    dark: darkBlue
  };

  const styleType = styles[type];

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${styleType} ${className}`}
      fill="none"
      onClick={onClick}
      viewBox="0 0 16 16"
    >
      <path
        stroke={color[colorType]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 8L8 2 2 8"
      />
    </svg>
  );

  return backArrow ? (
    <div className="d-flex align-items-center text-weight-bold">
      {icon}
      <div
        style={{ color: colorType === 'light' ? 'white' : 'black' }}
        className="text-weight-bold clickable"
        onClick={onClick}
      >
        Back
      </div>
    </div>
  ) : (
    icon
  );
};

Arrow.propTypes = {
  type: oneOf(['up', 'down', 'left', 'right']),
  height: number,
  width: number,
  colorType: oneOf(['light', 'dark']),
  className: string,
  onClick: func,
  backArrow: bool
};

Arrow.defaultProps = {
  type: 'up',
  height: 16,
  width: 16,
  colorType: 'dark',
  className: '',
  backArrow: false
};

export default Arrow;
