import createReducer from 'reducers/createReducer';
import { actionTypes as giftActions } from 'actions/giftActions';

const initialState = {
  available_pods: [],
  gifts: [],
  gift_events: []
};

const handleGetGiftsSuccess = (state, data) => {
  state.available_pods = data.availablePods;
  state.gifts = data.gifts;
  state.gift_events = data.giftEvents;
};

export default createReducer(initialState, {
  [giftActions.GET_GIFTS_SUCCESS]: handleGetGiftsSuccess
});
