import React, { memo } from 'react';
import { string, bool, object } from 'prop-types';
import cn from 'classnames';

import { ReactComponent as Clock } from 'assets/icons/small-clock.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';

import { ACCEPTED, REJECTED } from './constants';

const Recipient = ({ recipient: { registered, email, userName, state }, last, className }) => {
  let textClassname = 'text-gray';
  let Icon = Clock;
  let bgClassname = 'bg-gray';
  let text = 'PENDING';

  if (state === ACCEPTED) {
    Icon = Check;
    bgClassname = 'bg-blue';
    textClassname = '';
    text = 'ACCEPTED';
  } else if (state === REJECTED) {
    Icon = Cross;
    bgClassname = 'bg-red';
    text = 'REJECTED';
  }

  return (
    <span className={className}>
      <span className={cn('text-weight-semibold text-break', textClassname)}>
        {registered ? userName : email}
      </span>
      <span
        className={cn('rounded-5 px-2 py-1 ml-2 d-inline-flex  align-items-center', bgClassname)}
      >
        <Icon className="mr-2" />
        <span className={cn(textClassname, 'text-x-small')}>{text}</span>
      </span>
      <span className={cn('p-1 d-none d-md-inline', { 'border-right mx-3': !last })} />
    </span>
  );
};

Recipient.propTypes = {
  recipient: object.isRequired,
  className: string,
  last: bool
};

Recipient.defaultProps = {
  className: '',
  last: false
};

export default memo(Recipient);
