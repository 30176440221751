import createAction from 'actions/createAction';
import podService from 'services/podService';
import parseError from 'utils/parseError';
import { dateToIso, setTimeToDate, timeToString } from 'utils/dateHelpers';

export const actionTypes = {
  GET_OUTGOING_PODS: 'GET_OUTGOING_PODS',
  GET_OUTGOING_PODS_REQUEST: 'GET_OUTGOING_PODS_REQUEST',
  GET_OUTGOING_PODS_SUCCESS: 'GET_OUTGOING_PODS_SUCCESS',
  GET_OUTGOING_PODS_ERROR: 'GET_OUTGOING_PODS_ERROR',
  UPDATE_POD: 'UPDATE_POD',
  UPDATE_POD_SUCCESS: 'UPDATE_POD_SUCCESS',
  UPDATE_POD_ERROR: 'UPDATE_POD_ERROR',
  UPDATE_POD_REQUEST: 'UPDATE_POD_REQUEST',
  UPDATE_POD_RESET: 'UPDATE_POD_RESET',
  GET_OUTGOING_POD: 'GET_OUTGOING_POD',
  GET_OUTGOING_POD_REQUEST: 'GET_OUTGOING_POD_REQUEST',
  CLEAR_OUTGOING_POD: 'CLEAR_OUTGOING_POD',
  GET_OUTGOING_POD_ERROR: 'GET_OUTGOING_POD_ERROR',
  GET_OUTGOING_POD_SUCCESS: 'GET_OUTGOING_POD_SUCCESS'
};

export const getOutgoingPodsSuccess = createAction(actionTypes.GET_OUTGOING_PODS_SUCCESS);
export const getOutgoingPodsError = createAction(actionTypes.GET_OUTGOING_PODS_ERROR);
export const getOutgoingPodsRequest = createAction(actionTypes.GET_OUTGOING_PODS_REQUEST);

export const getOutgoingPodSuccess = createAction(actionTypes.GET_OUTGOING_POD_SUCCESS);
export const getOutgoingPodError = createAction(actionTypes.GET_OUTGOING_POD_ERROR);
export const getOutgoingPodRequest = createAction(actionTypes.GET_OUTGOING_POD_REQUEST);

export const clearOutgoingPod = createAction(actionTypes.CLEAR_OUTGOING_POD);

export const updatePodSuccess = createAction(actionTypes.UPDATE_POD_SUCCESS);
export const updatePodError = createAction(actionTypes.UPDATE_POD_ERROR);
export const updatePodRequest = createAction(actionTypes.UPDATE_POD_REQUEST);
export const updatePodReset = createAction(actionTypes.UPDATE_POD_RESET);

export const getOutgoingPods = () => async dispatch => {
  dispatch(getOutgoingPodsRequest());
  try {
    const { data } = await podService.getOutgoingPods();
    dispatch(getOutgoingPodsSuccess({ data }));
  } catch ({ data }) {
    dispatch(getOutgoingPodsError(parseError(data)));
  }
};

export const getOutgoingPod = id => async dispatch => {
  dispatch(getOutgoingPodRequest());
  try {
    const { data } = await podService.getOutgoingPod(id);
    dispatch(getOutgoingPodSuccess({ data }));
  } catch ({ data }) {
    dispatch(getOutgoingPodError(parseError(data)));
  }
};

export const updatePod = (id, pod) => async dispatch => {
  dispatch(updatePodRequest());

  const timePeriod = timeToString(
    pod.time?.hour.value,
    pod.time?.minutes.value,
    pod.time?.period.value
  );
  const procDate = setTimeToDate(pod.date, timePeriod);
  const openingDate = dateToIso(procDate, pod.time?.timezone.value);

  const procPod = {
    ...(pod.description && { description: pod.description }),
    ...(pod.coverImage && { coverImage: pod.coverImage }),
    ...(pod.title && { title: pod.title }),
    ...(pod.date && { openingDate })
  };

  try {
    await podService.updatePod(id, { pod: procPod });
    dispatch(updatePodSuccess());
  } catch ({ data }) {
    dispatch(updatePodError(parseError(data)));
  }
};
