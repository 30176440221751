import { useState, useMemo } from 'react';

export default (slides, initialSlide = 0) => {
  const [slide, setSlide] = useState(initialSlide);
  const { length } = slides;

  const nextSlide = useMemo(() => {
    if (slide >= length - 1) return;
    return () => setSlide(slide + 1);
  }, [length, slide]);

  const previousSlide = useMemo(() => {
    if (slide <= 0) return;
    return () => setSlide(slide - 1);
  }, [slide]);

  return { slide, previousSlide, nextSlide };
};
