import React, { memo, useState, useCallback } from 'react';
import { object, func, number, bool } from 'prop-types';
import Crop from 'react-image-crop';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';

import getCroppedImg from './getCrop';

const CropModal = ({ aspectRatio, image: { data, ...rest }, onCrop, onClose, circular }) => {
  const [crop, setCrop] = useState({
    aspect: aspectRatio,
    unit: '%'
  });

  const [imageRef, setImageRef] = useState();

  const onImageLoaded = image => {
    setImageRef(image);
    const newCrop = { ...crop };

    if (image.width / image.height > aspectRatio) {
      newCrop.height = 100;
      newCrop.x = (1 / 2 - (image.height * aspectRatio) / 2 / image.width) * 100;
    } else {
      newCrop.width = 100;
      newCrop.y = (1 / 2 - image.width / aspectRatio / 2 / image.height) * 100;
    }

    setCrop(newCrop);
  };

  const submit = useCallback(() => {
    const croppedImage = getCroppedImg(imageRef, crop);
    onCrop({ ...rest, data: croppedImage });
    onClose();
  }, [crop, imageRef, onClose, onCrop, rest]);

  return (
    <Modal>
      <div className="d-flex flex-column h-100 align-items-center">
        <div className="h-25 text-inverted font-weight-medium text-h3 d-flex align-items-center mx-9">
          Crop Photo
        </div>
        <Crop
          className="my-auto"
          onImageLoaded={onImageLoaded}
          src={data}
          crop={crop}
          onChange={crop => imageRef && setCrop(crop)}
          circularCrop={circular}
          keepSelection
          minWidth={50}
        />
        <Button
          className="mt-auto clickable text-inverted font-weight-medium text-h5 d-flex align-items-center px-10 px-md-12 py-2"
          onClick={submit}
          disabled={!crop.width}
        >
          Save Photo
        </Button>
        <span
          className="clickable text-inverted text-weight-medium mt-4 mb-4 mb-md-12"
          onClick={() => onClose()}
        >
          Cancel
        </span>
      </div>
    </Modal>
  );
};

CropModal.propTypes = {
  image: object.isRequired,
  onClose: func.isRequired,
  onCrop: func.isRequired,
  aspectRatio: number,
  circular: bool
};

export default memo(CropModal);
