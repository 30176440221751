import { CREATEPOD_FIELDS } from 'constants/fields';

export const messages = {
  titles: ['Pod', 'Recipients', 'Opening Date']
};

export const extras = {
  size: [{ message: '1 extra month after pod is opened on us until it expires', price: 0 }],
  gift: [],
  bundle: []
};

export const checkboxes = [
  {
    id: CREATEPOD_FIELDS.checkViewableDays,
    title: 'I understand this Pod will only be viewable for 30 days once opened.'
  },
  {
    id: CREATEPOD_FIELDS.checkNoRefunds,
    title: 'I understand there are no refunds - even if Pod is cancelled.'
  },
  {
    id: CREATEPOD_FIELDS.checkPodAccepted,
    title:
      'Once a pod is sent, the contents can no longer be edited. You can still change the open date or cancel it before the open date.'
  }
];
