import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { useDispatchOnMount, useStatus } from 'hooks';
import { getContacts, actionTypes } from 'actions/contactsActions';

import GeneralDataHandler from 'components/common/GeneralDataHandler';
import EmptyState from 'components/common/EmptyState';

import { messages } from './constants';
import Contact from './Contact';

const ContactListComp = memo(({ contacts }) => (
  <div className="bg-white px-3 px-md-8 pt-2 pb-6 rounded-top-5 flex-fill">
    {!contacts.length && (
      <EmptyState
        className="py-md-10"
        title={messages.emptyTitle}
        subtitle={messages.emptySubtitle}
      />
    )}

    {contacts.map(contact => (
      <Contact key={contact.email} {...contact} />
    ))}
  </div>
));

const ContactList = () => {
  useDispatchOnMount(getContacts);
  const contacts = useSelector(({ contacts: { contactsList } }) => contactsList);

  const { status } = useStatus(actionTypes.GET_CONTACTS);

  return (
    <GeneralDataHandler
      status={status}
      SuccessComponent={ContactListComp}
      successComponentProps={{ contacts }}
    />
  );
};

export default memo(ContactList);
