import React from 'react';
import { string, number } from 'prop-types';
import { gray } from 'styles/_constants.scss';

const Gift = ({ color, height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="clip-Web_1920_1">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
    <g id="Web_1920_1" data-name="Web 1920 – 1" clipPath="url(#clip-Web_1920_1)">
      <rect width="24" height="24" fill="#fff" />
      <path
        fill={color}
        id="gift"
        d="M21.156,21.836H19.364a3.769,3.769,0,0,0,.7-2.189A3.533,3.533,0,0,0,16.665,16c-1.929,0-3.046,1.158-4.993,4.368C9.725,17.158,8.608,16,6.68,16a3.533,3.533,0,0,0-3.4,3.648,3.769,3.769,0,0,0,.7,2.189H2.189A2.189,2.189,0,0,0,0,24.025V28.4a.732.732,0,0,0,.73.73h.73V35.7a2.189,2.189,0,0,0,2.189,2.189H19.7A2.189,2.189,0,0,0,21.886,35.7V29.131h.73a.732.732,0,0,0,.73-.73V24.025A2.189,2.189,0,0,0,21.156,21.836Zm-8.563-.164c2.257-3.8,3.009-4.213,4.072-4.213A2.077,2.077,0,0,1,18.6,19.648a2.077,2.077,0,0,1-1.938,2.189H12.493ZM6.68,17.459c1.067,0,1.815.415,4.072,4.213l.1.164H6.675a2.077,2.077,0,0,1-1.938-2.189A2.084,2.084,0,0,1,6.68,17.459ZM8.754,36.427H3.648a.732.732,0,0,1-.73-.73V29.131H8.754Zm0-8.754h-7.3V24.025a.732.732,0,0,1,.73-.73H8.754Zm4.377,8.754H10.213V23.3h2.918Zm7.3-.73a.732.732,0,0,1-.73.73H14.59v-7.3h5.836Zm1.459-8.025h-7.3V23.3h6.566a.732.732,0,0,1,.73.73Z"
        transform="translate(0.328 -14.943)"
      />
    </g>
  </svg>
);

Gift.propTypes = {
  color: string,
  width: number,
  height: number
};

Gift.defaultProps = {
  color: gray,
  width: 24,
  height: 24
};

export default Gift;
