import React from 'react';
import { bool, string, func, node } from 'prop-types';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';

const UploadFiles = ({
  rootClassName,
  customclassName,
  accept,
  multiple,
  onChange,
  children,
  disabled,
  noDrag
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onChange,
    accept,
    multiple,
    disabled,
    noDrag
  });

  return (
    <div {...getRootProps()} className={cn(['clickable', rootClassName])}>
      <div className={customclassName}>
        <input {...getInputProps()} />
        {children}
      </div>
    </div>
  );
};

UploadFiles.propTypes = {
  rootClassName: string,
  customclassName: string,
  accept: string,
  multiple: bool,
  onChange: func.isRequired,
  children: node.isRequired,
  disabled: bool,
  noDrag: bool
};

UploadFiles.defaultProps = {
  multiple: false,
  disabled: false,
  noDrag: false
};

export default UploadFiles;
