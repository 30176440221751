/*
import { useMemo } from 'react';
*/

export default (size, priceList) => {
  if (!priceList) return;

  let minStorage;
  let minIndex;
  priceList.forEach(({ storageLimitInBytes }, index) => {
    if (size <= storageLimitInBytes) {
      if (!minStorage || minStorage > storageLimitInBytes) {
        minStorage = storageLimitInBytes;
        minIndex = index;
      }
    }
  });
  return priceList[minIndex] || priceList[priceList.length - 1];
};
