import React, { memo } from 'react';
import { string, object, func } from 'prop-types';

const DeleteCoverImage = ({ width, height, className, onClick, disableOnClick }) => (
  <div {...disableOnClick}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M3.5 7H5.83333H24.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 6.99967V4.66634C9.3335 4.0475 9.57933 3.45401 10.0169 3.01643C10.4545 2.57884 11.048 2.33301 11.6668 2.33301H16.3335C16.9523 2.33301 17.5458 2.57884 17.9834 3.01643C18.421 3.45401 18.6668 4.0475 18.6668 4.66634V6.99967M22.1668 6.99967V23.333C22.1668 23.9518 21.921 24.5453 21.4834 24.9829C21.0458 25.4205 20.4523 25.6663 19.8335 25.6663H8.16683C7.54799 25.6663 6.9545 25.4205 6.51691 24.9829C6.07933 24.5453 5.8335 23.9518 5.8335 23.333V6.99967H22.1668Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6665 12.833V19.833"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3335 12.833V19.833"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

DeleteCoverImage.propTypes = {
  width: string,
  height: string,
  className: string,
  onClick: func,
  disableOnClick: object
};

DeleteCoverImage.defaultProps = {
  width: '28',
  height: '28',
  className: ''
};

export default memo(DeleteCoverImage);
