import React, { memo, useState, useRef, useEffect } from 'react';
import { func, object } from 'prop-types';

import { useSession } from 'hooks';
import { handleImgOrientation } from 'utils/filesHelpers';

import Avatar from 'components/user/Avatar';
import Camera from 'assets/icons/camera';
import CropModal from 'components/common/CropModal';

import { SUCCESS, ERROR } from 'constants/status';

import styles from './ProfilePicture.scss';
import { ERROR_IMG_LOADING } from './constants';

const profileAspectRatio = 1;

const ProfilePicture = ({ onChangeAvatar, updateStatus }) => {
  const {
    user: { profileImage }
  } = useSession();

  const [picture, setPicture] = useState();
  const inputRef = useRef();

  const openFilePicker = () => {
    if (inputRef.current) {
      inputRef.current.value = null;
      inputRef.current.click();
    }
  };

  const [imageUploading, setImageUploading] = useState();

  useEffect(() => {
    if ([SUCCESS, ERROR].includes(updateStatus) && imageUploading) {
      setImageUploading();
      window.onbeforeunload = null;
    }
  }, [imageUploading, updateStatus]);

  const handleFilePick = async ({ target: { files } }) => {
    handleImgOrientation(
      files[0],
      data => setPicture({ ...files[0], data }),
      () => alert(ERROR_IMG_LOADING)
    );
  };

  const handleSubmit = picture => {
    setPicture(picture);
    onChangeAvatar(picture.data);

    setImageUploading(true);
    window.onbeforeunload = () => true;
  };

  return (
    <div className="position-relative">
      {picture && (
        <CropModal
          image={picture}
          onClose={setPicture}
          aspectRatio={profileAspectRatio}
          onCrop={handleSubmit}
          circular
        />
      )}
      <Avatar
        className="m-auto"
        iconClassName="m-auto"
        image={picture?.data || profileImage}
        loading={imageUploading}
      />
      <div className={styles.cameraWrapper}>
        <Camera onClick={openFilePicker} className="clickable" />
        <input
          accept="image/png, image/jpeg"
          ref={inputRef}
          type="file"
          onChange={handleFilePick}
          className="hidden"
        />
      </div>
    </div>
  );
};

ProfilePicture.propTypes = {
  onChangeAvatar: func,
  updateStatus: object
};

export default memo(ProfilePicture);
