import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'hooks';
import { replaceLandingTab } from 'utils/tabHelpers';
import { signUp, login } from 'actions/userActions';

export default code => {
  const signUpRequest = useDispatch(signUp);
  const loginRequest = useDispatch(login);

  const { push } = useHistory();

  const handleSignup = useCallback(values => signUpRequest({ ...values, code }), [
    code,
    signUpRequest
  ]);

  const handleTabChange = useCallback(tab => push(replaceLandingTab(tab, code)), [code, push]);

  return {
    handleTabChange,
    handleLogin: loginRequest,
    handleSignup
  };
};
