import humps from 'humps';
import { isEmpty, isArray, isString, isNumber, isDate, isPlainObject } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';

import { DATE_FORMAT, LAST_RETRY } from 'constants/constants';

export const applyQueryParams = (url, params = {}) => {
  if (isEmpty(params)) {
    return url;
  }
  const queryParams = queryString.stringify(humps.decamelizeKeys(params));
  return `${url}?${queryParams}`;
};

export const truncateString = (input, maxLength) =>
  input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;

export const truncateFileName = (
  fileName,
  maxLength,
  acceptedLength = 10,
  firstIndex = 4,
  lastIndex = 6
) => {
  const nameLength = fileName.length;
  if (maxLength > acceptedLength && nameLength > maxLength) {
    const firstPart = fileName.substring(0, firstIndex);
    const lastPart = fileName.substring(nameLength - lastIndex, nameLength);
    return `${firstPart}...${lastPart}`;
  }
  return fileName;
};

export const customBackground = (image, backgroundColor, position, size, gradient) => {
  const imageUrl = `url(${image})`;
  let imageGradient = gradient;
  backgroundColor &&
    (imageGradient = `linear-gradient(0deg, ${backgroundColor}, ${backgroundColor})`);
  return {
    backgroundImage: imageGradient ? `${imageGradient}, ${imageUrl}` : imageUrl,
    backgroundPosition: position,
    backgroundRepeat: 'no-repeat',
    backgroundSize: size
  };
};

export const joinWithComma = list => list.join(', ');

export const formatDataToString = data => {
  if (isString(data)) return data;

  if (isNumber(data)) return data.toString();

  if (isDate(data)) return moment(data).format(DATE_FORMAT);

  if (isArray(data)) return joinWithComma(data.map(d => formatDataToString(d)));

  if (isPlainObject(data)) {
    const keys = Object.keys(data);
    return keys.reduce((accumulator, k) => `${accumulator} ${formatDataToString(data[k])}`, '');
  }
};

export const truncateDecimal = (number, digits) => parseFloat(number.toFixed(digits));

export const isiOS = () => navigator.userAgent.match(/ipad|iphone/i);

export const isMobile = () =>
  navigator.userAgent.match(/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/i);

export const retryCall = async (func, retries) => {
  try {
    return await func();
  } catch (error) {
    if (retries === LAST_RETRY) {
      throw new Error({ data: error });
    }
    return retryCall(func, retries - 1);
  }
};

export const sortByDateAttribute = (a, b, attr, asc = true) => {
  const direction = asc ? 1 : -1;
  if (new Date(a[attr]) < new Date(b[attr])) return -1 * direction;
  if (new Date(a[attr]) > new Date(b[attr])) return 1 * direction;
  return 0;
};
