import React from 'react';
import { number, string } from 'prop-types';

const VideoFile = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 7L16 12L23 17V7Z"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

VideoFile.propTypes = {
  width: number,
  height: number,
  className: string
};

VideoFile.defaultProps = {
  width: 23,
  height: 23
};

export default VideoFile;
