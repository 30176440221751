import React from 'react';

import { CheckInput } from 'components/common/Input';

import { checkboxes } from './constants';

const Checkboxes = ({ onChange, values }) => {
  const checkboxValues = Object.fromEntries(
    Object.entries(values).filter(([key]) => !key.includes('promoCode'))
  );

  const inputProps = inputId => ({
    onChange: e => onChange(inputId, e.target.checked),
    value: checkboxValues[inputId],
    checked: !!checkboxValues[inputId]
  });

  return (
    <div className="col-12 mt-6 mt-md-0">
      <div className="border-top pt-6">
        {checkboxes.map(({ id, title }) => (
          <CheckInput
            key={id}
            type="checkbox"
            input={{ ...inputProps(id) }}
            className="d-flex flex-column pb-3"
          >
            <span className="d-flex align-items-center text-x-small text-weight-regular pl-4">
              {title}
            </span>
          </CheckInput>
        ))}
      </div>
    </div>
  );
};

export default Checkboxes;
