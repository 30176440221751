import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useDispatchOnMount, useDispatch, useStatus } from 'hooks';

import { getOutgoingPod, clearOutgoingPod, actionTypes } from 'actions/outgoingPodActions';

import GeneralDataHandler from 'components/common/GeneralDataHandler';
import Header from 'components/common/Header';
import OutgoingPod from 'components/pods/OutgoingPod';

const OutgoingPodPage = () => {
  const {
    state: { selectedPodId }
  } = useLocation();
  useDispatchOnMount(getOutgoingPod, selectedPodId);

  const outgoingPodClear = useDispatch(clearOutgoingPod);
  useEffect(() => outgoingPodClear, [outgoingPodClear]);

  const pod = useSelector(state => state.pods.outgoingPod) || {};
  const { status } = useStatus(actionTypes.GET_OUTGOING_POD);

  return (
    <div className="bg-gray min-vh-100">
      <Header />
      <div className="container py-4 ">
        <GeneralDataHandler
          status={status}
          SuccessComponent={OutgoingPod}
          successComponentProps={{ pod, className: 'bg-white rounded-5 overflow-hidden' }}
        />
      </div>
    </div>
  );
};

export default OutgoingPodPage;
