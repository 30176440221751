import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import actionStatus from 'reducers/statusReducer';
import events from 'reducers/eventsReducer';
import session from 'reducers/sessionReducer';
import verification from 'reducers/verificationReducer';
import pods from 'reducers/podsReducer';
import campaigns from 'reducers/campaignReducer';
import contacts from 'reducers/contactsReducer';
import gifts from 'reducers/giftsReducer';

const rootReducer = history =>
  combineReducers({
    session,
    router: connectRouter(history),
    events,
    actionStatus,
    verification,
    pods,
    campaigns,
    contacts,
    gifts
  });

export default rootReducer;
