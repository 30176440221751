import React from 'react';
import { number, string } from 'prop-types';

const Tick = ({ width = 13, height = 9, className }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.3111 1L4.22222 7.41667L1 4.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Tick.propTypes = {
  width: number,
  height: number,
  className: string
};

export default Tick;
