import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { func, object } from 'prop-types';
import moment from 'moment';

import { DatePicker } from 'components/common/Input';
import { CREATEPOD_FIELDS } from 'constants/fields';

import TimeSelect from './TimeSelect';
import { messages } from './constants';

const OpenDateForm = ({ onChange, values, errors }) => {
  const { date, time } = values;

  const [maxDate, setMaxDate] = useState();
  const maxOpeningDateMonths = useSelector(
    ({ pods: { maxOpeningDateMonths } }) => maxOpeningDateMonths
  );

  useEffect(() => {
    if (maxOpeningDateMonths) {
      const maxDate = new Date(moment().add(maxOpeningDateMonths, 'months'));
      setMaxDate(maxDate);
    }
  }, [maxOpeningDateMonths]);

  const dayAfterTomorrow = new Date(moment().add(2, 'day'));

  const handleDateChange = value => {
    onChange(CREATEPOD_FIELDS.date, value);
  };

  const handleTimeChange = (hour, minutes, period, timezone) => {
    const newTime = {
      [CREATEPOD_FIELDS.hour]: hour,
      [CREATEPOD_FIELDS.minutes]: minutes,
      [CREATEPOD_FIELDS.period]: period,
      [CREATEPOD_FIELDS.timezone]: timezone
    };
    onChange(CREATEPOD_FIELDS.time, newTime);
  };

  return (
    <div className="col-12 mt-6 mt-md-0">
      <DatePicker
        {...messages[CREATEPOD_FIELDS.date]}
        value={date}
        onChange={handleDateChange}
        minDate={dayAfterTomorrow}
        maxDate={maxDate}
        customError={errors[CREATEPOD_FIELDS.date]}
        className="w-100 mb-5"
        wrapperClassName="w-100"
      />
      <TimeSelect
        {...messages[CREATEPOD_FIELDS.time]}
        className="w-100 mb-5"
        onTimeChange={handleTimeChange}
        value={{ time }}
      />
    </div>
  );
};

OpenDateForm.propTypes = {
  onChange: func.isRequired,
  onBlur: func,
  values: object,
  errors: object
};

OpenDateForm.defaultProps = {
  values: {},
  errors: {}
};

export default memo(OpenDateForm);
