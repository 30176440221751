import api from 'api';
import { applyQueryParams } from 'utils/helpers';
import axios from 'axios';

import { store } from '../index';

class PodService {
  static bundlePayment(data) {
    return api.post('/pods/buy_bundle', data);
  }

  static create(pod) {
    return api.post('/pods', pod);
  }

  static sendFiles(id, data, dispatch, uploadProgress) {
    const { info } = store.getState().session;
    const { token, client, uid } = info;
    const ACCESS_TOKEN = 'access-token';
    const headers = {
      [ACCESS_TOKEN]: token,
      client,
      uid
    };
    return axios({
      method: 'put',
      url: `${process.env.API_URL}/pods/${id}/upload`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers
      },
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        dispatch(uploadProgress({ loaded, total }));
      }
    });
  }

  static downloadPod(id) {
    const { info } = store.getState().session;
    const { token, client, uid } = info;
    const ACCESS_TOKEN = 'access-token';
    const headers = {
      [ACCESS_TOKEN]: token,
      client,
      uid
    };
    return axios({
      method: 'get',
      url: `${process.env.API_URL}/pods/${id}/download`,
      responseType: 'blob',
      headers
    });
  }

  static checkExtraTimeAmount({ id, months }) {
    const url = applyQueryParams(`/pods/${id}/check_extra_time`, { months });
    return api.get(url);
  }

  static setExtraTime({ id, months, token }) {
    return api.post(`/pods/${id}/extra_time`, { months, token });
  }

  static getOutgoingPods() {
    return api.get('/pods');
  }
  static getOutgoingPod(id) {
    return api.get(`/pods/${id}`);
  }
  static getIncomingPods() {
    return api.get('/pods/incoming');
  }
  static getIncomingPod(id) {
    return api.get(`/pods/${id}/incoming`);
  }
  static getGuestIncomingPod(id, accessToken) {
    return api.get(`/pods/${id}/show_guest_incoming?access_token=${accessToken}`);
  }
  static acceptPod(id) {
    return api.post(`/pods/${id}/accept`);
  }
  static acceptGuestPod(id, accessToken) {
    return api.post(`/pods/${id}/guest_accept?access_token=${accessToken}`);
  }
  static rejectPod(id) {
    return api.post(`/pods/${id}/reject`);
  }
  static rejectGuestPod(id, accessToken) {
    return api.post(`/pods/${id}/guest_reject?access_token=${accessToken}`);
  }
  static getPodPricing() {
    return api.get(`/pods/new`);
  }
  static updatePod(id, pod) {
    return api.put(`/pods/${id}`, pod);
  }
  static checkPodAmount({ openingDate, size, actualPodId }) {
    const url = applyQueryParams('/pods/check_amount', {
      openingDate,
      size,
      actual_pod_id: actualPodId
    });
    return api.get(url);
  }
  static cancelPod(id) {
    return api.delete(`/pods/${id}`);
  }
  static getFormatViewingSuggestions() {
    return api.get('/file_viewer_suggestions');
  }

  static checkPromoCode(code) {
    return api.get(`/pods/check_promo_code?promo_code=${code}`);
  }

  static getMyPods() {
    return api.get('/user/available_pods');
  }
}

export default PodService;
