import React, { memo } from 'react';
import { string, object, node } from 'prop-types';
import cn from 'classnames';

import styles from './CheckInput.scss';

const CheckInput = ({
  input,
  placeholder,
  meta: { error, touched },
  customError,
  className,
  children
}) => {
  const showError = (touched && error) || customError;
  return (
    <div className={className}>
      <label className={cn(styles.label, 'd-flex align-items-center pb-3 pl-7 clickable')}>
        <input className="hidden" {...input} placeholder={placeholder} type="checkbox" />
        <span className={styles.customCheckbox} />
        {children}
      </label>
      {showError && (
        <span className="text-alert text-x-small text-weight-semibold">{customError || error}</span>
      )}
    </div>
  );
};

CheckInput.propTypes = {
  input: object,
  placeholder: string,
  meta: object,
  className: string,
  customError: string,
  children: node
};

CheckInput.defaultProps = {
  className: '',
  meta: {}
};

export default memo(CheckInput);
