import createReducer from 'reducers/createReducer';
import { actionTypes as userActionTypes } from 'actions/userActions';
import { actionTypes as podActions } from 'actions/podActions';
import { actionTypes as incomingPodActions } from 'actions/incomingPodActions';
import { actionTypes as outgoingPodActions } from 'actions/outgoingPodActions';

const initialState = {
  outGoingPods: null,
  incomingPods: null,
  pricings: [],
  minimumCost: null,
  discount: null,
  outgoingPod: null,
  incomingPod: null,
  uploadProgress: 0,
  formatViewingSuggestions: null,
  actualPodCount: null,
  actualPod: {},
  actualBundle: null,
  myPods: []
};

const createBundlePaymentSuccess = (state, { data }) => {
  state.actualBundle = data;
};

const handleOutgoingPodsSuccess = (state, { data }) => {
  state.outGoingPods = data;
};

const handleOutgoingPodSuccess = (state, { data: { pod } }) => {
  state.outgoingPod = pod;
};

const handleClearOutgoingPod = state => {
  state.outgoingPod = null;
};

const handleIncomingPodsSuccess = (state, { data }) => {
  state.incomingPods = data;
};

const handleIncomingPodSuccess = (state, { data: { pod } }) => {
  state.incomingPod = pod;
};

const handleNewPodInfoSuccess = (state, { data }) => {
  const {
    actualPod,
    actualPodCount,
    pricings,
    discount,
    minimumCost,
    maximiumOpeningDateMonths
  } = data;
  state.pricings = pricings;
  state.discount = discount || {};
  state.actualPod = actualPod;
  state.actualPodCount = actualPodCount;
  state.discount.type =
    discount?.percentage && discount?.percentage !== 0 ? 'percentage' : 'amount';
  state.minimumCost = minimumCost;
  state.maxOpeningDateMonths = maximiumOpeningDateMonths;
};

const handleCheckPromoCodeSuccess = (state, { data }) => {
  const { promoCode } = data;
  promoCode.discountPercentage
    ? (state.discount.percentage = promoCode.discountPercentage)
    : (state.discount.discount = promoCode.discountAmount);
  state.discount.type = promoCode.discountPercentage ? 'percentage' : 'amount';
};

const handleUploadProgress = (state, { loaded, total }) => {
  const percentageCompleted = Math.round((loaded * 100) / total);
  state.uploadProgress = percentageCompleted;
};

const handleCreationSuccess = state => {
  state.uploadProgress = 0;
};

const handleCreationError = state => {
  state.uploadProgress = 0;
};

const handleExtraTimeSuccess = (state, { extraMonths, expirationDate }) => {
  state.incomingPod = {
    ...state.incomingPod,
    extraMonths,
    expirationDate
  };
};

const handleViewingSuggestionsSuccess = (state, { suggestions }) => {
  state.formatViewingSuggestions = suggestions;
};

const handleGetMyPodsSuccess = (state, data) => {
  state.myPods = data.data.availablePods;
};

const handleLogOut = () => ({ ...initialState });

export default createReducer(initialState, {
  [outgoingPodActions.GET_OUTGOING_PODS_SUCCESS]: handleOutgoingPodsSuccess,
  [incomingPodActions.GET_INCOMING_PODS_SUCCESS]: handleIncomingPodsSuccess,
  [incomingPodActions.GET_INCOMING_POD_SUCCESS]: handleIncomingPodSuccess,
  [podActions.GET_NEW_POD_INFO_SUCCESS]: handleNewPodInfoSuccess,
  [podActions.CHECK_PROMO_CODE_SUCCESS]: handleCheckPromoCodeSuccess,
  [userActionTypes.LOGOUT_REQUEST]: handleLogOut,
  [outgoingPodActions.GET_OUTGOING_POD_SUCCESS]: handleOutgoingPodSuccess,
  [outgoingPodActions.CLEAR_OUTGOING_POD]: handleClearOutgoingPod,
  [podActions.UPLOAD_PROGRESS]: handleUploadProgress,
  [podActions.CREATE_SUCCESS]: handleCreationSuccess,
  [podActions.CREATE_ERROR]: handleCreationError,
  [podActions.GET_EXTRA_TIME_SUCCESS]: handleExtraTimeSuccess,
  [podActions.GET_VIEWING_SUGGESTIONS_SUCCESS]: handleViewingSuggestionsSuccess,
  [podActions.CREATE_BUNDLE_PAYMENT_SUCCESS]: createBundlePaymentSuccess,
  [podActions.GET_MY_PODS_SUCCESS]: handleGetMyPodsSuccess
});
