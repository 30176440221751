import api from 'api';

class UserService {
  static login(user) {
    return api.post('/users/sign_in', { user });
  }

  static fblogin(user) {
    return api.post('/user/social_login', { user });
  }

  static logout() {
    return api.delete('/users/sign_out');
  }

  static signUp(user) {
    return api.post('/users', { user });
  }

  static confirm(email) {
    return api.post('/users/confirmation', { email });
  }

  static update(user) {
    return api.put('/user', { user });
  }

  static requestPasswordChange(email) {
    return api.post('/users/password', { email });
  }

  static changePassword(passwordWithConfirmation) {
    return api.put('/users/password', passwordWithConfirmation);
  }

  static getProfile() {
    return api.get('/user/profile');
  }

  static autoLogin(userId) {
    return api.post('/user/user_auto_login', { userId });
  }
}

export default UserService;
