import React, { memo, useState, useCallback, useEffect } from 'react';
import { func, string, object } from 'prop-types';
import Select from 'react-select';

import { PERIOD } from 'constants/constants';
import { getMinutesInterval, getHours } from 'utils/selectOptions';
import { timezones } from '../constants';
import { stylesSelect } from './styles';
import styles from './TimeSelect.scss';

const TimeSelect = ({ className, label, onTimeChange, value }) => {
  const {
    time: { hour, minutes, period, timezone }
  } = value;
  const hours = getHours().map(hour => ({ value: parseInt(hour, 10), label: hour }));
  const allMinutes = getMinutesInterval(15).map(minutes => ({
    value: minutes,
    label: minutes
  }));
  const periods = [{ value: PERIOD.AM, label: PERIOD.AM }, { value: PERIOD.PM, label: PERIOD.PM }];

  const defaultHour = hours[hours.length - 1];
  const defaultMinutes = allMinutes[0];
  const defaultPeriod = periods[0];
  const defaultTimezone = timezones[1];

  const [selectedHour, setHour] = useState(hour || defaultHour);
  const [selectedMinutes, setMinutes] = useState(minutes || defaultMinutes);
  const [selectedPeriod, setPeriod] = useState(period || defaultPeriod);
  const [selectedTimezone, setTimezone] = useState(timezone || defaultTimezone);

  useEffect(() => {
    !hour && onTimeChange(defaultHour, defaultMinutes, defaultPeriod, defaultTimezone);
  }, []);

  const handleHourChange = useCallback(
    newValue => {
      setHour(newValue);
      onTimeChange(newValue, selectedMinutes, selectedPeriod, selectedTimezone);
    },
    [onTimeChange, selectedMinutes, selectedPeriod, selectedTimezone]
  );

  const handleMinuteChange = useCallback(
    newValue => {
      setMinutes(newValue);
      onTimeChange(selectedHour, newValue, selectedPeriod, selectedTimezone);
    },
    [onTimeChange, selectedHour, selectedPeriod, selectedTimezone]
  );

  const handlePeriodChange = useCallback(
    newValue => {
      setPeriod(newValue);
      onTimeChange(selectedHour, selectedMinutes, newValue, selectedTimezone);
    },
    [onTimeChange, selectedHour, selectedMinutes, selectedTimezone]
  );

  const handleTimezoneChange = useCallback(
    newValue => {
      setTimezone(newValue);
      onTimeChange(selectedHour, selectedMinutes, selectedPeriod, newValue);
    },
    [onTimeChange, selectedHour, selectedMinutes, selectedPeriod]
  );

  return (
    <div className={className}>
      <label className="text-x-small text-weight-semibold d-block mb-2">{label}</label>
      <div className="d-flex pl-md-0 justify-content-between mobile_view_box">
        <Select
          maxMenuHeight={200}
          className={styles.time}
          styles={stylesSelect}
          value={selectedHour}
          onChange={handleHourChange}
          options={hours}
        />
        <span className="my-auto">:</span>
        <Select
          className={styles.time}
          styles={stylesSelect}
          value={selectedMinutes}
          onChange={handleMinuteChange}
          options={allMinutes}
        />
        <Select
          className={styles.time}
          styles={stylesSelect}
          value={selectedPeriod}
          onChange={handlePeriodChange}
          options={periods}
        />
        <Select
          className={styles.timezone}
          onChange={handleTimezoneChange}
          options={timezones}
          value={selectedTimezone}
          isSearchable={false}
          styles={stylesSelect}
        />
      </div>
    </div>
  );
};

TimeSelect.propTypes = {
  className: string,
  onTimeChange: func.isRequired,
  label: string,
  value: object.isRequired
};

TimeSelect.defaultProps = {
  label: '',
  className: ''
};

export default memo(TimeSelect);
