import React from 'react';

import Item from './Item';

const SizeItems = ({ size, sizePrice, timeTag, datePrice, subTotal, discount, discountText }) => {
  return (
    <>
      <Item title={`${size}`} price={`$ ${sizePrice}`} />
      <Item title={timeTag} price={`x${datePrice}`} />
      <Item title="SubTotal" price={`$ ${subTotal.toFixed(2)}`} strikethrough={discount} />
      {discount > 0 && <Item title="Discount" price={discountText} discount />}
    </>
  );
};

export default SizeItems;
