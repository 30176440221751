import React, { useEffect } from 'react';
import cn from 'classnames';
import { number } from 'prop-types';

import Card from 'components/common/Card';
import styles from './CreatePodProcessPage.scss';

import { messages } from './constants';

const CreatePodProcessPage = ({ progress }) => {
  useEffect(() => {
    window.onbeforeunload = () => true;
    return () => {
      window.onbeforeunload = null;
    };
  });

  return (
    <div className="d-flex flex-column min-vh-100 h-100">
      <div className="bg-gray h-100">
        <div className="container p-md-9">
          <Card
            className={cn(
              'container d-flex flex-column align-items-center justify-content-center px-4 mt-5 rounded-5 background-card',
              styles.card
            )}
          >
            <p className="text-h3 mb-6 text-center">{messages.title}</p>
            <div className={cn('bg-gray rounded-5 w-75', styles.progressBar)}>
              <div
                style={{ width: `${progress}%` }}
                className={cn('h-100 rounded-5', styles.progressCovered)}
              />
            </div>
            <p className="text-center pt-10">{messages.disclaimer}</p>
          </Card>
        </div>
      </div>
    </div>
  );
};

CreatePodProcessPage.propTypes = {
  progress: number.isRequired
};

export default CreatePodProcessPage;
