// Max file size: 20MB
export const MAX_FILE_SIZE = 20;

export const ACCEPT_FORMATS = {
  short: 'jpeg, png, jpg',
  long: 'image/jpeg, image/png, image/jpg'
};

export const MESSAGES = {
  unsupportedFile: 'You can only add a single image that is within the accepted files',
  overMaxSize: 'The image you uploaded is too big',
  corruptedFile: 'The file could not be loaded. Please try with another image.'
};

export const INPUT = 'coverImage';
