import React from 'react';
import { string } from 'prop-types';
import cn from 'classnames';

import { ReactComponent as EmptyBox } from 'assets/images/box-empty.svg';

const EmptyState = ({ title, subtitle, className }) => (
  <div className={cn('d-flex flex-column align-center', className)}>
    <EmptyBox className="mx-auto" />
    <span className="text-center text-weight-medium text-h5 text-gray my-4">{title}</span>
    <span className="d-none d-md-block text-center text-gray mt-2">{subtitle} </span>
  </div>
);

EmptyState.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  className: string
};

EmptyState.defaultProps = {
  className: ''
};

export default EmptyState;
