import React, { memo, useState, useCallback } from 'react';
import { func, object } from 'prop-types';

import { useTextInputProps } from 'hooks';

import { Inputs } from 'components/common/Input';
import CoverImage from 'components/createPod/CoverImage';
import CropModal from 'components/common/CropModal';

import { CREATEPOD_FIELDS } from 'constants/fields';

import { messages } from './constants';
import styles from './BasicInfoPodForm.scss';

const coverAspectRatio = 16 / 9;

const BasicInfoPodForm = ({ onChange, onBlur, values, errors, setSubmitDisabled }) => {
  const inputTextProps = useTextInputProps(onChange, onBlur, values);
  const [rawImage, setRawImage] = useState();

  const changeCover = useCallback(
    image => {
      onChange(CREATEPOD_FIELDS.coverImage, image);
    },
    [onChange]
  );

  const onCloseModal = useCallback(() => setRawImage(), []);

  const clearImage = useCallback(() => onChange(CREATEPOD_FIELDS.coverImage), [onChange]);

  return (
    <div className="mt-6 mt-md-0">
      {rawImage && (
        <CropModal
          aspectRatio={coverAspectRatio}
          onClose={onCloseModal}
          onCrop={changeCover}
          image={rawImage}
        />
      )}
      <Inputs
        {...messages[CREATEPOD_FIELDS.title]}
        type="text"
        customError={errors[CREATEPOD_FIELDS.title]}
        className="mb-4"
        input={{
          value: inputTextProps(CREATEPOD_FIELDS.title).value,
          onChange: e => {
            if (e.target.value !== inputTextProps(CREATEPOD_FIELDS.title).value) {
              onChange(inputTextProps(CREATEPOD_FIELDS.title).id, e.target.value, true);
            }
          }
        }}
      />
      <CoverImage
        onChange={setRawImage}
        onClear={clearImage}
        value={inputTextProps(CREATEPOD_FIELDS.coverImage).value}
        setSubmitDisabled={setSubmitDisabled}
      />
      <Inputs
        {...messages[CREATEPOD_FIELDS.description]}
        customError={errors[CREATEPOD_FIELDS.description]}
        inputClassName={styles.textarea}
        className="mb-4"
        as="textarea"
        input={{
          value: inputTextProps(CREATEPOD_FIELDS.description).value,
          onChange: e => {
            if (e.target.value !== inputTextProps(CREATEPOD_FIELDS.description).value) {
              onChange(inputTextProps(CREATEPOD_FIELDS.description).id, e.target.value, true);
            }
          }
        }}
      />
    </div>
  );
};

BasicInfoPodForm.propTypes = {
  onChange: func.isRequired,
  onBlur: func,
  values: object,
  errors: object,
  setSubmitDisabled: func
};

BasicInfoPodForm.defaultProps = {
  values: {},
  errors: {},
  onBlur: () => {}
};

export default memo(BasicInfoPodForm);
