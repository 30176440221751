import { SIGNUP_FIELDS } from 'constants/fields';
import { SIGNUP_ERROR } from './errorMessages';

export default {
  [SIGNUP_FIELDS.name]: {
    presence: true
  },
  [SIGNUP_FIELDS.password]: {
    presence: true,
    length: { minimum: 8 }
  },
  [SIGNUP_FIELDS.passwordConfirmation]: {
    presence: true,
    equality: { attribute: 'password' }
  },
  [SIGNUP_FIELDS.telephone]: {
    presence: true,
    length: {
      minimum: 10,
      tokenizer(value) {
        return value.replace(/([^\d+])/g, '');
      }
    }
  },
  [SIGNUP_FIELDS.referral]: {},
  [SIGNUP_FIELDS.terms]: {
    presence: {
      presence: true,
      message: SIGNUP_ERROR[SIGNUP_FIELDS.terms]
    },
    type: {
      type: value => !!value,
      message: SIGNUP_ERROR[SIGNUP_FIELDS.terms]
    }
  }
};
