import { CREATEPOD_FIELDS } from 'constants/fields';

export const messages = {
  [CREATEPOD_FIELDS.title]: {
    label: 'Title',
    placeholder: 'Add a title for your pod'
  },
  [CREATEPOD_FIELDS.description]: {
    label: 'Message',
    placeholder: 'This can only be read once the pod is opened by the recipient(s)'
  }
};
