import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { getGifts, actionTypes } from 'actions/giftActions';
import { useDispatch, useStatus } from 'hooks';
import { formatDateExtended } from 'utils/dateHelpers';

import GeneralDataHandler from 'components/common/GeneralDataHandler';
import Paper from 'components/common/Paper';
import EmptyState from 'components/common/EmptyState';
import cn from 'classnames';
import GiftForm from './GiftForm';
import styles from './Title.scss';

const GiftPodsComp = ({ availablePods, giftEvents }) => {
  return (
    <>
      <Paper className="bg-white rounded-5 mt-3 pt-3 pb-5 px-3 px-md-6 row">
        <div className="col-md-6 mb-8 mb-sm-0 left">
          <p className={`text-weight-bold mb-8 ${cn(styles.title)}`}>Gift Timeline</p>
          {giftEvents && giftEvents.length > 0 ? (
            <>
              {giftEvents.map((gift, index) => (
                <div className="border-bottom py-3" key={index}>
                  <p className="text-gray mb-2">{formatDateExtended(gift.createdAt)}</p>
                  <p>{gift.message}</p>
                </div>
              ))}
            </>
          ) : (
            <EmptyState
              className="mb-9 mt-9 mb-md-12 mt-md-12"
              title="You don't have any gifts to show yet"
              subtitle="Once you send or receive pod gifts it will appear here"
            />
          )}
        </div>

        <div className="col-md-6 right">
          <p className={`text-weight-bold mb-8 ${cn(styles.title)}`}>Give the Gift of Skypod</p>

          {availablePods && availablePods.length > 0 ? (
            <GiftForm availablePods={availablePods} />
          ) : (
            <EmptyState
              className="mb-9 mt-9 mb-md-12 mt-md-12"
              title="You don't have any pods to gift"
              subtitle='If you would like to send a pod as a gift, you can get one by clicking on "Create pod"'
            />
          )}
        </div>
      </Paper>
    </>
  );
};

const GiftPods = () => {
  const gifts = useDispatch(getGifts);
  const availablePods = useSelector(({ gifts }) => gifts.available_pods);
  const sentGifts = useSelector(({ gifts }) => gifts.gifts);
  const giftEvents = useSelector(({ gifts }) => gifts.gift_events);
  useEffect(() => {
    gifts();
  }, [gifts]);

  const { status } = useStatus(actionTypes.GET_GIFTS);

  return (
    <GeneralDataHandler
      status={status}
      SuccessComponent={GiftPodsComp}
      successComponentProps={{ sentGifts, availablePods, giftEvents }}
    />
  );
};

export default GiftPods;
