import { useState, useCallback } from 'react';

const useTabs = ({ initialValues }) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = useCallback(key => {
    const newValues = { focused: key };
    setValues(newValues);
  }, []);
  return { handleChange, values };
};

export default useTabs;
