import createAction from 'actions/createAction';
import podService from 'services/podService';
import trackingService from 'services/trackingService';

import { dateToIso, setTimeToDate, timeToString } from 'utils/dateHelpers';
import { multipleFormData } from 'utils/filesHelpers';
import { retryCall } from 'utils/helpers';
import parseError from 'utils/parseError';
import { ERROR_MESSAGE, BACKEND_RETRIES } from 'constants/constants';
import { store } from '../index';

export const actionTypes = {
  CREATE: 'CREATE_POD',
  CREATE_SUCCESS: 'CREATE_POD_SUCCESS',
  CREATE_REQUEST: 'CREATE_POD_REQUEST',
  CREATE_ERROR: 'CREATE_POD_ERROR',
  CREATE_POD_RESET: 'CREATE_POD_RESET',
  UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
  GET_NEW_POD_INFO: 'GET_NEW_POD_INFO',
  GET_NEW_POD_INFO_REQUEST: 'GET_NEW_POD_INFO_REQUEST',
  GET_NEW_POD_INFO_SUCCESS: 'GET_NEW_POD_INFO_SUCCESS',
  GET_NEW_POD_INFO_ERROR: 'GET_NEW_POD_INFO_ERROR',
  CANCEL_POD: 'CANCEL_POD',
  CANCEL_POD_REQUEST: 'CANCEL_POD_REQUEST',
  CANCEL_POD_SUCCESS: 'CANCEL_POD_SUCCESS',
  CANCEL_POD_ERROR: 'CANCEL_POD_ERROR',
  CANCEL_POD_RESET: 'CANCEL_POD_RESET',
  DOWNLOAD_POD: 'DOWNLOAD_POD',
  DOWNLOAD_POD_REQUEST: 'DOWNLOAD_POD_REQUEST',
  DOWNLOAD_POD_SUCCESS: 'DOWNLOAD_POD_SUCCESS',
  GET_EXTRA_TIME: 'GET_EXTRA_TIME',
  GET_EXTRA_TIME_REQUEST: 'GET_EXTRA_TIME_REQUEST',
  GET_EXTRA_TIME_SUCCESS: 'GET_EXTRA_TIME_SUCCESS',
  GET_EXTRA_TIME_ERROR: 'GET_EXTRA_TIME_ERROR',
  GET_EXTRA_TIME_RESET: 'GET_EXTRA_TIME_RESET',
  GET_VIEWING_SUGGESTIONS: 'GET_VIEWING_SUGGESTIONS',
  GET_VIEWING_SUGGESTIONS_REQUEST: 'GET_VIEWING_SUGGESTIONS_REQUEST',
  GET_VIEWING_SUGGESTIONS_SUCCESS: 'GET_VIEWING_SUGGESTIONS_SUCCESS',
  GET_VIEWING_SUGGESTIONS_ERROR: 'GET_VIEWING_SUGGESTIONS_ERROR',
  CHECK_PROMO_CODE: 'CHECK_PROMO_CODE',
  CHECK_PROMO_CODE_REQUEST: 'CHECK_PROMO_CODE_REQUEST',
  CHECK_PROMO_CODE_SUCCESS: 'CHECK_PROMO_CODE_SUCCESS',
  CHECK_PROMO_CODE_ERROR: 'CHECK_PROMO_CODE_ERROR',
  CREATE_BUNDLE_PAYMENT: 'CREATE_BUNDLE_PAYMENT',
  CREATE_BUNDLE_PAYMENT_REQUEST: 'CREATE_BUNDLE_PAYMENT_REQUEST',
  CREATE_BUNDLE_PAYMENT_SUCCESS: 'CREATE_BUNDLE_PAYMENT_SUCCESS',
  CREATE_BUNDLE_PAYMENT_ERROR: 'CREATE_BUNDLE_PAYMENT_ERROR',
  CREATE_BUNDLE_PAYMENT_RESET: 'CREATE_BUNDLE_PAYMENT_RESET',
  GET_MY_PODS_REQUEST: 'GET_MY_PODS_REQUEST',
  GET_MY_PODS_SUCCESS: 'GET_MY_PODS_SUCCESS',
  GET_MY_PODS_ERROR: 'GET_MY_PODS_ERROR'
};

const createSuccess = createAction(actionTypes.CREATE_SUCCESS);
const createRequest = createAction(actionTypes.CREATE_REQUEST);
const createError = createAction(actionTypes.CREATE_ERROR);
export const createPodStatusReset = createAction(actionTypes.CREATE_POD_RESET);

const uploadProgress = createAction(actionTypes.UPLOAD_PROGRESS);

const getNewPodInfoRequest = createAction(actionTypes.GET_NEW_POD_INFO_REQUEST);
const getNewPodInfoSuccess = createAction(actionTypes.GET_NEW_POD_INFO_SUCCESS);
const getNewPodInfoError = createAction(actionTypes.GET_NEW_POD_INFO_ERROR);

const cancelPodRequest = createAction(actionTypes.CANCEL_POD_REQUEST);
const cancelPodSuccess = createAction(actionTypes.CANCEL_POD_SUCCESS);
const cancelPodError = createAction(actionTypes.CANCEL_POD_ERROR);
export const cancelPodStatusReset = createAction(actionTypes.CANCEL_POD_RESET);

const getExtraTimeRequest = createAction(actionTypes.GET_EXTRA_TIME_REQUEST);
const getExtraTimeSuccess = createAction(actionTypes.GET_EXTRA_TIME_SUCCESS);
const getExtraTimeError = createAction(actionTypes.GET_EXTRA_TIME_ERROR);
export const getExtraTimeStatusReset = createAction(actionTypes.GET_EXTRA_TIME_RESET);

const getViewingSuggestionsRequest = createAction(actionTypes.GET_VIEWING_SUGGESTIONS_REQUEST);
const getViewingSuggestionsSuccess = createAction(actionTypes.GET_VIEWING_SUGGESTIONS_SUCCESS);
const getViewingSuggestionsError = createAction(actionTypes.GET_VIEWING_SUGGESTIONS_ERROR);

const checkPromoCodeRequest = createAction(actionTypes.CHECK_PROMO_CODE_REQUEST);
const checkPromoCodeSuccess = createAction(actionTypes.CHECK_PROMO_CODE_SUCCESS);
const checkPromoCodeError = createAction(actionTypes.CHECK_PROMO_CODE_ERROR);

const createBundlePaymentRequest = createAction(actionTypes.CREATE_BUNDLE_PAYMENT_REQUEST);
const createBundlePaymentSuccess = createAction(actionTypes.CREATE_BUNDLE_PAYMENT_SUCCESS);
const createBundlePaymentError = createAction(actionTypes.CREATE_BUNDLE_PAYMENT_ERROR);
export const createBundlePaymentReset = createAction(actionTypes.CREATE_BUNDLE_PAYMENT_RESET);

const getMyPodsRequest = createAction(actionTypes.GET_MY_PODS_REQUEST);
const getMyPodsSuccess = createAction(actionTypes.GET_MY_PODS_SUCCESS);
const getMyPodsError = createAction(actionTypes.GET_MY_PODS_ERROR);

export const createBundlePayment = payment => async dispatch => {
  const token = payment.totalPrice > 0 ? (await payment.stripe.createToken()).token.id : null;
  const procPayment = {
    token,
    pricing_id: payment.pricingId,
    promo_code: payment.totalPrice > 0 ? payment.promoCode : null,
    email: payment.email
  };

  dispatch(createBundlePaymentRequest());
  try {
    const { data } = await podService.bundlePayment(procPayment);
    dispatch(createBundlePaymentSuccess({ data }));
    localStorage.setItem('pod_id', data.id);
    localStorage.setItem('register_token', data.registerToken);
  } catch (resp) {
    dispatch(createBundlePaymentError(parseError(resp.data)));
    throw resp;
  }
};

export const createPod = ({
  description,
  coverImage,
  recipients,
  title,
  date,
  time: { hour, minutes, period, timezone },
  size,
  totalPrice,
  stripe,
  files: { uploadedFiles },
  actualPodId
}) => async dispatch => {
  try {
    dispatch(createRequest());
    const timePeriod = timeToString(hour.value, minutes.value, period.value);
    const procDate = setTimeToDate(date, timePeriod);
    const openingDate = dateToIso(procDate, timezone.value);

    const {
      data: { amount }
    } = await podService.checkPodAmount({ openingDate, size, actualPodId });

    if (amount.toFixed(2) != Math.floor(totalPrice * 100) / 100) throw new Error(ERROR_MESSAGE);

    const token = amount > 0 ? (await stripe.createToken()).token.id : null;
    const recipientsAttributes = recipients.map(({ email: { value, isNew } }) => ({
      email: isNew ? value : value.email
    }));

    const procPod = {
      description,
      coverImage,
      title,
      openingDate,
      recipientsAttributes,
      size,
      token,
      actual_pod_id: actualPodId
    };
    const {
      data: {
        pod: { id }
      }
    } = await podService.create({ pod: procPod });

    localStorage.removeItem('pod_id');

    if (uploadedFiles) {
      const formData = multipleFormData(uploadedFiles);
      await retryCall(
        () => podService.sendFiles(id, formData, dispatch, uploadProgress),
        BACKEND_RETRIES
      );
    }

    const { email, transactionId } = store.getState().session.user;

    await trackingService.podPurchaseCompleted({
      transactionId,
      amount,
      costOfGoods: 0,
      podId: id,
      email
    });

    dispatch(createSuccess());
  } catch ({ data }) {
    dispatch(createError(parseError(data)));
  }
};

export const getPodPricing = () => async dispatch => {
  dispatch(getNewPodInfoRequest());
  try {
    const { data } = await podService.getPodPricing();
    dispatch(getNewPodInfoSuccess({ data }));
  } catch ({ data }) {
    dispatch(getNewPodInfoError(parseError(data)));
  }
};

export const cancelPod = ({ id }) => async dispatch => {
  dispatch(cancelPodRequest());
  try {
    const { data } = await podService.cancelPod(id);
    dispatch(cancelPodSuccess({ data }));
  } catch ({ data }) {
    dispatch(cancelPodError(parseError(data)));
  }
};

export const getExtraTime = ({ id, months, totalPrice, stripe }) => async dispatch => {
  try {
    dispatch(getExtraTimeRequest());
    const {
      data: { amount }
    } = await podService.checkExtraTimeAmount({ id, months });

    if (amount != totalPrice) throw new Error(ERROR_MESSAGE);

    const token = amount > 0 ? (await stripe.createToken()).token.id : null;

    const {
      data: { expirationDate }
    } = await podService.setExtraTime({ id, months, token });

    dispatch(getExtraTimeSuccess({ extraMonths: months, expirationDate }));
  } catch ({ data }) {
    dispatch(getExtraTimeError(parseError(data)));
  }
};

export const getFormatViewingSuggestions = () => async dispatch => {
  dispatch(getViewingSuggestionsRequest());
  try {
    const {
      data: { suggestions }
    } = await podService.getFormatViewingSuggestions();
    dispatch(getViewingSuggestionsSuccess({ suggestions }));
  } catch ({ data }) {
    dispatch(getViewingSuggestionsError());
  }
};

export const checkPromoCode = code => async dispatch => {
  dispatch(checkPromoCodeRequest());
  try {
    const { data } = await podService.checkPromoCode(code);
    dispatch(checkPromoCodeSuccess({ data }));
  } catch ({ data }) {
    dispatch(checkPromoCodeError(parseError(data)));
  }
};

export const getMyPods = () => async dispatch => {
  dispatch(getMyPodsRequest());
  try {
    const { data } = await podService.getMyPods();
    dispatch(getMyPodsSuccess({ data }));
  } catch ({ data }) {
    dispatch(getMyPodsError(parseError(data)));
  }
};
