import React, { memo } from 'react';
import { func, string, object } from 'prop-types';
import { isArray } from 'lodash';

import cn from 'classnames';

import Edit from 'components/common/Icons/Edit';
import { formatDataToString } from 'utils/helpers';

const ReviewStep = ({ title, values, onEdit }) => {
  const keys = Object.keys(values);
  return (
    <div className="mb-9">
      <div className={cn('col-12 px-0 pb-2 mb-4 d-flex justify-content-between border-bottom')}>
        <span className="text-weight-bold">{title}</span>
        <Edit className="clickable" onClick={onEdit} />
      </div>
      <div className="col-12 px-0">
        {keys.map(key => {
          const { value, price, multiplicate } = values[key];
          const valueArr = isArray(value) ? value : [value];

          return (
            <div key={key} className="d-flex justify-content-between">
              <div className="d-flex flex-d-column min-width-0">
                {valueArr.map((singleValue, index) => (
                  <div className="min-width-0" key={index}>
                    <span className="px-0 overflow-wrap">
                      {formatDataToString(singleValue)}
                      {index !== valueArr.length - 1 && ', '}
                    </span>
                  </div>
                ))}
              </div>
              {(price || multiplicate) && (
                <span className="px-0 text-right">{`${price ? `$${price}` : ''} ${
                  multiplicate ? `x${multiplicate}` : ''
                }`}</span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ReviewStep.propTypes = {
  title: string,
  onEdit: func,
  values: object
};

ReviewStep.defaultProps = {
  title: '',
  onEdit: () => {},
  values: {}
};

export default memo(ReviewStep);
