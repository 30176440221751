import React, { memo } from 'react';
import cn from 'classnames';

import Plus from 'components/common/Icons/PlusCircle';
import { func, string, bool } from 'prop-types';
import styles from './AddRecipientButton.scss';

const AddRecipientButton = ({ className, disabled, onClick }) => (
  <div className={cn(className, { 'opacity-muted': disabled })}>
    <button
      className={cn(styles.button, 'col-md-8 col-lg-9 row', { clickable: !disabled })}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      <div className={cn(styles.icon, 'p-0')}>
        <Plus />
      </div>
      <span className="ml-2 my-auto">Add Other Recipient</span>
    </button>
  </div>
);

AddRecipientButton.propTypes = {
  onClick: func.isRequired,
  disabled: bool,
  className: string
};

AddRecipientButton.defaultProps = {
  className: ''
};

export default memo(AddRecipientButton);
