import React, { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import { useInputValue, useDispatch, useValidation, useStatus } from 'hooks';
import { LOADING, ERROR, SUCCESS } from 'constants/status';
import {
  requestPasswordChange,
  requestPasswordChangeReset,
  actionTypes
} from 'actions/userActions';

import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import { Inputs } from 'components/common/Input';
import validation from 'validations/requestPasswordChangeValidations';
import { messages } from './constants';

const ForgotPasswordForm = ({ onCancel, className }) => {
  const changePasswordRequest = useDispatch(requestPasswordChange);
  const requestReset = useDispatch(requestPasswordChangeReset);
  const { status, error } = useStatus(actionTypes.REQUEST_PASSWORD_CHANGE);
  const [value, onChange] = useInputValue();
  const [errors, setErrors] = useState();

  const validator = useValidation(validation);
  const validate = useCallback(() => {
    const errors = validator({ email: value });
    setErrors(errors);
    return errors;
  }, [validator, value]);

  const handleSubmit = useCallback(() => {
    isEmpty(validate()) && changePasswordRequest(value);
  }, [changePasswordRequest, validate, value]);

  const meta = useMemo(() => ({ error: errors?.email, touched: !!value }), [errors, value]);

  useEffect(() => {
    status === ERROR && alert(error);
  }, [error, status]);

  useEffect(() => requestReset, [requestReset]);

  const sent = status === SUCCESS;

  return (
    <div className={cn(className, 'py-12 py-md-9')}>
      <p className="text-h5 mb-3 text-weight-normal">
        {sent ? messages.sentTitle : messages.title}
      </p>
      <p className="text-small mb-8">{sent ? messages.sentSubtitle(value) : messages.subtitle}</p>
      {sent && (
        <div className="mt-9">
          <u className="clickable text-blue canceButton" onClick={onCancel}>
            {messages.back}
          </u>
        </div>
      )}
      {!sent && (
        <>
          <Inputs
            onBlur={validate}
            className="mt-1"
            meta={meta}
            input={{ value, onChange }}
            label={messages.email}
          />
          <div className="row justify-content-center mt-9">
            <Button
              className="py-3 col-9"
              onClick={handleSubmit}
              disabled={!value || status === LOADING}
            >
              {status === LOADING ? (
                <Loader className="mt-n3 mb-5" />
              ) : (
                <span className="text-h5 text-inverted">{messages.button}</span>
              )}
            </Button>
          </div>
          <div className="mt-9 row justify-content-center">
            <u className="clickable canceButton" onClick={onCancel}>
              {messages.cancel}
            </u>
          </div>
        </>
      )}
    </div>
  );
};

ForgotPasswordForm.propTypes = {
  onCancel: func.isRequired,
  className: string
};

ForgotPasswordForm.defaultProps = {
  className: ''
};

export default memo(ForgotPasswordForm);
