import React, { useState } from 'react';
import { array, node } from 'prop-types';
import cn from 'classnames';

import ImageIcon from 'components/common/Icons/ImageFile';
import VideoIcon from 'components/common/Icons/VideoFile';
import AudioIcon from 'components/common/Icons/AudioFile';
import TextIcon from 'components/common/Icons/TextFile';
import DefaultIcon from 'components/common/Icons/DefaultFile';
import PodCarousel from 'components/common/PodCarousel';

import { POD_FILES_TYPES } from 'constants/constants';
import { mimeTypeToType, getMinimumSizeMB } from 'utils/filesHelpers';
import { customBackground } from 'utils/helpers';

import styles from './PodFiles.scss';

const defaultIcon = mimeType => {
  const type = mimeTypeToType(mimeType);
  switch (type && type[0]) {
    case POD_FILES_TYPES.IMAGE:
      return <ImageIcon className="m-auto" width={40} height={40} />;
    case POD_FILES_TYPES.VIDEO:
      return <VideoIcon className="m-auto" width={40} height={40} />;
    case POD_FILES_TYPES.AUDIO:
      return <AudioIcon className="m-auto" width={40} height={40} />;
    case POD_FILES_TYPES.TEXT:
    case POD_FILES_TYPES.APPLICATION:
      return <TextIcon className="m-auto" width={40} height={40} />;
    default:
      return <DefaultIcon className="m-auto" width={40} height={40} />;
  }
};

const PodFiles = ({ files, backIcon }) => {
  const [selectedFile, toggleCarousel] = useState();
  const handleCloseCarousel = () => toggleCarousel(null);

  return (
    <>
      <div className={cn('col-12 d-flex flex-column', styles.container)}>
        <div className="d-flex flex-column h-100">
          {backIcon}
          <div className="d-flex flex-column">
            <p className="m-auto text-gray text-weight-semibold pb-2">POD CONTENT</p>
            <div className={cn('w-100 container row pt-4 m-auto', styles.scroll)}>
              {files.map(({ name, type, sizeInMegabytes, thumbnail }, index) => {
                const handleSelect = () => toggleCarousel({ index });
                return (
                  <div
                    key={index}
                    className="col-12 col-sm-6 col-md-4 col-lg-3 mb-6 clickable"
                    onClick={handleSelect}
                  >
                    <div className={cn(styles.wrapper, 'rounded-5')}>
                      <div
                        style={customBackground(thumbnail, null, 'center', 'cover')}
                        className={cn(styles.thumbnail, {
                          'd-flex': !thumbnail,
                          [styles.icon]: !thumbnail
                        })}
                      >
                        {!thumbnail && defaultIcon(type)}
                      </div>
                      <div
                        className={cn('py-2 d-flex justify-content-flex-start nowrap', styles.name)}
                      >
                        <span className="pl-3 text-small text-ellipsis">{name}</span>
                        <span className="text-gray text-small pl-2 mr-2">{`∙ ${getMinimumSizeMB(
                          sizeInMegabytes
                        )} MB`}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {!!selectedFile && (
        <PodCarousel files={files} onClose={handleCloseCarousel} initial={selectedFile.index} />
      )}
    </>
  );
};
PodFiles.propTypes = {
  files: array.isRequired,
  backIcon: node
};

export default PodFiles;
