import createAction from 'actions/createAction';
import podService from 'services/podService';

export const actionTypes = {
  GET_INCOMING_PODS: 'GET_INCOMING_PODS',
  GET_INCOMING_PODS_REQUEST: 'GET_INCOMING_PODS_REQUEST',
  GET_INCOMING_PODS_SUCCESS: 'GET_INCOMING_PODS_SUCCESS',
  GET_INCOMING_PODS_ERROR: 'GET_INCOMING_PODS_ERROR',
  REJECT_POD: 'REJECT_POD',
  REJECT_POD_REQUEST: 'REJECT_POD_REQUEST',
  REJECT_POD_SUCCESS: 'REJECT_POD_SUCCESS',
  REJECT_POD_ERROR: 'REJECT_POD_ERROR',
  REJECT_POD_RESET_STATUS: 'REJECT_POD_RESET_STATUS',
  ACCEPT_POD: 'ACCEPT_POD',
  ACCEPT_POD_REQUEST: 'ACCEPT_POD_REQUEST',
  ACCEPT_POD_SUCCESS: 'ACCEPT_POD_SUCCESS',
  ACCEPT_POD_ERROR: 'ACCEPT_POD_ERROR',
  ACCEPT_POD_RESET_STATUS: 'ACCEPT_POD_RESET_STATUS',
  GET_INCOMING_POD: 'GET_INCOMING_POD',
  GET_INCOMING_POD_REQUEST: 'GET_INCOMING_POD_REQUEST',
  GET_INCOMING_POD_SUCCESS: 'GET_INCOMING_POD_SUCCESS',
  GET_INCOMING_POD_ERROR: 'GET_INCOMING_POD_ERROR'
};

export const getIncomingPodsRequest = createAction(actionTypes.GET_INCOMING_PODS_REQUEST);
export const getIncomingPodsSuccess = createAction(actionTypes.GET_INCOMING_PODS_SUCCESS);
export const getIncomingPodsError = createAction(actionTypes.GET_INCOMING_PODS_ERROR);

export const getIncomingPodRequest = createAction(actionTypes.GET_INCOMING_POD_REQUEST);
export const getIncomingPodSuccess = createAction(actionTypes.GET_INCOMING_POD_SUCCESS);
export const getIncomingPodError = createAction(actionTypes.GET_INCOMING_POD_ERROR);

export const acceptPodRequest = createAction(actionTypes.ACCEPT_POD_REQUEST);
export const acceptPodSuccess = createAction(actionTypes.ACCEPT_POD_SUCCESS);
export const acceptPodError = createAction(actionTypes.ACCEPT_POD_ERROR);
export const acceptPodClearStatus = createAction(actionTypes.ACCEPT_POD_RESET_STATUS);

export const rejectPodRequest = createAction(actionTypes.REJECT_POD_REQUEST);
export const rejectPodSuccess = createAction(actionTypes.REJECT_POD_SUCCESS);
export const rejectPodError = createAction(actionTypes.REJECT_POD_ERROR);
export const rejectPodClearStatus = createAction(actionTypes.REJECT_POD_RESET_STATUS);

export const getIncomingPods = () => async dispatch => {
  dispatch(getIncomingPodsRequest());
  try {
    const { data } = await podService.getIncomingPods();
    dispatch(getIncomingPodsSuccess({ data }));
  } catch ({ data: { error } }) {
    dispatch(getIncomingPodsError(error));
  }
};

export const getIncomingPod = id => async dispatch => {
  dispatch(getIncomingPodRequest());
  try {
    const { data } = await podService.getIncomingPod(id);
    dispatch(getIncomingPodSuccess({ data }));
  } catch ({ data: { error } }) {
    dispatch(getIncomingPodError(error));
  }
};

// useDispatchOnMount doesn't support multiple params.
// Using objects as params causes infinite comp. load. Used concat string id and token
export const getGuestIncomingPod = idAndTokenStr => async dispatch => {
  const [id, token] = idAndTokenStr.split('-');
  dispatch(getIncomingPodRequest());
  try {
    const { data } = await podService.getGuestIncomingPod(id, token);
    dispatch(getIncomingPodSuccess({ data }));
  } catch ({ data: { error } }) {
    dispatch(getIncomingPodError(error));
  }
};

export const acceptPod = id => async dispatch => {
  dispatch(acceptPodRequest());
  try {
    await podService.acceptPod(id);
    dispatch(acceptPodSuccess());
    dispatch(getIncomingPods());
  } catch ({ data: { error } }) {
    dispatch(acceptPodError(error));
  }
};

export const acceptGuestPod = (id, accessToken) => async dispatch => {
  dispatch(acceptPodRequest());
  try {
    await podService.acceptGuestPod(id, accessToken);
    dispatch(acceptPodSuccess());
    dispatch(getGuestIncomingPod(`${id}-${accessToken}`));
  } catch ({ data: { error } }) {
    dispatch(acceptPodError(error));
  }
};

export const rejectPod = id => async dispatch => {
  dispatch(rejectPodRequest());
  try {
    await podService.rejectPod(id);
    dispatch(rejectPodSuccess());
    dispatch(getIncomingPods());
  } catch ({ data: { error } }) {
    dispatch(rejectPodError(error));
  }
};

export const rejectGuestPod = (id, accessToken) => async dispatch => {
  dispatch(rejectPodRequest());
  try {
    await podService.rejectGuestPod(id, accessToken);
    dispatch(rejectPodSuccess());
    dispatch(getGuestIncomingPod(`${id}-${accessToken}`));
  } catch ({ data: { error } }) {
    dispatch(rejectPodError(error));
  }
};
