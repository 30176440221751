import React, { memo } from 'react';
import { func, string, bool } from 'prop-types';
import cn from 'classnames';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import {
  useForm,
  useStatus,
  useTextInputProps,
  useValidation,
  useCheckInputProps,
  useBreakpoint,
  useDispatch
} from 'hooks';
import { actionTypes, fbLogin } from 'actions/userActions';

import pdfTerms from 'assets/pdfs/terms-of-use.pdf';
import pdfPrivacy from 'assets/pdfs/privacy-policy.pdf';

import { Inputs, CheckInput } from 'components/common/Input';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import { LOADING } from 'constants/status';
import { SIGNUP_FIELDS } from 'constants/fields';
import signUpValidations from 'validations/signUpValidations';
import { useLocation } from 'react-router-dom';
import { sm } from 'styles/_constants.scss';
import googleImage from '../../../assets/images/google.png';
import facebookImage from '../../../assets/images/facebook.png';
import { messages } from './constants';
import styles from './SignUpForm.scss';

export const SignUpForm = ({ onSubmit, className, blackAccents }) => {
  const { status } = useStatus(actionTypes.SIGN_UP);

  const validator = useValidation(signUpValidations);
  const { values, errors, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true
    },
    [onSubmit]
  );
  const inputTextProps = useTextInputProps(handleValueChange, handleBlur, values);
  const inputCheckProps = useCheckInputProps(handleValueChange, handleBlur, values);

  const isLoading = status === LOADING;

  const isSm = useBreakpoint(sm);
  const fontSize = isSm ? 'text-small' : 'text-x-small';
  const { search } = useLocation();

  let refCode = '';
  if (search.includes('code')) {
    document.cookie = '';
    const params = new URLSearchParams(search);
    refCode = params.get('code');
  }

  // facebook and google login response

  const fbl = useDispatch(fbLogin, {});
  const query = new URLSearchParams(window.location.search);
  const data = query != undefined ? query.get('code') : '';
  function responseFacebook(response) {
    if (response.email) {
      let formData = {};
      formData = {
        email: response.email,
        referral: data,
        name: response.name
      };
      fbl(formData);
    } else {
    }
  }

  function responseGoogle(response) {
    let formData = {};
    if (response.profileObj.email) {
      formData = {
        email: response.profileObj.email,
        referral: data,
        name: response.profileObj.name
      };
      fbl(formData);
    } else {
      // error = ""
    }
  }

  const triggerfb = () => {
    document.getElementsByClassName('fb-login')[0].childNodes[0].childNodes[1].click();
  };
  const triggergoogle = () => {
    document.getElementsByClassName('google-login')[0].childNodes[0].click();
  };

  return (
    <form
      onSubmit={handleSubmit}
      autocomplete="off"
      className={cn('d-flex flex-column flex-grow-1', className)}
    >
      <div>
        <Inputs
          label={messages[SIGNUP_FIELDS.name]}
          type="text"
          placeholder="Enter your name"
          customError={errors[SIGNUP_FIELDS.name]}
          className="mb-4"
          input={{ ...inputTextProps(SIGNUP_FIELDS.name) }}
        />
      </div>
      <div>
        <Inputs
          label={messages[SIGNUP_FIELDS.telephone]}
          type="text"
          inputMode="numeric"
          placeholder="Your phone number"
          customError={errors[SIGNUP_FIELDS.telephone]}
          className="mb-4"
          input={{ ...inputTextProps(SIGNUP_FIELDS.telephone) }}
        />
      </div>
      <div>
        <Inputs
          label={messages[SIGNUP_FIELDS.password]}
          type="password"
          placeholder="Minimum 8 characters long"
          customError={errors[SIGNUP_FIELDS.password]}
          className="mb-4"
          input={{ ...inputTextProps(SIGNUP_FIELDS.password) }}
        />
      </div>
      <div>
        <Inputs
          label={messages[SIGNUP_FIELDS.passwordConfirmation]}
          type="password"
          placeholder="Confirm your password"
          customError={errors[SIGNUP_FIELDS.passwordConfirmation]}
          className="mb-4"
          input={{ ...inputTextProps(SIGNUP_FIELDS.passwordConfirmation) }}
        />
      </div>
      <div>
        <label className="text-x-small text-weight-semibold d-block mb-2">
          {messages[SIGNUP_FIELDS.referral]}
        </label>
        <div>
          <input
            label={messages[SIGNUP_FIELDS.referral]}
            type="text"
            readOnly={refCode !== ''}
            placeholder="Referral Code"
            className="mb-4 px-3"
            id="referral"
            value={refCode !== '' ? refCode : null}
          />
        </div>
      </div>
      <div className="d-flex align-items-center mb-4">
        <CheckInput
          type="checkbox"
          customError={errors[SIGNUP_FIELDS.terms]}
          input={{ ...inputCheckProps(SIGNUP_FIELDS.terms) }}
          className="d-flex flex-column"
        >
          <span className={cn('text-gray text-break terms_condition', fontSize)}>
            {messages.termsMessage}
            <a href={pdfTerms} className={cn('text-gray text-underline mr-1', fontSize)}>
              {messages.termsLink}
            </a>
            and
            <a href={pdfPrivacy} className={cn('text-gray text-underline ml-1', fontSize)}>
              {messages.privacyLink}
            </a>
          </span>
        </CheckInput>
      </div>
      {/* TODO: manage gral form error */}
      {/* <div className={styles.errorWrapper}>
        <label className={styles.error}>{error}</label>
      </div> */}

      <div className="mt-auto">
        <Button
          type="submit"
          styleType={blackAccents ? 'black' : 'primary'}
          className={cn('w-100', styles.button)}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <span className="text-inverted text-h5">{messages.submit}</span>
          )}
        </Button>
      </div>
      <div class="social-container">
        <a onClick={triggergoogle}>
          <img alt="Google Login" id="cwg" src={googleImage} width="50%"></img>
        </a>
        <a onClick={triggerfb}>
          <img alt="Facebook Login" id="cwf" src={facebookImage} width="50%"></img>
        </a>

        <div style={{ display: 'none' }} class="fb-login">
          <FacebookLogin
            appId="845717646203155"
            autoLoad={false}
            fields="name,email,picture"
            cssClass="my-facebook-button-class"
            icon="fa-facebook"
            textButton="Continue with Facebook"
            callback={responseFacebook}
            state={1}
            isMobile={false}
          />
        </div>
        <div style={{ display: 'none' }} class="google-login">
          <GoogleLogin
            clientId="236262822872-21cl8936en7rh7le5bek6tm8oqk6f7ra.apps.googleusercontent.com"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy="single_host_origin"
            buttonText="Continue with Google"
            className="google-button"
          />
        </div>
      </div>
    </form>
  );
};

SignUpForm.propTypes = {
  onSubmit: func.isRequired,
  className: string,
  blackAccents: bool
};

SignUpForm.defaultProps = {
  className: '',
  blackAccents: false
};

export default memo(SignUpForm);
