import React from 'react';
import { createPortal } from 'react-dom';

import { darkTransparentBlue90 } from 'styles/_constants.scss';

const Modal = ({ children }) => {
  const modalRoot = document.getElementById('app');

  return modalRoot
    ? createPortal(
        <div
          className="position-fixed absolute-fill"
          style={{ zIndex: 1, backgroundColor: darkTransparentBlue90 }}
        >
          {children}
        </div>,
        modalRoot
      )
    : null;
};

export default Modal;
