import React from 'react';
import cn from 'classnames';
import { string, object, bool } from 'prop-types';

import { customBackground } from 'utils/helpers';

const ImagePreview = ({ file: { playUrl }, className, isDesktop }) => (
  <div
    style={{
      ...customBackground(playUrl, null, 'center', 'contain'),
      maxHeight: !isDesktop && '48.5rem'
    }}
    className={cn('h-100 w-100', className)}
  />
);

ImagePreview.propTypes = {
  className: string,
  file: object.isRequired,
  isDesktop: bool
};

ImagePreview.defaultProps = {
  className: '',
  isDesktop: true
};

export default ImagePreview;
