import React, { memo, useCallback } from 'react';
import { number, string } from 'prop-types';

import { removeContact, removeContactStatusReset, actionTypes } from 'actions/contactsActions';
import { useDispatch, useStatus } from 'hooks';

import { ERROR } from 'constants/status';

import Avatar from 'components/user/Avatar';
import { messages } from './constants';
import RemoveButton from './RemoveButton';

const Contact = ({ profileImage, name, email, id }) => {
  const removeContactRequest = useDispatch(removeContact);
  const resetRemoveContactStatus = useDispatch(removeContactStatusReset);
  const { status, error } = useStatus(actionTypes.REMOVE_CONTACT);

  const handleRemoveContact = useCallback(
    () => window.confirm(messages.removeContact) && removeContactRequest({ id }),
    [id, removeContactRequest]
  );

  if (status === ERROR) {
    window.alert(error);
    resetRemoveContactStatus();
  }

  return (
    <div className="d-flex pt-4 pb-3 pt-md-5 pb-md-4 align-items-center border-bottom w-100">
      <Avatar small image={profileImage} className="flex-shrink-0 align-items-center" />
      <div className="ml-2 ml-md-6 flex-shrink-1 flex-basis-0 flex-grow-1 min-width-0">
        <p className="text-ellipsis">{name}</p>
        <p className="text-small text-gray text-ellipsis w-100">{email}</p>
      </div>
      <RemoveButton className="ml-1 mr-md-4" onClick={handleRemoveContact} />
    </div>
  );
};

Contact.propTypes = {
  profileImage: string,
  name: string,
  email: string.isRequired,
  id: number.isRequired
};

export default memo(Contact);
