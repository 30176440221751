import { CREATEGIFT_FIELDS } from 'constants/fields';

export default {
  [CREATEGIFT_FIELDS.podCount]: {
    presence: true
  },
  [CREATEGIFT_FIELDS.senderName]: {
    presence: true
  },
  [CREATEGIFT_FIELDS.recipientName]: {
    presence: true
  },
  [CREATEGIFT_FIELDS.recipientEmail]: {
    email: true,
    presence: true
  }
};
