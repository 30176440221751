import React from 'react';
import cn from 'classnames';
import { ReactComponent as Gift } from 'assets/icons/gift.svg';
import styles from './FreePod.scss';
import ButtonBundlePayment from '../ButtonBundlePayment';

const FreePod = ({
  currentStep,
  disabled,
  pricingPlan,
  promoCode,
  selectedBundle,
  stripe,
  totalPrice
}) => (
  <>
    <div className={cn('d-flex py-md-12 mb-9 mb-md-0 flex-column mt-5 mt-md-0', styles.container)}>
      <div className="d-flex justify-content-center flex-md-grow-1">
        <Gift className="mt-4 mt-md-auto" />
      </div>
      <div className={cn('d-flex flex-column mb-6 mb-md-0', styles.textWrapper)}>
        <div className="d-flex flex-column mt-8 mx-3 mx-md-10">
          <span className="text-center text-h4 text-blue text-weight-medium mb-4">
            This pod is Free!
          </span>
          <span className="text-center">
            No need to enter payment method. You are ready to send the pod.
          </span>
        </div>
      </div>
    </div>

    {currentStep === 1 && pricingPlan === 'bundle' && (
      <div className="mt-8">
        <ButtonBundlePayment
          disabled={disabled}
          promoCode={promoCode}
          selectedBundle={selectedBundle}
          stripe={stripe}
          totalPrice={totalPrice}
        />
      </div>
    )}
  </>
);

export default FreePod;
