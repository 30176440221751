import React, { useCallback, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { string, object } from 'prop-types';
import cn from 'classnames';
import moment from 'moment';

import { cancelPod, cancelPodStatusReset, actionTypes } from 'actions/podActions';
import { useDispatch, useStatus } from 'hooks';

import Edit from 'components/common/Icons/Edit';
import PodCover from 'components/pods/PodCover';
import PodFilesList from 'components/common/PodFilesList';

import routesPaths from 'constants/routesPaths';
import { SUCCESS } from 'constants/status';

import { MOMENT_TIMEZONES } from 'constants/constants';
import Recipient from './Recipient';
import { messages } from './constants';
import styles from './OutgoingPod.scss';

const OutgoingPod = ({ pod, className }) => {
  const {
    canEdit,
    canCancel,
    coverImage,
    description,
    openingDate,
    recipients = [],
    id,
    files,
    title
  } = pod;
  const openAt = moment(openingDate);
  const cancelTimeLeft =
    !canEdit &&
    canCancel &&
    moment(moment(openingDate).subtract(1, 'minutes')).format('MM/DD/YYYY [at] h.mm a');
  const cancelable = moment(new Date()).isBefore(moment(openingDate).subtract(1, 'minutes'));
  const date = new Date(openingDate);

  const time = {
    hour: {
      value: moment(openingDate).format('hh'),
      label: moment(openingDate).format('hh')
    },
    minutes: {
      value: moment(openingDate).format('mm'),
      label: moment(openingDate).format('mm')
    },
    period: {
      value: moment(openingDate).format('a'),
      label: moment(openingDate)
        .format('a')
        .toUpperCase()
    },
    timezone: {
      value: MOMENT_TIMEZONES.EST,
      label: 'EST'
    }
  };

  const cancelPodRequest = useDispatch(cancelPod);
  const resetCancelPodStatus = useDispatch(cancelPodStatusReset);
  const { status } = useStatus(actionTypes.CANCEL_POD);
  const isSuccess = status === SUCCESS;

  const handleCancelClick = useCallback(() => {
    if (window.confirm(messages.cancelConfirmation)) cancelPodRequest({ id });
  }, [cancelPodRequest, id]);

  useEffect(() => resetCancelPodStatus, [resetCancelPodStatus]);

  if (isSuccess) {
    return <Redirect to={routesPaths.index} />;
  }

  return (
    <div className={cn('bg-white rounded-5 overflow-hidden', className)}>
      <PodCover pod={pod} />
      <div className="px-5 px-md-9 py-5">
        <div className="d-flex justify-content-between">
          <span className="text-gray mb-1">Message:</span>
          {canEdit && (
            <Link
              className="d-flex"
              to={{
                pathname: routesPaths.updatePod,
                state: { pod: { id, coverImage, title, description, date, time } }
              }}
            >
              <Edit />
              <u className="ml-0 ml-md-2 d-none d-md-block">Edit Pod Information</u>
            </Link>
          )}
        </div>
        <p className="mb-6">{description}</p>

        <p className="text-gray mb-1">Open on:</p>
        <p className="mb-6">{openAt.format('MM/DD/YYYY [at] h.mm a')} </p>

        <p className="text-gray mb-2">Pod sent to:</p>
        {recipients.map((recipient, index) => (
          <Recipient
            className="mb-3 d-block d-md-inline-block"
            last={index === recipients.length - 1}
            recipient={recipient}
            key={recipient.email}
          />
        ))}
      </div>
      {files && (
        <div className={styles.footer}>
          <div className="mx-1 mx-md-8 mt-7 mb-9 col col-md-6">
            <p className="text-gray pb-5">Content:</p>
            <PodFilesList list={files} />
          </div>
        </div>
      )}
      {canCancel && (
        <div className={cn('d-flex flex-column', styles.footer)}>
          {!canEdit && (
            <div className="d-flex m-auto pt-9">
              {cancelable && (
                <>
                  <p className="text-center text-gray">You can cancel the pod until</p>
                  <span className="text-center mx-1">{cancelTimeLeft}</span>
                </>
              )}
            </div>
          )}
          <a
            className={cn('mx-auto text-alert text-underline', {
              'mt-9 mb-9': canEdit,
              'mt-4 mb-9': !canEdit
            })}
            onClick={handleCancelClick}
          >
            Cancel Pod
          </a>
          {/*
          {canEdit && (
            <p className="m-auto col-12 col-sm-6 col-md-5 col-lg-4 text-center text-gray pt-4 pb-10 pb-sm-6">
              You’ll be able to cancel your pod up to a minute before it opens
            </p>
          )}
          */}
        </div>
      )}
    </div>
  );
};

OutgoingPod.propTypes = {
  className: string,
  pod: object.isRequired
};

OutgoingPod.defaultProps = {
  className: ''
};
export default OutgoingPod;
