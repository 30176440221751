import React from 'react';
import { number, string } from 'prop-types';

const CheckCircle = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.1668 26.7767V29.0001C53.1639 34.2114 51.4764 39.2822 48.3561 43.4562C45.2357 47.6301 40.8498 50.6836 35.8523 52.1612C30.8548 53.6388 25.5135 53.4613 20.6251 51.6553C15.7367 49.8493 11.5631 46.5115 8.72665 42.1397C5.89022 37.7678 4.54299 32.5962 4.88588 27.3962C5.22877 22.1961 7.24341 17.2462 10.6293 13.2847C14.0153 9.32314 18.5911 6.56225 23.6743 5.41377C28.7575 4.26528 34.0758 4.79073 38.836 6.91175"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.1667 9.66675L29 33.8576L21.75 26.6076"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CheckCircle.propTypes = {
  width: number,
  height: number,
  className: string
};

CheckCircle.defaultProps = {
  width: 58,
  height: 58,
  className: ''
};

export default CheckCircle;
