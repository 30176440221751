export const DEFAULT_CODE = 'auth';

const routes = {
  index: '/',
  home: '/home/:tab',
  createPod: '/create',
  createSuccess: '/create-success',
  createSuccessPayment: '/create-success-payment',
  signUp: `/${DEFAULT_CODE}/:tab?`,
  verifyEmail: '/verify',
  confirmEmail: '/confirm-email',
  referral: '/referral',
  updatePod: '/update-pod',
  updatePassword: '/update-password',
  outgoingPod: '/outgoing-pod',
  openPod: '/open-pod',
  incomingPod: '/incoming-pod/:podId/:token?',
  campaign: '/:code/:tab?',
  extraTimeSuccess: '/extra-time-success',
  formatViewingSuggestions: '/viewing-suggestions',
  privacy: '/privacy-policy'
};

export default routes;
