import React from 'react';
import { string, number } from 'prop-types';
import { gray } from 'styles/_constants.scss';

const MyPods = ({ color, height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 21 22"
  >
    <path
      d="M20.9531 7.25C20.9531 6.78125 20.8594 6.26562 20.625 5.84375L18.3281 1.53125C18.0938 0.921875 17.5312 0.5 16.875 0.5H4.07812C3.42188 0.5 2.85938 0.921875 2.625 1.53125L0.328125 5.84375C0.09375 6.3125 0 6.78125 0 7.29688C0 10.1094 0 20 0 20C0 20.8438 0.65625 21.5 1.5 21.5H19.5C20.2969 21.5 21 20.8438 21 20C21 20 20.9531 10.1094 20.9531 7.25ZM11.25 2H16.875L19.2656 6.5H11.25V2ZM4.07812 2H9.75V6.5H1.6875L4.07812 2ZM10.5 20H1.5C1.5 20 1.5 11.5156 1.5 8H19.5C19.5 11.5156 19.5 20 19.5 20H10.5ZM14.7188 10.1562C14.0625 9.59375 12.4688 8.79688 10.5 10.8125C8.48438 8.79688 6.89062 9.59375 6.23438 10.1562C4.96875 11.2344 4.875 13.1562 6.04688 14.3281L10.0312 18.3125C10.2656 18.5938 10.6875 18.5938 10.9688 18.3125L14.9062 14.3281C16.0781 13.1562 15.9844 11.2344 14.7188 10.1562ZM13.8281 13.25L10.5 16.6719L7.125 13.25C6.65625 12.7812 6.51562 11.8438 7.21875 11.2812C7.875 10.7656 8.67188 11.1406 9 11.4688L10.5 12.9688L11.9531 11.4688C12.2812 11.1406 13.125 10.7656 13.7344 11.2812C14.4375 11.8438 14.3438 12.7812 13.8281 13.25Z"
      fill={color}
    />
  </svg>
);

MyPods.propTypes = {
  color: string,
  width: number,
  height: number
};

MyPods.defaultProps = {
  color: gray,
  width: 24,
  height: 24
};

export default MyPods;
