import createReducer from 'reducers/createReducer';
import { actionTypes } from 'actions/contactsActions';
import { actionTypes as userActionTypes } from 'actions/userActions';

const initialState = {
  contactsList: []
};

const handleContactsSuccess = (state, { contacts }) => {
  state.contactsList = contacts;
};

const handleLogOut = () => ({ ...initialState });

export default createReducer(initialState, {
  [actionTypes.GET_CONTACTS_SUCCESS]: handleContactsSuccess,
  [userActionTypes.LOGOUT_REQUEST]: handleLogOut
});
