import React from 'react';
import { Link } from 'react-router-dom';
import { replaceLandingTab } from 'utils/tabHelpers';
import { LABELS } from 'pages/SignUpPage/tabs';

import Check from 'components/common/Icons/CheckCircle';

const PasswordChanged = () => (
  <div className="d-flex align-items-center justify-content-center flex-column">
    <Check className="mt-9" />
    <span className="text-center mt-9">Your SKYPOD password has been successfully reset</span>
    <Link to={replaceLandingTab(LABELS.login)} className="clickable mt-9 mb-12">
      <u className="text-blue">Login to your account</u>
    </Link>
  </div>
);

export default PasswordChanged;
