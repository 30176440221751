import React from 'react';
import { string, func } from 'prop-types';

const Edit = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    className={className}
    onClick={onClick}
  >
    <path
      stroke="#06223C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14.166 2.5A2.357 2.357 0 0117.5 5.833L6.25 17.083l-4.583 1.25 1.25-4.583L14.166 2.5z"
    />
  </svg>
);

Edit.propTypes = {
  className: string,
  onClick: func
};

Edit.defaultProps = {
  className: ''
};

export default Edit;
