import createAction from 'actions/createAction';
import userService from 'services/userService';
import parseError from 'utils/parseError';

export const actionTypes = {
  CONFIRM_SIGNUP: 'CONFIRM_SIGNUP',
  CONFIRM_SIGNUP_REQUEST: 'CONFIRM_SIGNUP_REQUEST',
  CONFIRM_SIGNUP_SUCCESS: 'CONFIRM_SIGNUP_SUCCESS',
  CONFIRM_SIGNUP_ERROR: 'CONFIRM_SIGNUP_ERROR',
  CONFIRM_SIGNUP_RESET_STATUS: 'CONFIRM_SIGNUP_RESET_STATUS',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_RESET_STATUS: 'LOGIN_RESET_STATUS',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
  SIGN_UP: 'SIGN_UP',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_ERROR: 'SIGN_UP_ERROR',
  SIGN_UP_RESET_STATUS: 'SIGN_UP_RESET_STATUS',
  UPDATE: 'UPDATE',
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_ERROR: 'UPDATE_ERROR',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_SESSION: 'UPDATE_SESSION',
  REQUEST_PASSWORD_CHANGE: 'REQUEST_PASSWORD_CHANGE',
  REQUEST_PASSWORD_CHANGE_REQUEST: 'REQUEST_PASSWORD_CHANGE_REQUEST',
  REQUEST_PASSWORD_CHANGE_ERROR: 'REQUEST_PASSWORD_CHANGE_ERROR',
  REQUEST_PASSWORD_CHANGE_SUCCESS: 'REQUEST_PASSWORD_CHANGE_SUCCESS',
  REQUEST_PASSWORD_CHANGE_RESET: 'REQUEST_PASSWORD_CHANGE_RESET',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_RESET: 'CHANGE_PASSWORD_RESET',
  GET_PROFILE: 'GET_PROFILE',
  GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
  GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_RESET: 'GET_PROFILE_RESET',
  USER_AUTO_LOGIN: 'USER_AUTO_LOGIN',
  USER_AUTO_LOGIN_REQUEST: 'USER_AUTO_LOGIN_REQUEST',
  USER_AUTO_LOGIN_ERROR: 'USER_AUTO_LOGIN_ERROR',
  USER_AUTO_LOGIN_SUCCESS: 'USER_AUTO_LOGIN_SUCCESS',
  USER_AUTO_LOGIN_RESET: 'USER_AUTO_LOGIN_RESET'
};

const confirmSuccess = createAction(actionTypes.CONFIRM_SIGNUP_SUCCESS);
const confirmRequest = createAction(actionTypes.CONFIRM_SIGNUP_REQUEST);
const confirmError = createAction(actionTypes.CONFIRM_SIGNUP_ERROR);
export const confirmClearStatus = createAction(actionTypes.CONFIRM_SIGNUP_RESET_STATUS);

export const loginSuccess = createAction(actionTypes.LOGIN_SUCCESS);
const loginRequest = createAction(actionTypes.LOGIN_REQUEST);
const loginError = createAction(actionTypes.LOGIN_ERROR);
export const loginClearStatus = createAction(actionTypes.LOGIN_RESET_STATUS);

const logoutSuccess = createAction(actionTypes.LOGOUT_SUCCESS);
const logoutRequest = createAction(actionTypes.LOGOUT_REQUEST);
const logoutError = createAction(actionTypes.LOGOUT_ERROR);

const signUpSuccess = createAction(actionTypes.SIGN_UP_SUCCESS);
const signUpRequest = createAction(actionTypes.SIGN_UP_REQUEST);
const signUpError = createAction(actionTypes.SIGN_UP_ERROR);
export const signUpClearStatus = createAction(actionTypes.SIGN_UP_RESET_STATUS);

const updateSuccess = createAction(actionTypes.UPDATE_SUCCESS);
const updateRequest = createAction(actionTypes.UPDATE_REQUEST);
const updateError = createAction(actionTypes.UPDATE_ERROR);

const requestPasswordChangeSuccess = createAction(actionTypes.REQUEST_PASSWORD_CHANGE_SUCCESS);
const requestPasswordChangeRequest = createAction(actionTypes.REQUEST_PASSWORD_CHANGE_REQUEST);
const requestPasswordChangeError = createAction(actionTypes.REQUEST_PASSWORD_CHANGE_ERROR);
export const requestPasswordChangeReset = createAction(actionTypes.REQUEST_PASSWORD_CHANGE_RESET);

const changePasswordSuccess = createAction(actionTypes.CHANGE_PASSWORD_SUCCESS);
const changePasswordRequest = createAction(actionTypes.CHANGE_PASSWORD_REQUEST);
const changePasswordError = createAction(actionTypes.CHANGE_PASSWORD_ERROR);
export const changePasswordReset = createAction(actionTypes.CHANGE_PASSWORD_RESET);

const getProfileSuccess = createAction(actionTypes.GET_PROFILE_SUCCESS);
const getProfileRequest = createAction(actionTypes.GET_PROFILE_REQUEST);
const getProfileError = createAction(actionTypes.GET_PROFILE_ERROR);
export const getProfileReset = createAction(actionTypes.GET_PROFILE_RESET);

const userAutoLoginSuccess = createAction(actionTypes.USER_AUTO_LOGIN_SUCCESS);
const userAutoLoginRequest = createAction(actionTypes.USER_AUTO_LOGIN_REQUEST);
const userAutoLoginError = createAction(actionTypes.USER_AUTO_LOGIN_ERROR);
export const userAutoLoginReset = createAction(actionTypes.USER_AUTO_LOGIN_RESET);

export const updateSession = createAction(actionTypes.UPDATE_SESSION);

export const update = user => async dispatch => {
  try {
    dispatch(updateRequest());
    const { data } = await userService.update(user);
    dispatch(updateSuccess({ user: data.user }));
  } catch ({ data }) {
    dispatch(updateError(parseError(data)));
  }
};

export const skypodSocialLogin = user => async dispatch => {
  try {
    dispatch(loginRequest());
    const userForLogin = JSON.parse(user).user;
    const dataForApi = { email: userForLogin.email, code: null, name: userForLogin.name };
    dataForApi.pod_id = localStorage.getItem('pod_id');
    dataForApi.register_token = localStorage.getItem('register_token');
    const { data } = await userService.fblogin(dataForApi);
    localStorage.removeItem('register_token');
    dispatch(loginSuccess({ user: data.user }));
  } catch ({ data }) {
    dispatch(loginError(parseError(data)));
  }
};

export const fbLogin = user => async dispatch => {
  try {
    dispatch(loginRequest());
    user.pod_id = localStorage.getItem('pod_id');
    user.register_token = localStorage.getItem('register_token');
    const { data } = await userService.fblogin(user);
    localStorage.removeItem('register_token');
    dispatch(loginSuccess({ user: data.user }));
  } catch ({ data }) {
    dispatch(loginError(parseError(data)));
  }
};

export const autoLogin = user => async dispatch => {
  try {
    dispatch(userAutoLoginRequest());
    const { data } = await userService.autoLogin(user);
    dispatch(userAutoLoginSuccess({ user: data.user }));
    window.location.href = '/';
  } catch ({ data }) {
    dispatch(userAutoLoginError(parseError(data)));
  }
};

export const login = user => async dispatch => {
  try {
    dispatch(loginRequest());
    const { data } = await userService.login(user);
    dispatch(loginSuccess({ user: data.user }));
  } catch ({ data: { error } }) {
    dispatch(loginError(error));
  }
};

export const logout = () => async dispatch => {
  try {
    dispatch(logoutRequest());
    userService.logout();
    dispatch(logoutSuccess());
  } catch ({ data: { error } }) {
    dispatch(logoutError(error));
  }
};

export const signUp = user => async dispatch => {
  try {
    dispatch(signUpRequest());
    user.referral = document.getElementById('referral').value;
    user.pod_id = localStorage.getItem('pod_id');
    user.register_token = localStorage.getItem('register_token');
    const {
      data: { user: userToSave }
    } = await userService.signUp(user);
    dispatch(loginSuccess({ user: userToSave }));
    dispatch(signUpSuccess({ user: userToSave }));
    localStorage.removeItem('register_token');
  } catch ({ data }) {
    dispatch(signUpError(parseError(data)));
  }
};

export const confirm = email => async dispatch => {
  try {
    dispatch(confirmRequest());
    await userService.confirm(email);
    dispatch(confirmSuccess({ email }));
  } catch ({ data: { error } }) {
    dispatch(confirmError(error));
  }
};

export const requestPasswordChange = email => async dispatch => {
  try {
    dispatch(requestPasswordChangeRequest());
    await userService.requestPasswordChange(email);
    dispatch(requestPasswordChangeSuccess());
  } catch ({ data }) {
    dispatch(requestPasswordChangeError(parseError(data)));
  }
};

export const changePassword = passwordWithConfirmation => async dispatch => {
  try {
    dispatch(changePasswordRequest());
    await userService.changePassword(passwordWithConfirmation);
    dispatch(changePasswordSuccess());
  } catch ({ data: { error } }) {
    dispatch(changePasswordError(error));
  }
};

export const getProfile = () => async dispatch => {
  try {
    dispatch(getProfileRequest());
    const {
      data: { user }
    } = await userService.getProfile();
    dispatch(getProfileSuccess(user));
  } catch ({ data }) {
    dispatch(getProfileError(parseError(data)));
  }
};
