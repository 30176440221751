import api from 'api/trackingApi';
import { applyQueryParams } from 'utils/helpers';

const eventNewPodPurchased = 'new_pod_purchased';

class TrackingService {
  static podPurchaseCompleted({ transactionId, amount, costOfGoods, podId, email }) {
    return api.get(
      applyQueryParams('/', {
        eventType: eventNewPodPurchased,
        transactionId,
        costOfGoods,
        amount,
        emailAddress: email,
        podId
      })
    );
  }
}

export default TrackingService;
