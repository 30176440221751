import React from 'react';
import { string, number } from 'prop-types';

const User = ({ className, width, height }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      stroke="#899EB1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5.333 28v-2.667A5.333 5.333 0 0110.666 20h10.667a5.333 5.333 0 015.334 5.333V28M16 14.667A5.333 5.333 0 1116 4a5.333 5.333 0 010 10.667z"
    />
  </svg>
);

User.propTypes = {
  className: string,
  width: number,
  height: number
};

User.defaultProps = {
  className: '',
  width: 32,
  height: 32
};

export default User;
