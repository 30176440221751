import React, { memo } from 'react';
import { object, string, arrayOf, node, instanceOf } from 'prop-types';
import cn from 'classnames';
import DatePickerComponent from 'react-datepicker';

import { useToggle } from 'hooks';

import styles from './DatePicker.scss';

const DatePicker = ({ label, icon, placeholder, customError, className, value, ...props }) => {
  const [open, toggleOpen] = useToggle(false);

  const input = (
    <DatePickerComponent
      open={open}
      onInputClick={toggleOpen}
      onClickOutside={toggleOpen}
      onSelect={toggleOpen}
      placeholderText={placeholder}
      className={cn('w-100 px-3', { [styles.errorinput]: !!customError }, styles.input)}
      calendarClassName="text-x-small"
      selected={value}
      {...props}
    />
  );

  return (
    <div className={className}>
      {label && <label className="text-x-small text-weight-semibold d-block mb-2">{label}</label>}
      <div>
        {icon ? (
          <div className={styles.icon}>
            {input}
            <div onClick={toggleOpen}>{icon}</div>
          </div>
        ) : (
          input
        )}
        {customError && (
          <p className={cn([styles.error, 'text-x-small text-alert text-weight-semibold mt-1'])}>
            {customError[0]}
          </p>
        )}
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  input: object,
  label: string,
  icon: node,
  placeholder: string,
  className: string,
  inputClassName: string,
  customError: arrayOf(string),
  value: instanceOf(Date)
};

DatePicker.defaultProps = {
  label: '',
  className: ''
};

export default memo(DatePicker);
