import React from 'react';

import Item from './Item';

const BundleItems = ({ size, sizePrice, discount, discountText }) => {
  return (
    <>
      <Item title={`${size}`} price={`$ ${sizePrice}`} />
      {discount > 0 && <Item title="Discount" price={discountText} discount />}
    </>
  );
};

export default BundleItems;
