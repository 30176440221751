import React from 'react';

const Exit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
    <path
      stroke="#06223C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6.75 15.75h-3a1.5 1.5 0 01-1.5-1.5V3.75a1.5 1.5 0 011.5-1.5h3M12 12.75L15.75 9 12 5.25M15.75 9h-9"
    />
  </svg>
);

export default Exit;
