import React from 'react';
import { string, number } from 'prop-types';
import { gray } from 'styles/_constants.scss';

const People = ({ color, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      view-box="0 0 24 24"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2M9 11a4 4 0 100-8 4 4 0 000 8zM23 21v-2a4 4 0 00-3-3.87M16 3.13a4 4 0 010 7.75"
      />
    </svg>
  );
};

People.propTypes = {
  color: string,
  width: number,
  height: number
};

People.defaultProps = {
  color: gray,
  width: 24,
  height: 24
};

export default People;
