import React, { useEffect, useState } from 'react';

import Arrow from 'components/common/Icons/Arrow';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import cn from 'classnames';

import { actionTypes, createBundlePayment, createBundlePaymentReset } from 'actions/podActions';
import { useDispatch, useStatus } from 'hooks';
import useLoading from 'hooks/useLoading';
import routes from 'constants/routesPaths';
import { useHistory } from 'react-router-dom';

import styles from './ButtonPanel/ButtonPanel.scss';

const ButtonBundlePayment = ({ disabled, selectedBundle, stripe, totalPrice, email }) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const isLoading = useLoading(actionTypes.CREATE_BUNDLE_PAYMENT);
  const history = useHistory();
  const create = useDispatch(createBundlePayment);
  const { error, status } = useStatus(actionTypes.CREATE_BUNDLE_PAYMENT);
  const resetCreateBundleStatus = useDispatch(createBundlePaymentReset);

  useEffect(() => {
    if (!buttonClicked) return;

    if (status === 'ERROR') {
      alert(error);
    }
    if (status === 'SUCCESS' || status === 'ERROR') {
      resetCreateBundleStatus();
    }
    if (status === 'SUCCESS') {
      history.push(routes.createSuccessPayment);
    }

    return () => setButtonClicked(false);
  }, [resetCreateBundleStatus, error, status]);

  const createAsyncPayment = async () => {
    try {
      await create({
        stripe,
        pricingId: selectedBundle.id,
        promoCode: null,
        totalPrice,
        email
      });
    } catch ({ data }) {
      console.log('data', data);
    }
  };

  return (
    <Button
      disabled={isLoading || disabled}
      type="submit"
      styleType="primary"
      onClick={e => {
        e.preventDefault();
        createAsyncPayment(e);
        setButtonClicked(true);
      }}
      className={cn(styles.button, { 'opacity-muted': disabled })}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.submitWrapper}>
          <span className={styles.label}>
            {totalPrice > 0 ? 'Purchase & Return Home' : 'Redeem without creating the first pod'}
          </span>
          <Arrow type="right" height={30} colorType="light" />
        </div>
      )}
    </Button>
  );
};

export default ButtonBundlePayment;
