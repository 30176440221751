import React, { memo } from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import routesPaths from 'constants/routesPaths';
import { useForm, useStatus, useTextInputProps, useValidation, useDispatch } from 'hooks';
import { LOADING } from 'constants/status';

import { actionTypes, changePassword } from 'actions/userActions';
import { Inputs } from 'components/common/Input';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import { SIGNUP_FIELDS } from 'constants/fields';
import updatePasswordValidations from 'validations/updatePasswordValidations';
import { messages } from './constants';

<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
  integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA=="
  crossorigin="anonymous"
/>;
const { password, passwordConfirmation } = SIGNUP_FIELDS;

export const UpdatePasswordForm = ({ className }) => {
  const onSubmit = useDispatch(changePassword);
  const { status, error } = useStatus(actionTypes.CHANGE_PASSWORD);
  const validator = useValidation(updatePasswordValidations);
  const { values, errors, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true
    },
    [onSubmit]
  );
  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);

  function showhidepswd() {
    const password = document.getElementById('password');
    if (password.type === 'password') {
      password.type = 'text';
    } else {
      password.type = 'password';
    }
    // toggle icon
    const checkIcon = document.getElementById('eye').className == 'fa fa-eye-slash';
    if (checkIcon) {
      document.getElementById('eye').className = 'fa fa-eye';
    } else {
      document.getElementById('eye').className = 'fa fa-eye-slash';
    }
  }

  function showhideconfirmpswd() {
    const confirmPassword = document.getElementById('confirmPassword');
    if (confirmPassword.type === 'password') {
      confirmPassword.type = 'text';
    } else {
      confirmPassword.type = 'password';
    }
    // toggle icon
    const checkIcon = document.getElementById('passwordEye').className == 'fa fa-eye-slash';
    if (checkIcon) {
      document.getElementById('passwordEye').className = 'fa fa-eye';
    } else {
      document.getElementById('passwordEye').className = 'fa fa-eye-slash';
    }
  }

  return (
    <form onSubmit={handleSubmit} className={className}>
      <span className="text-weight-medium">{messages.title}</span>
      <Inputs
        label={messages[password]}
        placeholder={messages.passwordPlaceholder}
        type="password"
        id="password"
        customError={errors[password]}
        input={{ ...inputProps(password) }}
        className="my-4"
      />
      <div style={{ float: 'right' }}>
        <i className="fa fa-eye-slash" onClick={showhidepswd} id="eye"></i>
      </div>
      <Inputs
        label={messages[passwordConfirmation]}
        placeholder={messages.passwordConfirmationPlaceholder}
        type="password"
        id="confirmPassword"
        customError={errors[passwordConfirmation]}
        input={{ ...inputProps(passwordConfirmation) }}
        className="my-4"
      />
      <div style={{ float: 'right' }}>
        <i className="fa fa-eye-slash" onClick={showhideconfirmpswd} id="passwordEye"></i>
      </div>
      <div className="mb-4">
        <label className="text-x-small text-weight-semibold text-alert">
          {errors[passwordConfirmation] || errors[password] ? '' : error}
        </label>
      </div>
      <div className="d-flex flex-column align-items-center mt-9">
        <Button
          disabled={isEmpty(values) || status === LOADING}
          className="py-3 col-9 col-lg-7"
          type="submit"
        >
          {status === LOADING ? (
            <Loader className="mt-n3 mb-5" />
          ) : (
            <span className="text-h5 text-inverted">{messages.button}</span>
          )}
        </Button>
        <Link to={routesPaths.index} className="clickable my-9">
          <u className="text-blue canceButton">{messages.back}</u>
        </Link>
      </div>
    </form>
  );
};

UpdatePasswordForm.propTypes = {
  className: string
};

UpdatePasswordForm.defaultProps = {
  className: ''
};

export default memo(UpdatePasswordForm);
