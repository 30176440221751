import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { useSession } from 'hooks';
import { ReactComponent as Parachute } from 'assets/icons/parachute.svg';
import Card from 'components/common/Card';
import Button from 'components/common/LinkButton';
import Header from 'components/common/Header';
import routes from 'constants/routesPaths';

import { messages } from './constants';

const CreatePodSuccessPage = () => {
  const {
    user: { referral }
  } = useSession();

  return (
    <div className="d-flex flex-column min-vh-100 h-100">
      <Header />
      <div className="bg-gray h-100">
        <div className="container p-md-9">
          <Card className="container d-flex flex-column pb-2 pb-md-10 px-4 mt-5 rounded-5 background-card">
            <Parachute className="my-8 mx-auto" />
            <h1 className="text-h3 text-center mb-2">{messages.title}</h1>
            <span className="text-center mb-6">{messages.firstSubtitle}</span>
            <span className="text-center text-small text-gray">{messages.firstLabel}</span>
            {referral?.code && (
              <Link to={routes.referral} className="mt-8 text-center text-blue">
                <u className="canceButton">{messages.link(referral?.amount)}</u>
              </Link>
            )}
            <div className="mt-8 mt-lg-10 px-8 px-md-12">
              <div className="row justify-content-center mx-lg-12">
                <Button
                  inverted
                  route={routes.createPod}
                  className="mb-5 order-2 col-12 col-lg ml-lg-9 mr-lg-3"
                  label={messages.back}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default memo(CreatePodSuccessPage);
