import React, { useEffect, memo } from 'react';
import { arrayOf, func, number, object, shape } from 'prop-types';

import { actionTypes } from 'actions/podActions';

import ReviewStep from 'components/createPod/ReviewStep';
import { useStatus } from 'hooks';
import Checkboxes from './Checkboxes';
import PromoCode from '../PromoCode';

import { messages, extras } from './constants';

const ReviewOrder = ({
  steps,
  goBack,
  onBlur,
  onChange,
  values,
  setSubmitDisabled,
  discount,
  pricingPlan
}) => {
  const { titles } = messages;

  const { status, error } = useStatus(actionTypes.CHECK_PROMO_CODE);

  const checkboxValues = Object.fromEntries(
    Object.entries(values).filter(([key]) => !key.includes('promoCode'))
  );

  const sizeBasedFlowPromoEntered = values.promoCode && !discount && pricingPlan !== 'bundle';

  useEffect(() => {
    if (error || sizeBasedFlowPromoEntered) {
      setSubmitDisabled(true);
      return;
    }
    setSubmitDisabled(Object.values(checkboxValues).some(checkbox => !checkbox));
    return () => setSubmitDisabled(false);
  }, [setSubmitDisabled, values, checkboxValues, discount]);

  return (
    <div className="col-12 mt-6 mt-md-0">
      {steps.map(({ step, values }, index) => (
        <ReviewStep key={step} title={titles[index]} onEdit={() => goBack(step)} values={values} />
      ))}
      {extras[pricingPlan].map(({ message, price }) => (
        <div className="col-12 px-0 d-flex justify-content-between mb-7" key={message}>
          <span className="col-9 px-0">{message}</span>
          <span className="col-3 text-right px-0">${price}</span>
        </div>
      ))}
      {!discount && pricingPlan === 'size' && (
        <PromoCode
          error={error}
          onBlur={onBlur}
          onChange={onChange}
          status={status}
          values={values}
        />
      )}
      <Checkboxes onChange={onChange} values={values} />
    </div>
  );
};

ReviewOrder.propTypes = {
  steps: arrayOf(
    shape({
      step: number,
      values: object
    })
  ).isRequired,
  goBack: func.isRequired,
  onBlur: func,
  onChange: func.isRequired,
  values: object.isRequired,
  setSubmitDisabled: func.isRequired
};

ReviewOrder.defaultProps = {
  onBlur: () => {}
};

export default memo(ReviewOrder);
