import React from 'react';
import { string, number } from 'prop-types';
import { gray } from 'styles/_constants.scss';

const UploadCloud = ({ color, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      view-box="0 0 24 24"
    >
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" clipPath="url(#clip0)">
        <path stroke="#000" d="M16 16l-4-4-4 4" />
        <path stroke={color} d="M12 12v9M20.39 18.39A5 5 0 0018 9h-1.26A8 8 0 103 16.3" />
        <path stroke={color} d="M16 16l-4-4-4 4" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

UploadCloud.propTypes = {
  color: string,
  width: number,
  height: number
};

UploadCloud.defaultProps = {
  color: gray,
  width: 24,
  height: 24
};

export default UploadCloud;
