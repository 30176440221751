import React from 'react';

import { useBreakpoint } from 'hooks';

import { sm } from 'styles/_constants.scss';
import Desktop from './Desktop';
import Mobile from './Mobile';

const DefaultLanding = () => {
  const isDesktop = useBreakpoint(sm);
  return isDesktop ? <Desktop /> : <Mobile />;
};

export default DefaultLanding;
