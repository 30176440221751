import React, { useEffect } from 'react';
import { PRICING_FIELDS } from 'constants/fields';
import cn from 'classnames';
import stylesError from 'components/common/Input/Inputs/Inputs.scss';
import BoxIcon from 'assets/icons/box.svg';
import BoxesIcon from 'assets/icons/boxes.svg';
import PlanBoxesIcon from 'components/common/Icons/Boxes';
// import PlanSortIcon from 'components/common/Icons/Sort';
import PlanGiftIcon from 'components/common/Icons/Gift';

import styles from './PricingPlan.scss';

const PricingPlan = ({
  bundlePod,
  bundlePods,
  errors,
  giftPodsCount,
  handleValueChange,
  pricingPlan,
  setPlan
}) => {
  useEffect(() => {
    if (pricingPlan === 'bundle' && bundlePod === null) {
      handleValueChange('bundlePod', bundlePods?.[0].id);
    }
  }, [pricingPlan]);

  return (
    <div className="mt-6 mt-md-0">
      <p className="border-bottom mb-8 pb-3">
        <img alt="Box icon" className="mr-3" src={BoxIcon} />
        Pod Types
      </p>

      <div className="row">
        {/* <div className={`${giftPodsCount ? 'col-lg-6 col-xxl-4' : 'col-lg-6'} mb-4`}>
          <label className={cn(styles.box, { [styles.boxActive]: pricingPlan === 'size' })}>
            <input
              className="mr-1"
              defaultChecked={pricingPlan === 'size'}
              name="pricingPlan"
              onChange={e => {
                handleValueChange(e.target.name, e.target.value);
                setPlan(e.target.value);
              }}
              type="radio"
              value="size"
            />
            <PlanSortIcon
              color={pricingPlan === 'size' ? '#3FA2FC' : '#BDBDBD'}
              height={60}
              width={60}
            />
            <p className={cn(styles.text)}>Purchase Based on Size</p>
            <span className={cn(styles.select)}>Select</span>
          </label>
        </div> */}

        {!!giftPodsCount && (
          <div className="col-lg-6 col-xxl-6 mb-4">
            <label className={cn(styles.box, { [styles.boxActive]: pricingPlan === 'gift' })}>
              <input
                className="mr-1"
                defaultChecked={pricingPlan === 'gift'}
                name="pricingPlan"
                onChange={e => {
                  handleValueChange(e.target.name, e.target.value);
                  setPlan(e.target.value);
                }}
                type="radio"
                value="gift"
              />
              <PlanGiftIcon
                color={pricingPlan === 'gift' ? '#3FA2FC' : '#BDBDBD'}
                height={60}
                width={60}
              />
              <p className={cn(styles.text)}>Fill Available Pods</p>
              <span className={cn(styles.select)}>Select</span>
            </label>
          </div>
        )}

        <div className={`${giftPodsCount ? 'col-lg-6 col-xxl-6' : 'col-lg-6'} mb-4`}>
          <label className={cn(styles.box, { [styles.boxActive]: pricingPlan === 'bundle' })}>
            <input
              className="mr-1"
              defaultChecked={pricingPlan === 'bundle'}
              name="pricingPlan"
              onChange={e => {
                handleValueChange(e.target.name, e.target.value);
                setPlan(e.target.value);
              }}
              type="radio"
              value="bundle"
            />
            <PlanBoxesIcon
              color={pricingPlan === 'bundle' ? '#3FA2FC' : '#BDBDBD'}
              height={60}
              width={60}
            />
            <p className={cn(styles.text)}>Purchase a Pod</p>
            {!!giftPodsCount && <span className={cn(styles.select)}>Select</span>}
          </label>
        </div>
      </div>

      {bundlePods && pricingPlan === 'bundle' && (
        <>
          <p className="border-bottom mb-8 mt-9 pb-3">
            <img alt="Boxes icon" className="mr-3" src={BoxesIcon} />
            Below are your pod details
          </p>

          <div className="row">
            {bundlePods.map((pod, index) => (
              <div className="col-lg-6 col-xxl-4 mb-8" key={index}>
                <div
                  className={cn(styles.bundleBox, {
                    [styles.bundleBoxActive]: bundlePod == pod.id
                  })}
                >
                  <label className="d-flex mb-2">
                    <input
                      className={cn(styles.checkbox)}
                      defaultChecked={
                        (pricingPlan === 'bundle' && bundlePod === null && index === 0) ||
                        bundlePod == pod.id
                      }
                      name="bundlePod"
                      onChange={e => handleValueChange(e.target.name, e.target.value)}
                      type="radio"
                      value={pod.id}
                    />
                    <span className={cn(styles.bundleName)}>
                      {pod.bundleSize} {`Pod${pod.bundleSize > 1 ? 's' : ''}`}
                    </span>
                  </label>

                  <div className="pl-6 text-small">
                    <p>Number of Pods: {pod.bundleSize}</p>
                    <p>Price: $ {pod.price}</p>
                    <p>Storage limit: {pod.storageLimitInMegabytes / 1000}GB</p>
                    <p>Expires in: {Math.ceil(pod.expirationMonth / 12)} years</p>
                  </div>

                  <p className={cn([stylesError.error, 'text-x-small text-weight-semibold mt-1'])}>
                    {errors[PRICING_FIELDS.bundlePod]}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PricingPlan;
