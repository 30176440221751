import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { number, func } from 'prop-types';

import { getPodPricing, actionTypes } from 'actions/podActions';
import { usePriceForSize, useDispatchOnMount, useStatus } from 'hooks';

import GeneralDataHandler from 'components/common/GeneralDataHandler';
import routes from 'constants/routesPaths';
import { SUCCESS, ERROR } from 'constants/status';

import GetExtraTimeComp from './GetExtraTimeComp';

const GetExtraTime = ({ id, podSize, handleBack, goBack }) => {
  useDispatchOnMount(getPodPricing);
  const priceList = useSelector(({ pods: { pricings } }) => pricings, shallowEqual);
  const { status: getPriceListStatus } = useStatus(actionTypes.GET_NEW_POD_INFO);

  const podPrice = usePriceForSize(podSize, priceList);

  const minimumCost = useSelector(({ pods: { minimumCost } }) => minimumCost);

  const { status, error } = useStatus(actionTypes.GET_EXTRA_TIME);

  useEffect(() => {
    status === ERROR && alert(error);
  }, [status, error]);

  if (status === SUCCESS) {
    return <Redirect to={routes.extraTimeSuccess} />;
  }

  return (
    <GeneralDataHandler
      status={getPriceListStatus}
      SuccessComponent={GetExtraTimeComp}
      successComponentProps={{ handleBack, goBack, id, podPrice, minimumCost }}
    />
  );
};

GetExtraTime.propTypes = {
  id: number.isRequired,
  podSize: number.isRequired,
  handleBack: func.isRequired,
  goBack: func.isRequired
};

export default GetExtraTime;
