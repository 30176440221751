import { CREATEPOD_FIELDS } from 'constants/fields';
import { RECIPIENTS_ERROR } from './errorMessages';

export default {
  size: [
    {},
    {
      [CREATEPOD_FIELDS.title]: {
        presence: true
      },
      [CREATEPOD_FIELDS.description]: {
        presence: true
      }
    },
    {
      [CREATEPOD_FIELDS.files]: {
        files: {
          uploadedFiles: {
            presence: {
              allowEmpty: false
            }
          }
        }
      }
    },
    {
      [CREATEPOD_FIELDS.recipients]: {
        arrayEmails: {
          'email.label': {
            presence: { message: `^${RECIPIENTS_ERROR.blank}` },
            email: { message: `^${RECIPIENTS_ERROR.invalid}` }
          }
        }
      }
    },
    {
      [CREATEPOD_FIELDS.date]: {
        presence: true
      }
    }
  ],
  bundle: [
    {
      [CREATEPOD_FIELDS.bundlePod]: {
        presence: true
      }
    },
    {},
    {
      [CREATEPOD_FIELDS.title]: {
        presence: true
      },
      [CREATEPOD_FIELDS.description]: {
        presence: true
      }
    },
    {
      [CREATEPOD_FIELDS.files]: {
        files: {
          uploadedFiles: {
            presence: {
              allowEmpty: false
            }
          }
        }
      }
    },
    {
      [CREATEPOD_FIELDS.recipients]: {
        arrayEmails: {
          'email.label': {
            presence: { message: `^${RECIPIENTS_ERROR.blank}` },
            email: { message: `^${RECIPIENTS_ERROR.invalid}` }
          }
        }
      }
    },
    {
      [CREATEPOD_FIELDS.date]: {
        presence: true
      }
    }
  ],
  gift: [
    {},
    {
      [CREATEPOD_FIELDS.title]: {
        presence: true
      },
      [CREATEPOD_FIELDS.description]: {
        presence: true
      }
    },
    {
      [CREATEPOD_FIELDS.files]: {
        files: {
          uploadedFiles: {
            presence: {
              allowEmpty: false
            }
          }
        }
      }
    },
    {
      [CREATEPOD_FIELDS.recipients]: {
        arrayEmails: {
          'email.label': {
            presence: { message: `^${RECIPIENTS_ERROR.blank}` },
            email: { message: `^${RECIPIENTS_ERROR.invalid}` }
          }
        }
      }
    },
    {
      [CREATEPOD_FIELDS.date]: {
        presence: true
      }
    }
  ]
};
