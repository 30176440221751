import React, { memo } from 'react';
import { string, func } from 'prop-types';
import cn from 'classnames';

import { button } from './RemoveButton.scss';

const RemoveButton = ({ className, onClick }) => (
  <div
    className={cn(
      'text-alert rounded-5 py-2 px-4 px-md-6 text-small text-weight-medium clickable',
      button,
      className
    )}
    onClick={onClick}
  >
    Remove
  </div>
);

RemoveButton.propTypes = {
  className: string,
  onClick: func.isRequired
};

RemoveButton.defaultProps = {
  className: ''
};

export default memo(RemoveButton);
