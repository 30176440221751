import React, { memo } from 'react';
import { node, bool, string, func } from 'prop-types';
import cn from 'classnames';

import styles from './Tab.scss';

const Tab = ({ children, focused, className, onClick, black }) => {
  const classNames = cn('d-flex', 'justify-content-center', 'clickable', {
    [className]: !!className,
    [black ? styles.blackFocused : styles.focused]: !!focused
  });

  return (
    <div className={classNames} onClick={onClick}>
      {children}
    </div>
  );
};

Tab.propTypes = {
  children: node.isRequired,
  focused: bool.isRequired,
  onClick: func.isRequired,
  className: string
};

export default memo(Tab);
