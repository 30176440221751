import React from 'react';
import cn from 'classnames';
import { string, object } from 'prop-types';

import { ReactComponent as External } from 'assets/icons/external-link.svg';
import Button from 'components/common/Button';
import { customBackground } from 'utils/helpers';
import styles from './PdfPreview.scss';

const PdfPreview = ({ file: { thumbnail, playUrl }, className }) => (
  <div className="h-100 position-relative">
    <Button
      styleType="primary"
      type="link"
      link={{ href: playUrl, target: '_blank' }}
      className={cn('m-auto py-2 px-3 d-flex')}
      linkClassName={cn('position-absolute', styles.button)}
    >
      <span className="text-inverted text-weight-semibold my-auto mr-4">Open PDF</span>
      <External className="my-auto" />
    </Button>
    <div
      style={{ ...customBackground(thumbnail, null, 'top', 'contain') }}
      className={cn('h-100 mt-7 mb-7 mx-4', styles.preview, className)}
    />
  </div>
);

PdfPreview.propTypes = {
  className: string,
  file: object.isRequired
};

PdfPreview.defaultProps = {
  className: ''
};

export default PdfPreview;
