import { CREATEPOD_FIELDS } from 'constants/fields';
import { stepTexts } from './stepTexts';

export const INDEX = {
  size: {
    SELECT: 0,
    BASIC: 1,
    FILES: 2,
    RECIPIENTS: 3,
    DATE: 4,
    REVIEW: 5,
    PAYMENT: 6
  },
  bundle: {
    SELECT: 0,
    PAYMENT: 1,
    BASIC: 2,
    FILES: 3,
    RECIPIENTS: 4,
    DATE: 5,
    REVIEW: 6
  },
  gift: {
    SELECT: 0,
    BASIC: 1,
    FILES: 2,
    RECIPIENTS: 3,
    DATE: 4,
    REVIEW: 5
    // PAYMENT: 6
  }
};

export const steps = {
  size: [
    stepTexts.size.pricingPlan,
    stepTexts.size.basicPodInfo,
    stepTexts.size.addFiles,
    stepTexts.size.recipients,
    stepTexts.size.openDate,
    stepTexts.size.review,
    stepTexts.size.payment
  ],
  bundle: [
    stepTexts.bundle.pricingPlan,
    stepTexts.bundle.payment,
    stepTexts.bundle.basicPodInfo,
    stepTexts.bundle.addFiles,
    stepTexts.bundle.recipients,
    stepTexts.bundle.openDate,
    stepTexts.bundle.review
  ],
  gift: [
    stepTexts.gift.pricingPlan,
    stepTexts.gift.basicPodInfo,
    stepTexts.gift.addFiles,
    stepTexts.gift.recipients,
    stepTexts.gift.openDate,
    stepTexts.gift.review
    // stepTexts.gift.payment
  ]
};

export const initialValues = {
  [CREATEPOD_FIELDS.bundlePod]: null,
  [CREATEPOD_FIELDS.pricingPlan]: 'bundle',
  [CREATEPOD_FIELDS.date]: '',
  [CREATEPOD_FIELDS.description]: '',
  [CREATEPOD_FIELDS.recipients]: [{ [CREATEPOD_FIELDS.email]: null }],
  [CREATEPOD_FIELDS.time]: {
    [CREATEPOD_FIELDS.hour]: null,
    [CREATEPOD_FIELDS.minutes]: null,
    [CREATEPOD_FIELDS.period]: null,
    [CREATEPOD_FIELDS.timezone]: null
  },
  [CREATEPOD_FIELDS.title]: '',
  [CREATEPOD_FIELDS.coverImage]: null,
  [CREATEPOD_FIELDS.files]: { uploadedFiles: null, totalSize: 0 },
  [CREATEPOD_FIELDS.checkViewableDays]: false,
  [CREATEPOD_FIELDS.checkNoRefunds]: false,
  [CREATEPOD_FIELDS.checkPodAccepted]: false,
  [CREATEPOD_FIELDS.promoCode]: null
};
