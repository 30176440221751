import { SIGNUP_FIELDS } from 'constants/fields';

export const RECIPIENTS_ERROR = {
  blank: "Email can't be blank",
  invalid: 'This is not a valid email',
  duplicated: 'Duplicated recipient'
};

export const FILES_ERROR = {
  empty: 'Please, upload files to the pod'
};

export const SIGNUP_ERROR = {
  [SIGNUP_FIELDS.terms]: '^The terms and conditions and privacy policy must be accepted'
};
