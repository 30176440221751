import React, { memo } from 'react';
import cn from 'classnames';

import styles from './Banner.scss';

const GiftBanner = ({ className, amount }) => (
  <div
    className={cn(
      styles.banner,
      'row align-items-center justify-content-center no-gutters py-4 pl-5',
      className
    )}
  >
    <span className="text-weight-bold text-inverted mr-2">
      You have {amount} gift pods which are ready for use.
    </span>
  </div>
);

export default memo(GiftBanner);
