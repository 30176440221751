import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { validate } from 'isemail';
import { useDispatch, useStatus, useLoading } from 'hooks';
import { confirm, confirmClearStatus, actionTypes } from 'actions/userActions';

import Footer from 'components/common/Footer';
import Logo from 'components/common/Icons/Logo';
import Loader from 'components/common/Loader';
import { SUCCESS } from 'constants/status';
import { LABELS } from 'pages/SignUpPage/tabs';
import getInfoFromQuery from 'utils/getInfoFromQuery';
import { replaceLandingTab } from 'utils/tabHelpers';

import { messages } from './constants';

const VerifyEmailPage = () => {
  const { search } = useLocation();
  const urlParams = getInfoFromQuery(search, ['email']);
  const verifyEmail = useSelector(({ verification: { email } }) => email) || urlParams.email;

  const { status } = useStatus(actionTypes.CONFIRM_SIGNUP);
  const isLoading = useLoading(actionTypes.CONFIRM_SIGNUP);
  const confirmRequest = useDispatch(confirm);
  const confirmClearStatusRequest = useDispatch(confirmClearStatus);

  useEffect(() => () => confirmClearStatusRequest(), [confirmClearStatusRequest]);

  if (!verifyEmail || !validate(verifyEmail)) {
    return <Redirect to={replaceLandingTab(LABELS.index)} />;
  }

  return (
    <div className="bg-gray min-vh-100 py-4 d-flex">
      <div className="container d-flex flex-column flex-grow-1">
        <div className="mt-md-12 mb-md-12 col-md-8 offset-md-2 bg-white rounded-5 p-4 pt-9 d-flex flex-column align-items-center flex-grow-1 text-center">
          <Logo className="mb-11 mt-7" width={260} />
          <h2 className="mt-4 text-h5">{messages.title}</h2>
          <span className="mt-5">{messages.firstLine + verifyEmail}</span>
          <span>{messages.secondLine}</span>
          <span className="text-gray mt-9">{messages.linkIntro} </span>
          <div className="pb-9">
            <span className="text-gray d-block d-md-inline text-small">{messages.spamInfo}</span>
            <span
              className="text-blue clickable text-small"
              onClick={() => confirmRequest(verifyEmail)}
            >
              <u className="canceButton">{messages.link}</u>
            </span>
          </div>
          <div className="px-12 pb-6">
            {isLoading && <Loader type="blue" className="pb-4" />}
            {status === SUCCESS && (
              <p className="text-gray px-12 text-x-small">{messages.success}</p>
            )}
          </div>
        </div>
        <Footer className="d-none d-md-inline" />
      </div>
    </div>
  );
};

export default memo(VerifyEmailPage);
