import createAction from 'actions/createAction';
import CampaignService from 'services/campaignService';
import parseError from 'utils/parseError';

export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const getCampaignRequest = createAction(`${GET_CAMPAIGN}_REQUEST`);
export const getCampaignSuccess = createAction(`${GET_CAMPAIGN}_SUCCESS`);
export const getCampaignError = createAction(`${GET_CAMPAIGN}_ERROR`);

export const getCampaign = code => async dispatch => {
  try {
    dispatch(getCampaignRequest());
    const { data } = await CampaignService.getCampaign(code);
    dispatch(getCampaignSuccess(data));
  } catch (err) {
    dispatch(getCampaignError(parseError(err)));
  }
};
