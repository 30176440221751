import React from 'react';
import { string } from 'prop-types';
import cn from 'classnames';

import style from './Loader.scss';

const Loader = ({ type, className, loaderClassName }) => (
  <div className={cn(type === 'blue' ? style.blueWrapper : style.whiteWrapper, className)}>
    <div className={cn(style.loader, loaderClassName)} />
  </div>
);

Loader.propTypes = {
  type: string,
  className: string,
  loaderClassName: string
};

Loader.defaultProps = {
  type: '',
  className: '',
  loaderClassName: ''
};

export default Loader;
