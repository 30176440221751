import React from 'react';

import routesPaths from 'constants/routesPaths';

import ConfirmPage from 'pages/ConfirmEmailPage';
import CreatePodPage from 'pages/CreatePodPage';
import UpdatePodPage from 'pages/UpdatePodPage';
import UpdatePasswordPage from 'pages/UpdatePasswordPage';
import CreatePodSuccessPage from 'pages/CreatePodSuccessPage';
import CreatePodSuccessPagePayment from 'pages/CreatePodSuccessPagePayment';
import OutgoingPodPage from 'pages/OutgoingPodPage';
import ReferralPage from 'pages/ReferralPage';
import HomePage from 'pages/HomePage';
import SignUpPage from 'pages/SignUpPage';
import VerifyEmailPage from 'pages/VerifyEmailPage';
import OpenPodPage from 'pages/OpenPodPage';
import OpenPodPublicPage from 'pages/OpenPodPublicPage';
import ExtraTimeSuccessPage from 'pages/ExtraTimeSuccessPage';
import FormatViewingSuggestionsPage from 'pages/FormatViewingSuggestionsPage';
import Privacy from '../src/components/staticPages/privacy';

const routes = [
  {
    path: routesPaths.privacy,
    component: <Privacy />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.index,
    component: <HomePage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.home,
    component: <HomePage />,
    private: true
  },
  {
    path: routesPaths.createPod,
    component: <CreatePodPage />,
    exact: true
  },
  {
    path: routesPaths.updatePod,
    component: <UpdatePodPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.createSuccess,
    component: <CreatePodSuccessPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.createSuccessPayment,
    component: <CreatePodSuccessPagePayment />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.outgoingPod,
    component: <OutgoingPodPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.referral,
    component: <ReferralPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.incomingPod,
    component: <OpenPodPublicPage />,
    exact: true,
    private: false
  },
  {
    path: routesPaths.openPod,
    component: <OpenPodPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.extraTimeSuccess,
    component: <ExtraTimeSuccessPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.formatViewingSuggestions,
    component: <FormatViewingSuggestionsPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.signUp,
    component: <SignUpPage />
  },
  {
    path: routesPaths.verifyEmail,
    component: <VerifyEmailPage />
  },
  {
    path: routesPaths.confirmEmail,
    component: <ConfirmPage />
  },
  {
    path: routesPaths.updatePassword,
    component: <UpdatePasswordPage />
  },
  {
    path: routesPaths.campaign,
    component: <SignUpPage />
  }
];

export default routes;
