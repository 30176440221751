import React from 'react';
import { useHistory } from 'react-router-dom';
import { array } from 'prop-types';
import cn from 'classnames';

import { useBreakpoint } from 'hooks';

import Arrow from 'components/common/Icons/Arrow';
import Paper from 'components/common/Paper';

import { md } from 'styles/_constants.scss';
import styles from './FormatViewingSuggestions.scss';

import { messages, arrowDimensions } from './constants';

const FormatViewingSuggestions = ({ viewingSuggestions }) => {
  const { goBack } = useHistory();

  const isMd = useBreakpoint(md);

  return (
    <Paper className="col-lg-10 offset-lg-1 pt-4 pt-md-7 pb-7 pb-md-12 px-6 px-md-8 mt-md-9 mb-md-11">
      <a className="d-inline-block" onClick={goBack}>
        <Arrow
          type="left"
          width={arrowDimensions.width}
          height={arrowDimensions.height}
          backArrow
        />
      </a>
      <div className="pt-2 pt-md-8 pl-0 pl-md-6 pb-5 pb-md-12">
        <p className={cn('text-weight-regular', { 'text-h4': !isMd }, { 'text-h2': isMd })}>
          {messages.title}
        </p>
        {viewingSuggestions.map(({ name, description, applications }) => (
          <div key={name} className="pb-3 pt-5">
            <p className={cn('pb-2 text-weight-bold')}>{name}</p>
            <h5 className="text-gray text-small">{messages.description}</h5>
            <p className="pb-2">{description}</p>
            <ul className={cn('pt-5 pl-5', styles.list)}>
              {applications.map(({ title, link }) => (
                <li key={title}>
                  <a href={link} className="text-underline text-blue">
                    {title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Paper>
  );
};

FormatViewingSuggestions.propTypes = {
  viewingSuggestions: array.isRequired
};

export default FormatViewingSuggestions;
