import React, { memo } from 'react';
import { string, bool } from 'prop-types';

import cn from 'classnames';

const Item = ({ title, price, discount, strikethrough }) => (
  <div className="d-flex justify-content-between mt-2">
    <span>{title}</span>
    <span
      className={cn({ 'text-success': discount, 'text-decoration-line-through': strikethrough })}
    >{`${discount ? '-' : ''}${price}`}</span>
  </div>
);

Item.propTypes = {
  title: string.isRequired,
  price: string.isRequired,
  strikethrough: bool,
  discount: bool
};

Item.defaultProps = {
  discount: false
};

export default memo(Item);
