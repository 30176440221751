import createAction from 'actions/createAction';
import giftsService from 'services/giftsService';
import parseError from 'utils/parseError';

export const actionTypes = {
  CREATE_GIFT: 'CREATE_GIFT',
  CREATE_GIFT_SUCCESS: 'CREATE_GIFT_SUCCESS',
  CREATE_GIFT_ERROR: 'CREATE_GIFT_ERROR',
  CREATE_GIFT_REQUEST: 'CREATE_GIFT_REQUEST',
  CREATE_GIFT_RESET_STATUS: 'CREATE_GIFT_RESET_STATUS',
  GET_GIFTS_SUCCESS: 'GET_GIFTS_SUCCESS',
  GET_GIFTS_ERROR: 'GET_GIFTS_ERROR',
  GET_GIFTS_REQUEST: 'GET_GIFTS_REQUEST'
};

export const createGiftSuccess = createAction(actionTypes.CREATE_GIFT_SUCCESS);
export const createGiftError = createAction(actionTypes.CREATE_GIFT_ERROR);
export const createGiftRequest = createAction(actionTypes.CREATE_GIFT_REQUEST);
export const createGiftClearStatus = createAction(actionTypes.CREATE_GIFT_RESET_STATUS);

const getGiftsRequest = createAction(actionTypes.GET_GIFTS_REQUEST);
const getGiftsSuccess = createAction(actionTypes.GET_GIFTS_SUCCESS);
const getGiftsError = createAction(actionTypes.GET_GIFTS_ERROR);

export const getGifts = () => async dispatch => {
  dispatch(getGiftsRequest());
  try {
    const { data } = await giftsService.getGifts();
    dispatch(getGiftsSuccess(data));
  } catch ({ data }) {
    dispatch(getGiftsError(parseError(data)));
  }
};

export const createGift = data => async dispatch => {
  dispatch(createGiftRequest(data));

  try {
    await giftsService.createGift(data);
    dispatch(createGiftSuccess());
  } catch ({ data }) {
    dispatch(createGiftError(parseError(data)));
  }
};
