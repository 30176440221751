import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { string, number } from 'prop-types';
import cn from 'classnames';

import Badge from 'components/common/Badge';
import Wallet from 'components/common/Icons/Wallet';
import routes from 'constants/routesPaths';

import { messages } from './constants';
import styles from './ReferralLink.scss';

const ReferralLink = ({ className, amount }) => (
  <Link to={routes.referral} className="referralLinkText">
    <div
      className={cn(
        styles.referral,
        'row align-items-center justify-content-center no-gutters py-2 pl-5',
        className
      )}
    >
      <Wallet className="my-n5 ml-1 mr-3" />
      <div className="col col-md-4 row no-gutters align-items-center">
        <span className="text-weight-bold text-inverted mr-2">{messages.first}</span>
        <Badge className="d-flex justify-content-center p-1 px-2 px-md-3 mr-2" type="green">
          <span className="text-inverted text-weight-bold">${amount}</span>
        </Badge>
        <span className="text-weight-bold text-inverted">{messages.last}</span>
      </div>
    </div>
  </Link>
);

ReferralLink.propTypes = {
  className: string,
  amount: number.isRequired
};

ReferralLink.defaultProps = {
  className: ''
};

export default memo(ReferralLink);
