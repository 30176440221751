import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { useDispatchOnMount, useStatus } from 'hooks';
import { getFormatViewingSuggestions, actionTypes } from 'actions/podActions';

import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import GeneralDataHandler from 'components/common/GeneralDataHandler';
import FormatViewingSuggestions from 'components/pods/FormatViewingSuggestions';

import styles from './FormatViewingSuggestionsPage.scss';

const FormatViewingOptionsPage = () => {
  useDispatchOnMount(getFormatViewingSuggestions);
  const { status } = useStatus(actionTypes.GET_VIEWING_SUGGESTIONS);
  const viewingSuggestions =
    useSelector(({ pods: { formatViewingSuggestions } }) => formatViewingSuggestions) || [];

  return (
    <div className="bg-gray min-vh-100 position-relative d-flex flex-column">
      <Header />
      <div className="container py-4">
        <GeneralDataHandler
          status={status}
          SuccessComponent={FormatViewingSuggestions}
          successComponentProps={{ viewingSuggestions }}
        />
        <Footer className={cn('d-none d-md-inline position-absolute', styles.footer)} />
      </div>
    </div>
  );
};

export default FormatViewingOptionsPage;
