import React, { memo, useCallback, useState } from 'react';
import { object, func } from 'prop-types';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';

import { customBackground } from 'utils/helpers';
import { handleImgOrientation, MBytesToBytes } from 'utils/filesHelpers';

import Plus from 'components/common/Icons/PlusCircle';
import DeleteCoverImage from 'components/common/Icons/DeleteCoverImg';
import UploadFile from 'components/common/UploadFiles';
import Button from 'components/common/Button';
import inputStyles from 'components/common/Input/Inputs/Inputs.scss';

import { coverGradient, blackGradient } from 'styles/_constants.scss';

import { ErrorMessage } from './ErrorMessage';
import { MAX_FILE_SIZE, ACCEPT_FORMATS, MESSAGES } from './constants';
import styles from './CoverImage.scss';

const CoverImage = ({ value, onChange, onClear, setSubmitDisabled }) => {
  const { unsupportedFile, overMaxSize, corruptedFile } = MESSAGES;

  const [rejectedFile, setRejectedFile] = useState();

  const handleImgSuccess = useCallback(
    (image64, name, type) => {
      const coverImage = {
        fileName: name,
        contentType: type,
        data: image64
      };
      onChange(coverImage);
    },
    [onChange]
  );

  const handleImgError = useCallback(() => {
    setRejectedFile(corruptedFile);
    setSubmitDisabled(true);
  }, [corruptedFile, setSubmitDisabled]);

  const handleValueChange = useCallback(
    file => {
      if (file) {
        const { name, type } = file;
        handleImgOrientation(
          file,
          image64 => handleImgSuccess(image64, name, type),
          handleImgError
        );
      } else onChange(file);
    },
    [handleImgError, handleImgSuccess, onChange]
  );

  const setErrorMessage = useCallback(
    message => {
      setRejectedFile(message);
      handleValueChange(null);
      setSubmitDisabled(true);
    },
    [handleValueChange, setSubmitDisabled]
  );

  const cancel = useCallback(() => {
    setRejectedFile();
    setSubmitDisabled();
  }, [setSubmitDisabled]);

  const validations = useCallback(
    files => {
      if (files.length) {
        files.forEach(file => {
          const { size } = file;
          if (size > MBytesToBytes(MAX_FILE_SIZE)) {
            setErrorMessage(overMaxSize);
          } else {
            setRejectedFile();
            handleValueChange(file);
            setSubmitDisabled();
          }
        });
      } else {
        setErrorMessage(unsupportedFile);
      }
    },
    [setErrorMessage, overMaxSize, handleValueChange, setSubmitDisabled, unsupportedFile]
  );

  const { getRootProps } = useDropzone();
  const disableOnClick = getRootProps({ onClick: event => event.stopPropagation() });

  return (
    <div className="mb-4">
      <label className="text-x-small text-weight-semibold d-block mb-2">
        Cover Image (optional)
      </label>
      <UploadFile
        rootClassName={cn([
          'd-flex justify-content-center align-items-center p-3 h-100 w-100',
          styles.dropZone,
          { [inputStyles.errorinput]: rejectedFile },
          { [styles.dropZoneBorder]: !rejectedFile }
        ])}
        customclassName={cn([
          'd-flex justify-content-center align-items-center bg-gray',
          styles.inDropZone,
          { 'pt-9': (!rejectedFile && !value) || !value?.data },
          { 'px-md-9 px-4 pb-7': !value || rejectedFile || !value?.data },
          { 'py-5': rejectedFile }
        ])}
        accept={ACCEPT_FORMATS.long}
        onChange={validations}
      >
        {!value?.data && !rejectedFile && (
          <div>
            <div className="d-flex align-items-center justify-content-center pb-4">
              <Plus />
              <p className={`ml-sm-5 ml-4 ${styles.title}`}>Add Cover Image</p>
            </div>
            <div className="text-center text-gray text-x-small">
              <p>{`Available formats ${ACCEPT_FORMATS.short}`}</p>
              <p>Maximum size {MAX_FILE_SIZE}MB</p>
            </div>
          </div>
        )}
        {!rejectedFile && value && value.data && (
          <div
            style={customBackground(
              value.data,
              null,
              'center',
              'cover',
              `linear-gradient(180deg, ${coverGradient} 0%, ${blackGradient} 100%)`
            )}
            className={cn(['h-100 w-100', styles.coverImage])}
          >
            <Button type="button" className={styles.changeCoverBtn}>
              Change Cover
            </Button>
            <DeleteCoverImage
              width="25"
              height="25"
              className={styles.deleteBtn}
              onClick={onClear}
              disableOnClick={disableOnClick}
            />
          </div>
        )}
        {rejectedFile === overMaxSize && (
          <ErrorMessage disableOnClick={disableOnClick} cancel={cancel} message={rejectedFile}>
            <p className="text-weight-bold">Max file size: {MAX_FILE_SIZE}MB</p>
          </ErrorMessage>
        )}
        {[unsupportedFile, corruptedFile].includes(rejectedFile) && (
          <ErrorMessage disableOnClick={disableOnClick} cancel={cancel} message={rejectedFile} />
        )}
      </UploadFile>
    </div>
  );
};

CoverImage.propTypes = {
  value: object,
  onChange: func.isRequired,
  onClear: func.isRequired,
  setSubmitDisabled: func.isRequired
};

export default memo(CoverImage);
