import moment from 'moment';
import momentTz from 'moment-timezone';

import { TIME_FORMATS } from 'constants/constants';

export const formatDateExtended = date => moment(date).format(TIME_FORMATS.EXTENDED_TIMEZONE);
export const formatDateNormal = date => moment(date).format(TIME_FORMATS.NORMAL_TIMEZONE);

export const dateToIso = (date, newTimezone) => {
  const noTimezone = moment(date).format(TIME_FORMATS.NO_TIMEZONE);
  const timezoneDate = newTimezone && momentTz.tz(noTimezone, newTimezone).format();
  return moment(newTimezone ? timezoneDate : date).toISOString();
};

export const setTimeToDate = (date, time) => {
  const procTime = moment(time, TIME_FORMATS.TWENTY_FOUR);
  const procDate = moment(date);

  procDate.hour(procTime.hour());
  procDate.minute(procTime.minute());
  return procDate;
};

export const monthsFromToday = (date, time, timezone) => {
  const datetime = setTimeToDate(date, time);
  const isoDate = dateToIso(datetime, timezone);
  return Math.ceil(moment(isoDate).diff(dateToIso(new Date()), 'months', true));
};

export const parseMonths = (years, months) => {
  let timeTag = '';
  if (years !== 0) {
    timeTag = `${timeTag}${years} ${years === 1 ? 'year ' : 'years '}`;
  }
  if (months !== 0) {
    timeTag = `${timeTag}${months} ${months === 1 ? 'month' : 'months'}`;
  }
  return timeTag;
};

export const timeToString = (hour, minutes, period) =>
  moment(`${hour}:${minutes} ${period}`, TIME_FORMATS.TWELVE).format(TIME_FORMATS.TWENTY_FOUR);

export const daysFromToday = date =>
  Math.floor(moment(new Date()).diff(moment(date), 'days', true));
