import React from 'react';
import { bool, number, string, arrayOf, shape } from 'prop-types';
import cn from 'classnames';

import styles from './PriceBar.scss';

const PriceBar = ({ className, currentData, pricings, overLimit, pricingPlan, storageLimit }) => {
  return (
    <div className={className}>
      <p className="text-weight-bold text-x-small mb-2">Pod storage</p>
      <div className={cn(styles.bar, 'position-relative row no-gutters')}>
        <div
          className={cn(
            'rounded-5 w-100 bg-gray d-flex overflow-hidden',
            styles.gradientContainer,
            { [styles.errorContainer]: overLimit }
          )}
        >
          <div
            style={{ width: `${(currentData / storageLimit) * 100}%` }}
            className={cn('d-flex', { [styles.gradient]: !overLimit })}
          />
        </div>

        {pricingPlan === 'size' &&
          pricings.map(
            ({ storageLimitInBytes: limit, price, storageLimitInMegabytes: limitMB }, index) => (
              <div
                key={index}
                className={cn('col justify-content-between pt-1 pb-4 px-1 px-sm-2 d-flex', {
                  [styles.rightSeparator]: index + 1 !== pricings.length
                })}
              >
                <span
                  className={cn('text-x-small', {
                    'opacity-muted': !(
                      currentData < limit &&
                      (currentData > pricings[index - 1]?.storageLimitInBytes ||
                        !pricings[index - 1])
                    )
                  })}
                >{`$${price}`}</span>
                <span className="text-x-small opacity-muted">{`${limitMB}MB`}</span>
              </div>
            )
          )}

        {(pricingPlan === 'bundle' || pricingPlan === 'gift') && (
          <div className="col justify-content-between pt-1 pb-4 px-1 px-sm-2 d-flex">
            <span />
            <span className="text-x-small opacity-muted">{`${pricings.storageLimitInMegabytes /
              1000}GB`}</span>
          </div>
        )}
      </div>
    </div>
  );
};

PriceBar.propTypes = {
  className: string,
  currentData: number,
  pricings: arrayOf(
    shape({
      storageLimitInMegabytes: number.isRequired,
      price: number.isRequired,
      storageLimitInBytes: number
    })
  ).isRequired,
  overLimit: bool
};

PriceBar.defaultProps = {
  className: ''
};

export default PriceBar;
