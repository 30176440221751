import React from 'react';
import { number, string } from 'prop-types';

const TextFile = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8337 1.83325H5.50033C5.0141 1.83325 4.54778 2.02641 4.20396 2.37022C3.86015 2.71404 3.66699 3.18036 3.66699 3.66659V18.3333C3.66699 18.8195 3.86015 19.2858 4.20396 19.6296C4.54778 19.9734 5.0141 20.1666 5.50033 20.1666H16.5003C16.9866 20.1666 17.4529 19.9734 17.7967 19.6296C18.1405 19.2858 18.3337 18.8195 18.3337 18.3333V7.33325L12.8337 1.83325Z"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.833 1.83325V7.33325H18.333"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6663 11.9167H7.33301"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6663 15.5833H7.33301"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16634 8.25H8.24967H7.33301"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

TextFile.propTypes = {
  width: number,
  height: number,
  className: string
};

TextFile.defaultProps = {
  width: 23,
  height: 23
};

export default TextFile;
