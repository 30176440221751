import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { updatePod, actionTypes, updatePodReset } from 'actions/outgoingPodActions';

import { useForm, useDispatch, useValidation, useStatus } from 'hooks';
import { LOADING, SUCCESS } from 'constants/status';
import onlyTouched, { someTouched } from 'utils/onlyTouchedFields';

import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import BasicPodInfo from 'components/createPod/BasicInfoPodForm';
import OpenDateForm from 'components/createPod/OpenDateForm';
import Paper from 'components/common/Paper';
import Header from 'components/common/Header';
import Arrow from 'components/common/Icons/Arrow';
import { validations } from 'validations/updatePodValidations';

const UpdatePodPage = () => {
  const {
    state: {
      pod: { coverImage, id, ...pod }
    }
  } = useLocation();

  const { goBack } = useHistory();

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const validator = useValidation(validations);
  const update = useDispatch(updatePod);
  const reset = useDispatch(updatePodReset);
  const { status, error } = useStatus(actionTypes.UPDATE_POD);

  const { values, errors, handleValueChange, handleSubmit, blurredFields } = useForm(
    {
      initialValues: { coverImage: { data: coverImage }, ...pod },
      validator,
      validateOnBlur: true,
      onSubmit: values => {
        const onlyTouchedValues = onlyTouched(values, blurredFields);
        const valuesWithDate = {
          ...onlyTouchedValues,
          ...{ date: values.date },
          ...{ time: values.time }
        };
        update(id, 'time' in onlyTouchedValues ? valuesWithDate : onlyTouchedValues);
      }
    },
    []
  );

  const { title, description, date, time } = values;

  useEffect(() => {
    error && alert(error);
    status === SUCCESS && goBack();
  }, [error, goBack, reset, status]);

  useEffect(() => setSubmitDisabled(!someTouched(blurredFields)), [blurredFields]);

  useEffect(() => reset, [reset]);

  return (
    <div className="bg-gray min-vh-100">
      <Header />
      <div className="container py-4">
        <Paper className="h-100 col-lg-10 offset-lg-1 p-md-9">
          <div className="row">
            <div className="col-12 col-md d-flex flex-column">
              <div className="m-3 clickable" onClick={goBack}>
                <Arrow type="left" width={20} height={50} backArrow />
              </div>
              <div className="px-0 px-md-4 p-md-8">
                <div className="pb-4">
                  <h1 className="text-h2">Edit pod</h1>
                  <p className="text-gray pt-2 pt-md-6">
                    Feel free to enhance the basic information about your pod.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md mb-4 mb-md-0">
              <BasicPodInfo
                setSubmitDisabled={setSubmitDisabled}
                errors={errors}
                values={{ title, description, coverImage }}
                onChange={handleValueChange}
              />
              <div className="row">
                <OpenDateForm
                  onChange={handleValueChange}
                  values={{ date, time }}
                  errors={errors}
                />
              </div>
            </div>
          </div>
          <div className="row align-items-end justify-content-between">
            <div className="col-12 order-md-2 col-md-6">
              <Button disabled={submitDisabled} onClick={handleSubmit} className="py-3 col">
                {status === LOADING ? (
                  <Loader className="mt-n3 mb-5" />
                ) : (
                  <span className="text-h5 text-inverted">Save</span>
                )}
              </Button>
            </div>
            <div
              className="col-12 order-md-1 col-md-1 pb-5 pt-7 py-md-3 text-center"
              onClick={goBack}
            >
              <u className="canceButton">Back</u>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default UpdatePodPage;
