import React, { useCallback } from 'react';
import { object, func } from 'prop-types';
import cn from 'classnames';

import { useBreakpoint } from 'hooks';
import Avatar from 'components/user/Avatar';
import { lg, md } from 'styles/_constants.scss';
import { truncateString } from 'utils/helpers';

import styles from './Option.scss';

const Option = ({ data: { value }, onClick }) => {
  const { name, email, profileImage, id } = value;

  let maxLength;
  const isLg = useBreakpoint(lg);
  const isMd = useBreakpoint(md);
  if (isLg) {
    maxLength = 30;
  } else if (isMd) {
    maxLength = 20;
  } else {
    maxLength = 15;
  }

  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <div
      className={cn('text-alert py-3 w-100 d-flex clickable', styles.optionContainer)}
      onClick={handleClick}
    >
      <Avatar
        className="ml-2 p-auto flex-shrink-0"
        iconClassName="m-auto"
        small
        iconHeight={15}
        iconWidth={15}
        image={profileImage}
      />
      <div className="ml-4 d-flex flex-column justify-content-center">
        {name && <span className="text-small">{truncateString(name, maxLength)}</span>}
        <span className={cn({ 'text-x-small text-gray': name, 'text-small': !name })}>
          {truncateString(email, maxLength)}
        </span>
      </div>
    </div>
  );
};

Option.propTypes = {
  data: object.isRequired,
  onClick: func.isRequired
};

export default Option;
