import React, { memo } from 'react';
import cn from 'classnames';
import { array, func, number } from 'prop-types';

import MediaPreview from 'components/common/MediaPreview';
import Modal from 'components/common/Modal';
import Arrow from 'components/common/Icons/Arrow';
import { ReactComponent as Cancel } from 'assets/icons/big-cross.svg';

import useCarousel from './useCarousel';
import { next, prev, cancel, carousel } from './ButtonStyles.scss';

const Carousel = ({ files, onClose, initial }) => {
  const { slide, nextSlide, previousSlide } = useCarousel(files, initial);

  return (
    <Modal>
      <div className="h-100">
        <div className="h-100 position-relative">
          {previousSlide && (
            <div className={cn(prev, 'p-4 clickable')} onClick={previousSlide}>
              <Arrow height={25} width={25} colorType="light" type="left" />
            </div>
          )}

          {nextSlide && (
            <div className={cn(next, 'p-4 clickable')} onClick={nextSlide}>
              <Arrow height={25} width={25} colorType="light" type="right" />
            </div>
          )}

          <div className={cn(cancel, 'p-4 py-md-9 px-md-12 clickable')} onClick={onClose}>
            <Cancel />
          </div>

          <div className="h-100 w-100 w-md-75 mx-auto overflow-hidden">
            <div
              className={cn('h-100 w-100 nowrap', carousel)}
              style={{ transform: `translateX(-${slide}00%)` }}
            >
              {files.map(file => (
                <MediaPreview key={file.name} file={file} currentSlide={slide} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

Carousel.propTypes = {
  files: array.isRequired,
  onClose: func.isRequired,
  initial: number
};

Carousel.defaultProps = {
  initial: 0
};

export default memo(Carousel);
