import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import useDispatch from 'hooks/useDispatch';
import { logout } from 'actions/userActions';

import routes from 'constants/routesPaths';
import VersionDisclaimer from 'components/common/VersionDisclaimer';
import Logo from 'components/common/Icons/Logo';
import Plus from 'components/common/Icons/Plus';
import Button from 'components/common/LinkButton';
import DropDown from 'components/common/DropDown';
import UserInfoPreview from 'components/homepage/UserInfoPreview';

import { white } from 'styles/_constants.scss';
import { itemsCreator, messages, DROPDOWN_FIELDS } from './constants';

const HomeHeader = () => {
  const logoutRequest = useDispatch(logout);
  const onClickHandlers = {
    [DROPDOWN_FIELDS.logout]: logoutRequest
  };
  const items = itemsCreator(onClickHandlers);

  return (
    <>
      {process.env.VERSION_DISCLAIMER && <VersionDisclaimer />}
      <div className="container">
        <div className="row align-items-center pt-3 mb-md-9 mb-lg-6">
          <div className="col" />
          <div className="col-8 d-flex justify-content-center">
            <Link to={routes.index}>
              <Logo className="d-md-block clickable" width={190} height={44} />
            </Link>
          </div>
          <DropDown label={messages.myAccount} items={items} className="col" />
        </div>
        <div className="row px-7 px-md-0 align-items-center justify-content-between my-4 my-md-0 no-gutters">
          <a href="/" className="logo_click">
            {null}
          </a>
          <UserInfoPreview className="col-md-8" />
          <div className="col-md-3 mt-8 mt-md-0 pl-md-10">
            <Button
              type="link"
              styleType="primary"
              route={routes.createPod}
              className="col-12 mb-3 mb-md-0 py-4 createBtn"
            >
              <Plus color={white} height={26} width={26} className="my-auto mr-1" />
              <span className="text-h5 text-inverted">{messages.createPod}</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(HomeHeader);
