import { inputBorder, backgrounGray, white, darkBlue } from 'styles/_constants.scss';

export const stylesSelect = {
  control: base => ({
    ...base,
    'max-height': '3.2rem',
    'border-color': inputBorder,
    background: backgrounGray,
    boxShadow: '0px 0px 10px rgba(13, 109, 151, 0.2);',
    '&:hover': {
      'border-color': inputBorder
    },
    width: '14.4rem'
  }),
  valueContainer: base => ({ ...base, padding: '0 8px', 'max-height': 'inherit' }),
  indicatorSeparator: () => ({}),
  input: base => ({
    ...base,
    margin: 0,
    'max-height': '3.2rem',
    width: '100%',
    div: { 'max-height': 'inherit' },
    input: { 'min-height': 'inherit' }
  }),
  placeholder: base => ({ ...base, 'font-size': '14px' }),
  singleValue: base => ({
    ...base,
    'font-size': '14px',
    color: darkBlue,
    'font-weight': '500'
  }),
  option: base => ({
    ...base,
    'font-size': '14px',
    color: darkBlue,
    'font-weight': '500',
    background: white
  })
};
