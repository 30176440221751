import React from 'react';
import { string, number } from 'prop-types';
import { gray } from 'styles/_constants.scss';

const Boxes = ({ color, height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 90 72"
  >
    <path
      d="M87.75 31.5H67.5V2.25C67.5 1.125 66.375 0 65.25 0H24.75C23.4844 0 22.5 1.125 22.5 2.25V31.5H2.25C0.984375 31.5 0 32.625 0 33.75V69.75C0 71.0156 0.984375 72 2.25 72H87.75C88.875 72 90 71.0156 90 69.75V33.75C90 32.625 88.875 31.5 87.75 31.5ZM63 36H72V44.8594L67.5 43.3125L63 44.8594V36ZM49.5 4.5V13.3594L45 11.8125L40.5 13.3594V4.5H49.5ZM27 4.5H36V19.5469L45 16.5938L54 19.5469V4.5H63V31.5H27V4.5ZM27 36V44.8594L22.5 43.3125L18 44.8594V36H27ZM4.5 36H13.5V51.0469L22.5 48.0938L31.5 51.0469V36H42.75V67.5H4.5V36ZM85.5 67.5H47.25V36H58.5V51.0469L67.5 48.0938L76.5 51.0469V36H85.5V67.5Z"
      fill={color}
    />
  </svg>
);

Boxes.propTypes = {
  color: string,
  width: number,
  height: number
};

Boxes.defaultProps = {
  color: gray,
  width: 24,
  height: 24
};

export default Boxes;
