import React from 'react';
import { string, number } from 'prop-types';

import { primaryBlue } from 'styles/_constants.scss';

const Plus = ({ color, height, width, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 5v14M5 12h14"
    />
  </svg>
);

Plus.propTypes = {
  color: string,
  height: number,
  width: number
};

Plus.defaultProps = {
  color: primaryBlue,
  height: 24,
  width: 24
};

export default Plus;
