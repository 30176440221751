import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { object } from 'prop-types';
import cn from 'classnames';
import moment from 'moment';

import { ReactComponent as AlertCircle } from 'assets/icons/alert-circle.svg';
import Button from 'components/common/Button';
import Arrow from 'components/common/Icons/Arrow';
import Paper from 'components/common/Paper';
import PodDescription from 'components/pods/PodDescription';

import PodFiles from 'components/pods/PodFiles';
import PodWrapper from 'components/pods/PodWrapper';
import PodExpiration from 'components/pods/PodExpiration';
import GetExtraTime from 'components/pods/GetExtraTime';

import { DATE_FORMAT } from 'constants/constants';

import { INDEX, NUM_STEPS } from './constants';

const IncomingPod = ({
  pod: {
    coverImage,
    title,
    userName,
    openingDate,
    locked,
    description,
    files,
    id,
    expirationDate,
    size
  }
}) => {
  const [currentStep, setStep] = useState(0);
  const { goBack } = useHistory();

  const handleNext = useCallback(() => {
    if (currentStep < NUM_STEPS - 1) setStep(currentStep + 1);
  }, [currentStep]);
  const handleBack = useCallback(() => {
    currentStep === INDEX.WRAPPER ? goBack() : setStep(currentStep - 1);
  }, [currentStep, goBack]);

  const openAt = moment(openingDate);
  const date = openAt.format(DATE_FORMAT);

  const expiration = moment(expirationDate).format(DATE_FORMAT);

  const backIcon = (
    <div className={currentStep === INDEX.WRAPPER ? 'black-shadow-arrow' : ''}>
      <Arrow
        className="clickable"
        colorType={currentStep === INDEX.WRAPPER ? 'light' : 'dark'}
        type="left"
        width={20}
        height={50}
        onClick={handleBack}
        backArrow
      />
    </div>
  );

  return (
    <Paper className="col-lg-10 offset-lg-1 pt-4 pb-7 pb-md-9 px-8 d-flex flex-column flex-grow-1 flex-md-grow-0">
      <div className={cn('row justify-content-center', 'mb-7 mb-md-10')}>
        {currentStep === INDEX.WRAPPER && (
          <PodWrapper
            pod={{ coverImage, title, userName, openingDate, locked }}
            backIcon={backIcon}
          />
        )}
        {currentStep === INDEX.DESCRIPTION && (
          <PodDescription description={description} backIcon={backIcon} />
        )}
        {currentStep === INDEX.FILES && <PodFiles files={files} backIcon={backIcon} />}
        {currentStep === INDEX.EXPIRATION_DATE && (
          <PodExpiration
            expirationDate={expiration}
            podId={id}
            backIcon={backIcon}
            handleNext={handleNext}
          />
        )}
        {currentStep === INDEX.EXTRA_TIME && (
          <GetExtraTime id={id} podSize={size} handleBack={handleBack} goBack={goBack} />
        )}
      </div>
      {currentStep !== INDEX.EXTRA_TIME &&
        (currentStep !== INDEX.EXPIRATION_DATE ? (
          <div className="row position-relative justify-content-center mt-auto">
            <div
              className={cn('mt-7 mt-md-5 pt-md-0 order-2 order-md-1 col-12 position-md-absolute')}
            >
              <a
                className="text-small text-underline d-block text-center text-md-left"
                onClick={goBack}
              >
                Close
              </a>
            </div>
            <Button
              disabled={locked}
              onClick={handleNext}
              type="submit"
              styleType="primary"
              className="col col-md-4 py-3"
            >
              {locked ? (
                <>
                  <span className="d-none d-md-inline text-inverted text-h5">Sealed Pod</span>
                  <div className="d-inline d-md-none">
                    <div className="d-flex justify-content-center mt-md-4 mx-auto">
                      <span className="text-h5 text-inverted text-center mr-2">Open on:</span>
                      <span className="text-h5 text-weight-bold text-inverted text-center">
                        {date}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex m-auto justify-content-center">
                  <span className="text-inverted text-h5 my-auto">Next</span>
                  <Arrow className="my-auto" type="right" height={30} colorType="light" />
                </div>
              )}
            </Button>
          </div>
        ) : (
          <a
            className="text-small text-underline d-block text-center text-md-center"
            onClick={goBack}
          >
            Close
          </a>
        ))}
      {locked && (
        <div className="row d-flex justify-content-center pt-6 pt-sm-9">
          <AlertCircle className="my-auto" />
          <span className="text-x-small text-gray text-center my-auto pl-1 pt-2 pt-sm-0">
            The sender can cancel or change the open date.
          </span>
        </div>
      )}
    </Paper>
  );
};

IncomingPod.propTypes = {
  pod: object.isRequired
};

export default IncomingPod;
