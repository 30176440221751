import React, { useRef, useEffect } from 'react';
import cn from 'classnames';

import { string, object, number } from 'prop-types';

const VideoPreview = ({ file: { playUrl, type }, className, currentSlide }) => {
  const videoRef = useRef();

  useEffect(() => {
    if (!videoRef.current.paused) {
      videoRef.current.pause();
    }
  }, [currentSlide]);

  return (
    <div className={cn('w-100 h-100 d-flex justify-content-center align-items-start', className)}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={videoRef}
        controls
        className={cn('mh-100 mw-100 position-absolute absolute-fill my-auto mx-auto', className)}
      >
        <source src={playUrl} type={type} />
      </video>
    </div>
  );
};

VideoPreview.propTypes = {
  className: string,
  file: object.isRequired,
  currentSlide: number.isRequired
};

VideoPreview.defaultProps = {
  className: ''
};

export default VideoPreview;
