import React from 'react';
import { string, bool, number } from 'prop-types';
import cn from 'classnames';

import User from 'components/common/Icons/User';
import Loader from 'components/common/Loader';

import styles from './Avatar.scss';

const Avatar = ({ image, className, iconClassName, small, iconWidth, iconHeight, loading }) => {
  return (
    <div
      className={cn(
        small ? styles.smallElipse : styles.elipse,
        'd-flex justify-content-center overflow-hidden',
        className
      )}
      style={{ backgroundImage: !loading && `url(${image})` }}
    >
      {loading && <Loader type="blue" loaderClassName={styles.loader} />}
      {!loading && !image && (
        <User className={iconClassName} width={iconWidth} height={iconHeight} />
      )}
    </div>
  );
};

Avatar.propTypes = {
  image: string,
  className: string,
  iconClassName: string,
  small: bool,
  iconHeight: number,
  iconWidth: number,
  loading: bool
};

Avatar.defaultProps = {
  image: '',
  className: '',
  iconClassName: '',
  small: false,
  loading: false
};

export default Avatar;
