import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { string, node, bool } from 'prop-types';

import styles from './styles.scss';

const Button = ({ children, label, className, route, inverted }) => (
  <Link
    className={cn(
      { [styles.button]: !inverted, [styles.inverted]: inverted },
      'py-3 px-6 d-flex justify-content-center',
      className
    )}
    to={route}
  >
    {children || (
      <span
        className={cn(
          { 'text-inverted': !inverted, [styles.invertedText]: inverted },
          'text-weight-semibold'
        )}
      >
        {label}
      </span>
    )}
  </Link>
);

Button.propTypes = {
  route: string.isRequired,
  className: string,
  children: node,
  label: string,
  inverted: bool
};

Button.defaultProps = {
  children: null,
  className: '',
  inverted: false
};

export default memo(Button);
