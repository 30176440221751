import { useCallback } from 'react';

export default (handleValueChange, handleBlur, values) =>
  useCallback(
    fieldKey => ({
      id: fieldKey,
      value: values[fieldKey] || '',
      onChange: e => handleValueChange(fieldKey, e.target.value),
      onBlur: () => handleBlur(fieldKey)
    }),
    [handleBlur, handleValueChange, values]
  );
