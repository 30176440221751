import React from 'react';
import { string, node, bool, object, oneOfType, func } from 'prop-types';
import { ERROR, LOADING } from 'constants/status';
import { LIST_ERROR_MESSAGE } from 'constants/constants';
import Loader from 'components/common/Loader';
import styles from './GeneralDataHandler.scss';

const GeneralDataHandler = ({ status, SuccessComponent, bypass, successComponentProps }) => {
  if (status === ERROR) {
    return (
      <div className={styles.errorMessage}>
        <p>{LIST_ERROR_MESSAGE}</p>
      </div>
    );
  }

  if (status === LOADING && !bypass) {
    return <Loader type="blue" />;
  }

  return <SuccessComponent {...successComponentProps} />;
};

GeneralDataHandler.propTypes = {
  status: string,
  SuccessComponent: oneOfType([node, func]).isRequired,
  bypass: bool,
  successComponentProps: object
};

GeneralDataHandler.defaultProps = {
  bypass: false
};

export default GeneralDataHandler;
