import { CREATEPOD_FIELDS } from 'constants/fields';

export const validations = {
  [CREATEPOD_FIELDS.title]: {
    presence: true
  },
  [CREATEPOD_FIELDS.description]: {
    presence: true
  }
};
