import React, { memo } from 'react';
import { array, func } from 'prop-types';
import cn from 'classnames';

import { mimeTypeToType, bytesToMBytes, getMinimumSizeMB } from 'utils/filesHelpers';
import splitFileName from 'utils/splitFileName';

import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import ImageIcon from 'components/common/Icons/ImageFile';
import VideoIcon from 'components/common/Icons/VideoFile';
import AudioIcon from 'components/common/Icons/AudioFile';
import TextIcon from 'components/common/Icons/TextFile';
import DefaultIcon from 'components/common/Icons/DefaultFile';

import { POD_FILES_TYPES } from 'constants/constants';

import styles from './PodFilesList.scss';

const Icon = ({ mimeType, className }) => {
  const type = mimeTypeToType(mimeType);
  switch (type && type[0]) {
    case POD_FILES_TYPES.IMAGE:
      return <ImageIcon className={className} />;
    case POD_FILES_TYPES.VIDEO:
      return <VideoIcon className={className} />;
    case POD_FILES_TYPES.AUDIO:
      return <AudioIcon className={className} />;
    case POD_FILES_TYPES.TEXT:
    case POD_FILES_TYPES.APPLICATION:
      return <TextIcon className={className} />;
    default:
      return <DefaultIcon className={className} />;
  }
};

const FilesList = ({ list, removeFile }) =>
  list.map(({ type, name, size }, index) => {
    const [beginning, ending] = splitFileName(name);
    return (
      <div
        key={`${name}_${index}`}
        className={cn([
          styles.fileCard,
          'bg-gray py-3 px-3 px-md-5 mb-3 d-flex justify-content-start align-items-center rounded-5 opacity-hinted'
        ])}
      >
        <Icon mimeType={type} className="flex-shrink-0" />
        <span
          style={{ maxWidth: '40rem' }}
          className="nowrap pl-2 pl-md-5 overflow-hidden text-ellipsis flex-shrink-1"
        >
          {beginning}
        </span>
        <span className="flex-shrink-0">{ending}</span>
        <span className="text-gray px-2">·</span>
        <p className="pr-2 text-gray">{getMinimumSizeMB(bytesToMBytes(size))}MB</p>
        {removeFile && (
          <Cancel
            className="ml-auto flex-shrink-0 clickable"
            onClick={() => removeFile(index, size)}
          />
        )}
      </div>
    );
  });

FilesList.propTypes = {
  list: array.isRequired,
  removeFile: func
};

export default memo(FilesList);
