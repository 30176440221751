import React from 'react';
import { string, node } from 'prop-types';
import cn from 'classnames';

import styles from './Paper.scss';

const Paper = ({ children, className, ...props }) => (
  <div className={cn([styles.boxShadow, 'bg-white', { [className]: !!className }])} {...props}>
    {children}
  </div>
);

Paper.propTypes = {
  children: node.isRequired,
  className: string
};

export default Paper;
