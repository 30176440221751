export const getHours = () => {
  const list = [];
  for (let i = 1; i < 13; i += 1) {
    list.push(i < 10 ? `0${i}` : `${i}`);
  }
  return list;
};

export const getMinutesInterval = interval => {
  const list = [];
  for (let i = 0; i < 60; i += interval) {
    list.push(i < 10 ? `0${i}` : `${i}`);
  }
  return list;
};
