import React, { useState, useCallback } from 'react';
import { func } from 'prop-types';
import Select from 'react-select';
import { SORT_PODS_CRITRIA, CREATED_AT_FILTER, OPENING_DATE_FILTER } from 'constants/constants';

import { stylesSelect } from '../SortSelect/styles';

const SortSelect = ({ handleChange, ...props }) => {
  const options = [
    { value: CREATED_AT_FILTER, label: SORT_PODS_CRITRIA.DATE_SENT },
    { value: OPENING_DATE_FILTER, label: SORT_PODS_CRITRIA.OPENING_DATE }
  ];

  const [sortSelected, setSort] = useState(options[0]);

  const onChange = useCallback(
    newValue => {
      setSort(newValue);
      handleChange(newValue);
    },
    [handleChange]
  );

  return (
    <Select
      styles={stylesSelect}
      onChange={onChange}
      value={sortSelected}
      options={options}
      isSearchable={false}
      {...props}
    />
  );
};

SortSelect.propTypes = {
  handleChange: func.isRequired
};

export default SortSelect;
