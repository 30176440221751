import { SIGNUP_FIELDS } from 'constants/fields';

export const messages = {
  submit: 'Next',
  [SIGNUP_FIELDS.email]: 'Email',
  [SIGNUP_FIELDS.name]: 'Name',
  [SIGNUP_FIELDS.password]: 'Password',
  [SIGNUP_FIELDS.passwordConfirmation]: 'Confirm Password',
  [SIGNUP_FIELDS.telephone]: 'Phone number',
  termsMessage: 'I accept the ',
  termsLink: 'Terms and Conditions',
  privacyLink: 'Privacy policy',
  [SIGNUP_FIELDS.referral]: 'Referral Code'
};
