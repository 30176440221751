import React from 'react';

import { darkRed, white } from 'styles/_constants.scss';

const VersionDisclaimer = () => (
  <p
    className="d-flex justify-content-center align-items-center text-h5 text-weight-semibold text-center col-12 py-2 px-3"
    style={{ color: white, backgroundColor: darkRed }}
  >
    {process.env.VERSION_DISCLAIMER}
  </p>
);

export default VersionDisclaimer;
