import React, { useEffect, createContext } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';

import { SUCCESS, ERROR } from 'constants/status';
import routes from 'constants/routesPaths';

import { useDispatch, useStatus, useAuth } from 'hooks';
import { replaceLandingTab, existsTab } from 'utils/tabHelpers';

import { signUpClearStatus, loginClearStatus, actionTypes } from 'actions/userActions';

import useHandlers from './useHandlers';
import DefaultLanding from './DefaultLanding';
import { LABELS } from './tabs';

export const LandingContext = createContext();

const SignUpPage = () => {
  const { state } = useLocation();
  const { handleTabChange, handleLogin, handleSignup } = useHandlers();

  const { tab } = useParams();
  const { status, error } = useStatus(actionTypes.SIGN_UP);
  const authenticated = useAuth();

  const clearSignUpStatusRequest = useDispatch(signUpClearStatus);
  const clearLoginStatusRequest = useDispatch(loginClearStatus);
  useEffect(() => {
    status === ERROR && alert(error);
  }, [error, status]);

  useEffect(() => clearSignUpStatusRequest, [clearSignUpStatusRequest]);

  useEffect(() => clearLoginStatusRequest, [clearLoginStatusRequest]);

  if (tab != LABELS.login && !localStorage.getItem('pod_id')) {
    return <Redirect to={routes.createPod} />;
  }

  if (!existsTab(tab, LABELS)) {
    return <Redirect to={replaceLandingTab(LABELS.signup)} />;
  }

  if (status === SUCCESS) {
    return <Redirect to={routes.verifyEmail} />;
  }

  if (authenticated) {
    return localStorage.getItem('pod_id') ? (
      <Redirect to={routes.createPod} />
    ) : (
      <Redirect to={state ? state.from.pathname : routes.index} />
    );
  }

  return (
    <LandingContext.Provider value={{ handleTabChange, handleLogin, handleSignup, tab }}>
      <DefaultLanding />
    </LandingContext.Provider>
  );
};

export default SignUpPage;
