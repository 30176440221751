import React, { memo, useCallback, useEffect } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { useDispatchOnMount, useStatus, useBreakpoint } from 'hooks';

import { getProfile, actionTypes } from 'actions/userActions';
import { SUCCESS } from 'constants/status';

import routes from 'constants/routesPaths';
import TabNav from 'components/common/TabNav';
import HomeHeader from 'components/homepage/HomeHeader';
import Timeline from 'components/homepage/Timeline';
import OutgoingPods from 'components/pods/OutgoingPods';
import IncomingPods from 'components/pods/IncomingPods';
import GiftPods from 'components/pods/GiftPods';
import MyPods from 'components/pods/MyPods';
import ContactList from 'components/contacts/ContactList';

import { replaceHomeTab, existsTab } from 'utils/tabHelpers';

import { md } from 'styles/_constants.scss';
import { TABS, LABELS } from './constants';

const HomePage = () => {
  useDispatchOnMount(getProfile);
  const { tab = LABELS.timeLine } = useParams();
  const { push } = useHistory();

  const handleChange = useCallback(tab => push(replaceHomeTab(tab)), [push]);
  const { status } = useStatus(actionTypes.SIGN_UP);
  const uploadProgress = useSelector(({ pods }) => pods.uploadProgress);

  const isDesktop = useBreakpoint(md);

  useEffect(() => {
    if (uploadProgress) {
      window.onbeforeunload = () => true;
    }
  }, [uploadProgress]);

  useEffect(() => {
    if (!uploadProgress) {
      window.onbeforeunload = null;
    }
  }, [uploadProgress]);

  if (status === SUCCESS) {
    return <Redirect to={routes.verifyEmail} />;
  }

  if (!existsTab(tab, LABELS)) {
    return <Redirect to={replaceHomeTab(LABELS.timeLine)} />;
  }

  return (
    <div className="d-flex flex-column min-vh-100">
      <HomeHeader />
      <div className="container mt-4">
        <TabNav
          tabs={TABS}
          tabClass="pb-2 col flex-column flex-md-row align-items-center"
          labelClass={cn({ 'text-small text-center': !isDesktop })}
          focused={tab}
          handleChange={handleChange}
        />
      </div>
      <div className="flex-fill bg-gray d-flex flex-column">
        <div className="container mt-5 flex-fill d-flex flex-column">
          {tab === LABELS.timeLine && <Timeline />}
          {tab === LABELS.outgoing && <OutgoingPods />}
          {tab === LABELS.incoming && <IncomingPods />}
          {tab === LABELS.gift && <GiftPods />}
          {tab === LABELS.mypods && <MyPods />}
          {tab === LABELS.contacts && <ContactList />}
        </div>
      </div>
    </div>
  );
};

export default memo(HomePage);
