import React, { memo, useCallback, useEffect } from 'react';
import { string } from 'prop-types';
import cn from 'classnames';

import { useSession, useDispatch, useToggle, useStatus, useInputValue } from 'hooks';
import { ERROR } from 'constants/status';

import { ReactComponent as CheckIcon } from 'assets/icons/big-check.svg';
import { update, actionTypes } from 'actions/userActions';
import Edit from 'components/common/Icons/Edit';
import ProfilePicture from 'components/homepage/ProfilePicture';
import styles from './UserInfo.scss';

const UserInfoPreview = ({ className }) => {
  const { error, status } = useStatus(actionTypes.UPDATE);

  useEffect(() => {
    status === ERROR && alert(error);
  }, [error, status]);

  const updateUser = useDispatch(update);
  const {
    user: { name: username, email }
  } = useSession();

  const [name, onChange] = useInputValue(username);
  const [editing, toggle] = useToggle(false);

  const handleNameChange = useCallback(() => {
    if (!name) alert('Name can not be empty.');
    else {
      toggle();
      updateUser({ name });
    }
  }, [name, toggle, updateUser]);

  const handleAvatarChange = useCallback(
    avatar => {
      updateUser({
        profileImage: {
          data: avatar
        }
      });
    },
    [updateUser]
  );

  const handleEnter = useCallback(({ key }) => key === 'Enter' && handleNameChange(), [
    handleNameChange
  ]);

  return (
    <div className={cn('d-flex', className)}>
      <ProfilePicture onChangeAvatar={handleAvatarChange} updateStatus={status} />
      <div className="ml-5 mt-4 ml-md-8 my-md-auto min-width-0">
        <div className="d-flex flex-column min-width-0">
          <div
            className={cn(
              'd-flex align-items-center',
              styles.nameContainer,
              { 'justify-content-between': editing },
              {
                [styles.field]: editing
              }
            )}
          >
            {editing ? (
              <>
                <input
                  className={cn(styles.input, 'text-weight-bold text-h4')}
                  value={name}
                  onChange={onChange}
                  onKeyDown={handleEnter}
                />
                <CheckIcon onClick={handleNameChange} className="mr-1" />
              </>
            ) : (
              <>
                <span className={cn('text-weight-bold text-ellipsis', styles.name)}>{name}</span>
                <Edit onClick={toggle} className="ml-3" />
              </>
            )}
          </div>

          <p className={cn('text-gray my-1 mt-6 overflow-wrap', styles.email)}>{email}</p>
        </div>
      </div>
    </div>
  );
};

UserInfoPreview.propTypes = {
  className: string
};

export default memo(UserInfoPreview);
