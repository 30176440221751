import React from 'react';
import { object, node } from 'prop-types';
import cn from 'classnames';
import moment from 'moment';

import { useBreakpoint } from 'hooks';

import defaultImage from 'assets/images/default-image-big.svg';
import Lock from 'components/common/Icons/Lock';
import { DATE_FORMAT, TIME_FORMATS } from 'constants/constants';
import { backgroundGradient, blackGradient, md } from 'styles/_constants.scss';
import { customBackground } from 'utils/helpers';

const PodWrapper = ({ pod: { coverImage, title, userName, openingDate, locked }, backIcon }) => {
  const openAt = moment(openingDate);
  const time = openAt.format(`[at] ${TIME_FORMATS.TWELVE_SINGLE_POINT}`);
  const date = openAt.format(DATE_FORMAT);

  const isDesktop = useBreakpoint(md);

  const renderTitle = () => (
    <div
      className={cn('my-md-auto d-flex flex-column mt-9 mb-auto', {
        'mx-auto': !isDesktop
      })}
    >
      <span
        className={cn('text-h2 text-weight-semibold text-center my-auto overflow-wrap', {
          'text-inverted': isDesktop
        })}
      >
        {title}
      </span>
      <div className="mt-4 mx-auto mb-md-3 text-center">
        <span className={cn('text-h5 text-center mr-2', { 'text-inverted': isDesktop })}>
          From:
        </span>
        <span
          className={cn('text-h5 text-weight-bold text-center', { 'text-inverted': isDesktop })}
        >
          {userName}
        </span>
      </div>
    </div>
  );

  return (
    <>
      <div
        style={customBackground(
          coverImage || defaultImage,
          locked && blackGradient,
          'center',
          coverImage ? 'cover' : 'contain',
          !locked && backgroundGradient
        )}
        className={cn('col-12 sixteen-nine d-flex flex-column rounded-5')}
      >
        <div className="d-flex col-12 flex-column aspect-ratio-box-inside h-100">
          {backIcon}
          {locked && (
            <Lock className="mb-5 mx-auto mt-md-9" width={116} height={116} strokeWidth={1} />
          )}
          {isDesktop && renderTitle('white')}
          {locked && (
            <div className="d-none d-md-inline mb-md-12">
              <div className="d-flex justify-content-center mt-md-4 mx-auto">
                <span className={cn('text-h5 text-center text-inverted mr-2')}>Open on:</span>
                <span className="text-h5 text-weight-bold text-inverted text-center">{date}</span>
                <span className="text-h5 text-weight-bold text-inverted text-center d-none d-md-inline ml-2">
                  {time}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {!isDesktop && renderTitle()}
    </>
  );
};

PodWrapper.propTypes = {
  pod: object.isRequired,
  backIcon: node
};

export default PodWrapper;
