import React from 'react';
import { string, number } from 'prop-types';
import { gray } from 'styles/_constants.scss';

const Home = ({ color, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      view-box="0 0 27 24"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3.5 10.5L15 2l11 8.5M6 8.652V21a2 2 0 002 2h14a2 2 0 002-1.999V12"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22V12h6v10"
      />
      <path fill="#fff" d="M15 13H17V22H15z" />
    </svg>
  );
};

Home.propTypes = {
  color: string,
  width: number,
  height: number
};

Home.defaultProps = {
  color: gray,
  width: 27,
  height: 24
};

export default Home;
