import createAction from 'actions/createAction';
import contactsService from 'services/contactsService';
import parseError from 'utils/parseError';

export const actionTypes = {
  GET_CONTACTS: 'GET_CONTACTS',
  GET_CONTACTS_REQUEST: 'GET_CONTACTS_REQUEST',
  GET_CONTACTS_SUCCESS: 'GET_CONTACTS_SUCCESS',
  GET_CONTACTS_ERROR: 'GET_CONTACTS_ERROR',

  REMOVE_CONTACT: 'REMOVE_CONTACT',
  REMOVE_CONTACT_REQUEST: 'REMOVE_CONTACT_REQUEST',
  REMOVE_CONTACT_SUCCESS: 'REMOVE_CONTACT_SUCCESS',
  REMOVE_CONTACT_ERROR: 'REMOVE_CONTACT_ERROR',
  REMOVE_CONTACT_RESET: 'REMOVE_CONTACT_RESET'
};

const getContactsRequest = createAction(actionTypes.GET_CONTACTS_REQUEST);
const getContactsSuccess = createAction(actionTypes.GET_CONTACTS_SUCCESS);
const getContactsError = createAction(actionTypes.GET_CONTACTS_ERROR);

const removeContactRequest = createAction(actionTypes.REMOVE_CONTACT_REQUEST);
const removeContactSuccess = createAction(actionTypes.REMOVE_CONTACT_SUCCESS);
const removeContactError = createAction(actionTypes.REMOVE_CONTACT_ERROR);
export const removeContactStatusReset = createAction(actionTypes.REMOVE_CONTACT_RESET);

export const getContacts = () => async dispatch => {
  dispatch(getContactsRequest());
  try {
    const { data } = await contactsService.getContacts();
    dispatch(getContactsSuccess(data));
  } catch ({ data }) {
    dispatch(getContactsError(parseError(data)));
  }
};

export const removeContact = ({ id }) => async dispatch => {
  dispatch(removeContactRequest());
  try {
    await contactsService.removeContact(id);
    dispatch(removeContactSuccess());
    dispatch(getContacts());
  } catch ({ data }) {
    dispatch(removeContactError(parseError(data)));
  }
};
