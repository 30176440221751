import api from 'api';
import { applyQueryParams } from 'utils/helpers';

class EventsService {
  static getTimeline({ page = 1, perPage }) {
    return api.get(applyQueryParams('/events', { page, perPage }));
  }
}

export default EventsService;
