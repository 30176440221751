export const SIGNUP_FIELDS = {
  name: 'name',
  email: 'email',
  terms: 'termsOfService',
  telephone: 'telephone',
  referral: 'referral',
  password: 'password',
  passwordConfirmation: 'passwordConfirmation'
};

export const LOGIN_FIELDS = {
  email: 'email',
  password: 'password'
};

export const CREATEPOD_FIELDS = {
  bundlePod: 'bundlePod',
  pricingPlan: 'pricingPlan',
  date: 'date',
  description: 'description',
  email: 'email',
  emailLabel: 'email.label',
  recipients: 'recipients',
  time: 'time',
  hour: 'hour',
  minutes: 'minutes',
  period: 'period',
  title: 'title',
  timezone: 'timezone',
  coverImage: 'coverImage',
  files: 'files',
  checkViewableDays: 'checkViewableDays',
  checkNoRefunds: 'checkNoRefunds',
  checkPodAccepted: 'checkPodAccepted',
  promoCode: 'promoCode'
};

export const PRICING_FIELDS = {
  bundlePod: 'bundlePod'
};

export const CREATEGIFT_FIELDS = {
  userPodId: 'userPodId',
  podCount: 'podCount',
  senderName: 'senderName',
  recipientName: 'recipientName',
  recipientEmail: 'recipientEmail',
  message: 'message'
};
