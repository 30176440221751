import React from 'react';
import { string, number } from 'prop-types';
import { gray } from 'styles/_constants.scss';

const DownloadCloud = ({ color, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      view-box="0 0 24 24"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0)"
      >
        <path d="M8 17l4 4 4-4M12 12v9" />
        <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

DownloadCloud.propTypes = {
  color: string,
  width: number,
  height: number
};

DownloadCloud.defaultProps = {
  color: gray,
  width: 24,
  height: 24
};

export default DownloadCloud;
