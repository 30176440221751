import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import { string, object, number } from 'prop-types';

const AudioPreview = ({ file: { playUrl, type }, className, currentSlide }) => {
  const audioRef = useRef();

  useEffect(() => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
    }
  }, [currentSlide]);

  return (
    <div className={cn('w-100 h-100 d-flex justify-content-center align-items-start', className)}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={audioRef}
        controls
        className={cn('mh-100 mw-100 position-absolute absolute-fill my-auto mx-auto', className)}
      >
        <source src={playUrl} type={type} />
      </audio>
    </div>
  );
};

AudioPreview.propTypes = {
  className: string,
  file: object.isRequired,
  currentSlide: number.isRequired
};

AudioPreview.defaultProps = {
  className: ''
};

export default AudioPreview;
