import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { useDispatchOnMount, useStatus, useDispatch } from 'hooks';

import {
  getGuestIncomingPod,
  acceptGuestPod,
  rejectGuestPod,
  acceptPodClearStatus,
  rejectPodClearStatus,
  actionTypes
} from 'actions/incomingPodActions';
import Button from 'components/common/Button';
import EmptyState from 'components/common/EmptyState';
import defaultImage from 'assets/images/default-image-big.svg';
import { customBackground } from 'utils/helpers';
import { blurredBlackGradient } from 'styles/_constants.scss';
import Header from 'components/common/Header';
import IncomingPod from 'components/pods/IncomingPod';
import GeneralDataHandler from 'components/common/GeneralDataHandler';
import { ERROR, LOADING } from 'constants/status';
import { ERROR_MESSAGE } from 'constants/constants';
import styles from 'components/pods/IncomingPendingPodItems/IncomingPendingPodItems.scss';
import { WARNING_REJECT_MESSAGE } from 'components/pods/IncomingPendingPodItems/constants';

const OpenPodPublicPage = () => {
  const { podId, token: accessToken } = useParams();
  // concat podid and accesstoken to prevent infinite component render loop with object args
  useDispatchOnMount(getGuestIncomingPod, `${podId}-${accessToken}`);

  const pod = useSelector(state => state.pods.incomingPod) || {};

  const { status } = useStatus(actionTypes.GET_INCOMING_POD);

  const acceptGuestPodRequest = useDispatch(acceptGuestPod);
  const rejectGuestPodRequest = useDispatch(rejectGuestPod);

  const acceptClearStatus = useDispatch(acceptPodClearStatus);
  const rejectClearStatus = useDispatch(rejectPodClearStatus);

  const statusAccept = useStatus(actionTypes.ACCEPT_POD);
  const statusReject = useStatus(actionTypes.REJECT_POD);
  const warningMessage = useCallback(() => {
    if (window.confirm(WARNING_REJECT_MESSAGE)) {
      rejectGuestPodRequest(podId, accessToken);
    }
  }, [rejectGuestPodRequest]);

  if (statusAccept.status === ERROR) {
    window.alert(ERROR_MESSAGE);
    acceptClearStatus();
  }
  if (statusReject.status === ERROR) {
    window.alert(ERROR_MESSAGE);
    rejectClearStatus();
  }

  return (
    <div className="bg-gray min-vh-100 position-relative d-flex flex-column">
      <Header />

      {pod.state === 'pending' && (
        <div key={podId} className="container py-4 d-flex justify-content-center">
          <div
            style={
              pod.coverImage
                ? customBackground(pod.coverImage, blurredBlackGradient, 'center top', 'contain')
                : customBackground(defaultImage, blurredBlackGradient, 'center')
            }
            className={styles.podItemIncoming}
          >
            <p className="text-inverted text-weight-medium text-center">
              {pod.userName} wants to share a Pod with you
            </p>
            <Button
              type="button"
              disabled={statusAccept.status === LOADING}
              styleType="primary"
              className="text-small my-4 py-3 px-6"
              onClick={() => {
                acceptGuestPodRequest(podId, accessToken);
              }}
            >
              Accept Pod
            </Button>
            <Link to="" className="text-inverted text-small" onClick={warningMessage}>
              Reject Pod
            </Link>
          </div>
        </div>
      )}

      {pod.state === 'accepted' && (
        <div className="container py-4 flex-grow-1 d-flex flex-column">
          <GeneralDataHandler
            status={status}
            SuccessComponent={IncomingPod}
            successComponentProps={{ pod }}
          />
        </div>
      )}

      {pod.state === 'rejected' && (
        <EmptyState
          className="mt-9 mt-md-12"
          title="You have rejected this pod."
          subtitle="Therefore you will not be able to see the pod content."
        />
      )}
    </div>
  );
};

export default OpenPodPublicPage;
