import api from 'api';

class giftsService {
  static getGifts() {
    return api.get('/gifts/new');
  }
  static createGift(data) {
    return api.post('/gifts', data);
  }
}

export default giftsService;
