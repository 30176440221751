import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { string } from 'prop-types';

import Logo from 'components/common/Icons/Logo';
import routes from 'constants/routesPaths';

const Header = ({ confirm }) => {
  const { push } = useHistory();

  const onClick = () => push(routes.index);
  const confirmClick = () => window.confirm(confirm) && onClick();

  return (
    <div className="bg-white pt-2 pb-3">
      <a onClick={confirm ? confirmClick : onClick}>
        <Logo className="d-block m-auto clickable" width={190} height={44} />
      </a>
    </div>
  );
};

Header.propTypes = {
  confirm: string
};

export default memo(Header);
