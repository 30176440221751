import React, { memo } from 'react';
import { func, string, node, object } from 'prop-types';
import cn from 'classnames';

import styles from './Field.scss';

const StripeField = ({ onChange, StripeComponent, label, placeholder, error }) => (
  <div className="mt-4">
    <label className="text-x-small text-weight-semibold d-block mb-2">{label}</label>
    <div className={cn(styles.field, { [styles.error]: error }, 'mb-4 d-flex align-items-center')}>
      <StripeComponent onChange={onChange} placeholder={placeholder} className="col-12" />
    </div>
    {error && (
      <p className={cn(styles.errorText, 'text-x-small text-alert text-weight-semibold mt-1 mb-4')}>
        {error.message}
      </p>
    )}
  </div>
);

StripeField.propTypes = {
  onChange: func.isRequired,
  StripeComponent: node.isRequired,
  label: string.isRequired,
  placeholder: string,
  error: object
};

export default memo(StripeField);
