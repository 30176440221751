import React from 'react';
import { number, string } from 'prop-types';

const ImageFile = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2083 2.875H4.79167C3.73312 2.875 2.875 3.73312 2.875 4.79167V18.2083C2.875 19.2669 3.73312 20.125 4.79167 20.125H18.2083C19.2669 20.125 20.125 19.2669 20.125 18.2083V4.79167C20.125 3.73312 19.2669 2.875 18.2083 2.875Z"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.14551 9.58325C8.93942 9.58325 9.58301 8.93966 9.58301 8.14575C9.58301 7.35184 8.93942 6.70825 8.14551 6.70825C7.3516 6.70825 6.70801 7.35184 6.70801 8.14575C6.70801 8.93966 7.3516 9.58325 8.14551 9.58325Z"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1253 14.3749L15.3337 9.58325L4.79199 20.1249"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

ImageFile.propTypes = {
  width: number,
  height: number,
  className: string
};

ImageFile.defaultProps = {
  width: 23,
  height: 23
};

export default ImageFile;
