import React from 'react';
import { number, string } from 'prop-types';

const AudioFile = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 6.25L7.5 11.25H2.5V18.75H7.5L13.75 23.75V6.25Z"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4248 10.575C20.5965 11.747 21.2547 13.3364 21.2547 14.9937C21.2547 16.651 20.5965 18.2404 19.4248 19.4124"
      stroke="#38A0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

AudioFile.propTypes = {
  width: number,
  height: number,
  className: string
};

AudioFile.defaultProps = {
  width: 23,
  height: 23
};

export default AudioFile;
