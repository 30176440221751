import createReducer from 'reducers/createReducer';
import { actionTypes } from 'actions/userActions';

const initialState = {
  email: null
};

const handleSingupSuccess = (state, { user }) => ({
  ...state,
  email: user.email
});

const handleConfirmSignupSuccess = (state, { email }) => ({
  ...state,
  email
});

export default createReducer(initialState, {
  [actionTypes.SIGN_UP_SUCCESS]: handleSingupSuccess,
  [actionTypes.CONFIRM_SIGNUP_SUCCESS]: handleConfirmSignupSuccess
});
