import React from 'react';
import { bool, string, node } from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import routes from 'constants/routesPaths';

// import { autoLogin } from 'actions/userActions';

// import { LABELS } from 'pages/SignUpPage/tabs';
// import { replaceLandingTab } from 'utils/tabHelpers';
// import { useDispatch } from '../../hooks';

const PrivateRoute = ({ children, exact = false, path, authenticated }) => {
  // const location = useLocation();
  const { search } = useLocation();
  // const userId = '';
  // const userCallBack = useDispatch(autoLogin, userId);

  // get user signed in here

  if (search.includes('ufw') && !authenticated) {
    // const params = new URLSearchParams(search);
    // const userId = params.get('ufw');
    // userCallBack(userId);
  } else if (search.includes('ufw') && authenticated) {
    window.history.pushState({}, null, `${window.location.protocol}//${window.location.host}`);
  }

  return authenticated ? (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  ) : (
    <Redirect to={routes.createPod} />
  );
};

PrivateRoute.propTypes = {
  children: node.isRequired,
  path: string.isRequired,
  authenticated: bool.isRequired,
  exact: bool
};

export default PrivateRoute;
