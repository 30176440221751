import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useAuth } from 'hooks';
import history from 'utils/history';
import RouteFromPath from 'components/routes/RouteFromPath';

import routes from '../routes';

const App = () => {
  const authenticated = useAuth();
  return (
    <>
      <Helmet>
        <title>SkyPod</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>
      <ConnectedRouter history={history}>
        <Switch>
          {routes.map((route, index) => (
            <RouteFromPath key={`route${index}`} {...route} authenticated={authenticated} />
          ))}
        </Switch>
      </ConnectedRouter>
    </>
  );
};

export default App;
