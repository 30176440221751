import React from 'react';
import { string } from 'prop-types';

import DefaultIcon from 'components/common/Icons/DefaultFile';

const DefaultPreview = ({ name }) => (
  <div className="h-100 w-100 d-flex flex-column justify-content-center position-absolute">
    <DefaultIcon className="mx-auto" height={104} width={104} />
    <span className="text-inverted mx-auto mt-2">{name}</span>
  </div>
);

DefaultPreview.propTypes = {
  name: string.isRequired
};

export default DefaultPreview;
