import React from 'react';
import { useHistory } from 'react-router-dom';
import { string, object } from 'prop-types';
import cn from 'classnames';

import { useBreakpoint } from 'hooks';

import defaultImage from 'assets/images/default-image-big.svg';
import { customBackground } from 'utils/helpers';
import { backgroundGradient, md } from 'styles/_constants.scss';

import Arrow from 'components/common/Icons/Arrow';

const PodCover = ({ pod: { title, coverImage }, className }) => {
  const { goBack } = useHistory();
  const isDesktop = useBreakpoint(md);

  const renderTitle = () => (
    <div
      className={cn(
        'text-weight-semibold text-h4 ml-5 mt-4 mt-md-auto mb-md-8 mx-md-10 overflow-wrap',
        {
          'text-inverted': isDesktop
        }
      )}
    >
      {title}
    </div>
  );

  return (
    <div className={className}>
      <div
        className="sixteen-nine position-relative"
        style={customBackground(
          coverImage || defaultImage,
          null,
          'center',
          coverImage ? 'cover' : 'contain',
          isDesktop && backgroundGradient
        )}
      >
        <div className="aspect-ratio-box-inside d-flex flex-column">
          <div className="clickable py-6 px-7 px-md-10 py-md-9 mb-md-8" onClick={goBack}>
            <div className="black-shadow-arrow">
              <Arrow colorType="light" type="left" backArrow />
            </div>
          </div>
          {isDesktop && renderTitle()}
        </div>
      </div>
      {!isDesktop && renderTitle()}
    </div>
  );
};

PodCover.propTypes = {
  className: string,
  pod: object.isRequired
};

PodCover.defaultProps = {
  className: ''
};
export default PodCover;
