import React, { memo, useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { Inputs } from 'components/common/Input';
import cn from 'classnames';
import { actionTypes } from 'actions/podActions';
import { useStatus, useAuth } from 'hooks';

import Field from './StripeField';
import { messages } from './constants';
import PromoCode from '../PromoCode';
// import ButtonBundlePayment from '../ButtonBundlePayment';

const isValid = ({ error, complete, empty }) => complete && !error && !empty;
const areValid = (...fields) => fields.every(isValid);

const PaymentForm = ({
  className,
  // currentStep,
  // disabled,
  discount,
  onBlur,
  onChange,
  pricingPlan,
  promoCode,
  // selectedBundle,
  setSubmitDisabled,
  // stripe,
  totalPrice,
  values
}) => {
  const [cardState, setCardState] = useState({});
  const [expiryState, seteEpiryState] = useState({});
  const [CVCState, setCVCState] = useState({});
  const [email, setEmail] = useState();
  const { status, error } = useStatus(actionTypes.CHECK_PROMO_CODE);

  useEffect(() => {
    if (pricingPlan === 'bundle' && promoCode && discount && totalPrice === 0) {
      setSubmitDisabled(false);
      return;
    }
    setSubmitDisabled(!areValid(cardState, expiryState, CVCState));
    return () => setSubmitDisabled(false);
  }, [cardState, expiryState, CVCState, setSubmitDisabled, promoCode, discount]);

  return (
    <div className={cn(className, 'col-12 mt-6 mt-md-0')}>
      {messages.subtitle}
      <div className="mt-4">
        <Field
          onChange={setCardState}
          label={messages.card}
          StripeComponent={CardNumberElement}
          error={cardState.error}
        />
        <Field
          onChange={seteEpiryState}
          label={messages.date}
          StripeComponent={CardExpiryElement}
          error={expiryState.error}
        />
        <Field
          onChange={setCVCState}
          label={messages.CVC}
          StripeComponent={CardCVCElement}
          error={CVCState.error}
        />
        {!useAuth() && (
          <Inputs
            type="email"
            className="mb-4"
            label={messages.email}
            placeholder="Example@email.com"
            input={{
              value: email,
              onChange: e => {
                setEmail(e.target.value);
                onChange('email', e.target.value, false);
              }
            }}
          />
        )}
      </div>

      {!discount && pricingPlan === 'bundle' && (
        <div className="mt-8">
          <PromoCode
            error={error}
            onBlur={onBlur}
            onChange={onChange}
            status={status}
            values={values}
          />
        </div>
      )}

      {/*
      {currentStep === 1 && pricingPlan === 'bundle' && (
        <ButtonBundlePayment
          disabled={disabled}
          promoCode={promoCode}
          selectedBundle={selectedBundle}
          stripe={stripe}
          totalPrice={totalPrice}
          email={email}
        />
      )}
      */}
    </div>
  );
};

PaymentForm.propTypes = {
  setSubmitDisabled: func,
  className: string
};

PaymentForm.defaultProps = {
  className: ''
};

export default memo(PaymentForm);
