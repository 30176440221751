import React from 'react';
import { func, string } from 'prop-types';

const Camera = ({ onClick, className }) => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="39"
    fill="none"
    viewBox="0 0 39 39"
    className={className}
  >
    <circle cx="19.5" cy="19.5" r="18.5" fill="#06223C" stroke="#fff" strokeWidth="2" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M28.208 25.042a1.583 1.583 0 01-1.583 1.583h-14.25a1.583 1.583 0 01-1.584-1.583v-8.709a1.583 1.583 0 011.584-1.583h3.166l1.584-2.375h4.75l1.583 2.375h3.167a1.583 1.583 0 011.583 1.583v8.709z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.5 23.458a3.167 3.167 0 100-6.333 3.167 3.167 0 000 6.333z"
    />
  </svg>
);

Camera.propTypes = {
  onClick: func,
  className: string
};

Camera.defaultProps = {
  className: ''
};

export default Camera;
