export const messages = {
  title: 'Verify your email address',
  firstLine: "We've sent you a verification link to ",
  secondLine: 'Click on it to confirm you email address.',
  linkIntro: "Haven't received the mail?",
  spamInfo: 'Check your spam folder or ',
  link: 'Send it again',
  success:
    'We sent a new verification link, if you still don’t see it, please check the spam folder.'
};
