import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { object, func } from 'prop-types';
import { last } from 'lodash';
import cn from 'classnames';

import { bytesToMBytes } from 'utils/filesHelpers';
import Plus from 'components/common/Icons/PlusCircle';
import Alert from 'components/common/Icons/Alert';
import { useDispatch, useStatus } from 'hooks';
import { getPodPricing, actionTypes } from 'actions/podActions';
import GeneralDataHandler from 'components/common/GeneralDataHandler';
import UploadFiles from 'components/common/UploadFiles';
import PriceBar from 'components/createPod/PriceBar';
import FilesList from 'components/common/PodFilesList';
import { CREATEPOD_FIELDS } from 'constants/fields';

import { input } from './constants';
import styles from './AddFiles.scss';

const AddFiles = ({
  onChange,
  values: {
    files: { uploadedFiles, totalSize }
  },
  errors,
  setSubmitDisabled,
  pricingPlan,
  bundlePod
}) => {
  const getPricing = useDispatch(getPodPricing);
  const { status } = useStatus(actionTypes.GET_NEW_POD_INFO);
  const pricesListSize = useSelector(({ pods }) => pods.pricings.sizeBased);
  const priceListBundle = useSelector(({ pods }) => pods.pricings.bundleBased);
  const selectedBundle = priceListBundle?.find(item => item.id == bundlePod);
  const giftPods = useSelector(({ pods }) => pods.actualPod);

  const pricings = {
    size: pricesListSize,
    gift: giftPods,
    bundle: selectedBundle
  }[pricingPlan];

  const storageLimit = useMemo(() => {
    if (pricingPlan === 'size') {
      const { storageLimitInBytes: top } = last(pricesListSize) || {};
      return top;
    }
    if (pricingPlan === 'bundle') {
      const storage = selectedBundle.storageLimitInBytes || {};
      return storage;
    }
    if (pricingPlan === 'gift') {
      return pricings.storageLimitInBytes;
    }
  }, [pricesListSize, selectedBundle]);

  const GTEMaxSize = totalSize >= storageLimit;
  const overMaxSize = totalSize > storageLimit;
  const error = errors && errors[CREATEPOD_FIELDS.files];

  useEffect(() => {
    getPricing();
  }, [getPricing]);

  useEffect(() => {
    overMaxSize && setSubmitDisabled(true);
    return () => setSubmitDisabled(false);
  }, [overMaxSize, setSubmitDisabled]);

  const updateFiles = useCallback(
    (files, newSize) => {
      const newFiles = { uploadedFiles: files, totalSize: newSize };
      onChange(input, newFiles, true);
    },
    [onChange]
  );

  const validations = useCallback(
    files => {
      if (files) {
        let subTotalSize = totalSize;
        let newFiles = uploadedFiles;
        for (let i = 0; i < files.length; i += 1) {
          const file = files[i];
          const { size } = file;
          subTotalSize += size;
          newFiles = newFiles ? [file, ...newFiles] : [file];
          if (subTotalSize > storageLimit) {
            setSubmitDisabled(subTotalSize > storageLimit);
            break;
          }
        }
        updateFiles(newFiles, subTotalSize);
      }
    },
    [setSubmitDisabled, storageLimit, totalSize, updateFiles, uploadedFiles]
  );

  const removeFile = useCallback(
    (index, size) => {
      const newFiles = [...uploadedFiles];
      newFiles.splice(index, 1);
      const newSize = totalSize - size;
      newSize < storageLimit && setSubmitDisabled(false);
      updateFiles(newSize === 0 ? null : newFiles, newSize);
    },
    [uploadedFiles, totalSize, storageLimit, setSubmitDisabled, updateFiles]
  );

  const addFiles = () => (
    <>
      <PriceBar
        className="mt-6 mt-md-0"
        currentData={totalSize}
        pricingPlan={pricingPlan}
        pricings={pricings}
        overLimit={overMaxSize}
        storageLimit={storageLimit}
      />

      <UploadFiles
        rootClassName={cn([
          'mt-8 d-flex align-items-center justify-content-center text-align-center',
          styles.dropZone,
          { 'px-9 flex-column': !uploadedFiles },
          { [styles.wholeHeight]: !uploadedFiles },
          { 'py-5 mb-5': uploadedFiles },
          { [styles.error]: overMaxSize }
        ])}
        customclassName={cn([
          'd-flex align-items-center justify-content-center text-align-center',
          { 'flex-column': !uploadedFiles },
          { 'px-3 px-sm-7': overMaxSize }
        ])}
        multiple
        onChange={validations}
        disabled={GTEMaxSize}
        noDrag={GTEMaxSize}
      >
        {overMaxSize && (
          <>
            <Alert width={40} height={40} />
            <div className="ml-6">
              <p className="text-alert text-weight-semibold">
                You exceeded the pod limit by {bytesToMBytes(totalSize - storageLimit)}MB
              </p>
              <p className="text-alert">Delete some files to continue</p>
            </div>
          </>
        )}
        {!overMaxSize && uploadedFiles && !GTEMaxSize && (
          <>
            <Plus width={40} height={40} />
            <p className="pl-5">Add more files</p>
          </>
        )}
        {!overMaxSize && !uploadedFiles && !GTEMaxSize && (
          <>
            <Plus width={70} height={70} />
            <p className="pt-9">Drag or click here to upload your files</p>
          </>
        )}
        {GTEMaxSize && !overMaxSize && <p>Your pod is complete!</p>}
      </UploadFiles>
      {error && <p className="text-x-small text-weight-semibold mt-1 text-alert">{error}</p>}
      {uploadedFiles && <FilesList list={uploadedFiles} removeFile={removeFile} />}
    </>
  );
  return <GeneralDataHandler status={status} SuccessComponent={addFiles} />;
};

AddFiles.propTypes = {
  onChange: func.isRequired,
  values: object,
  errors: object,
  setSubmitDisabled: func.isRequired
};

export default AddFiles;
