import React, { useContext } from 'react';
import cn from 'classnames';

import { useToggle } from 'hooks';

import VersionDisclaimer from 'components/common/VersionDisclaimer';
import Business from 'components/common/Icons/Business';
import Family from 'components/common/Icons/Family';
import Friends from 'components/common/Icons/Friends';
import TabNav from 'components/common/TabNav';
import SignUpForm from 'components/user/SignUpForm';
import LoginForm from 'components/user/LoginForm';
import ForgotPasswordForm from 'components/user/ForgotPasswordForm';
// import { ReactComponent as Logo } from 'assets/images/white-logo.svg';

import { LandingContext } from '../index';
import { LABELS, authFilteredTabs } from '../tabs';
import styles from '../SignUpPage.scss';

const Mobile = () => {
  const [showForgoPassword, toggleForgotPassword] = useToggle();

  const { handleSignup, handleLogin, handleTabChange, tab } = useContext(LandingContext);

  return (
    <div className="d-flex flex-column custom_form_holder">
      <div className="container">
        <a href="/" class="logo_holder">
          <img
            alt=""
            src="https://uploads-ssl.webflow.com/5edcdd00accb0b8dbabf6910/5f7c9dc6434b80dc36eeeaf7_Logo%20Horizontal.svg"
          ></img>
        </a>
        {process.env.VERSION_DISCLAIMER && <VersionDisclaimer />}
        <div className={styles.mobileCover} />
        {/* <Logo className="mt-11 mx-auto" /> */}

        {tab === LABELS.signup && (
          <div className="mx-2 mb-6">
            <div>
              <h3 className="mb-3">Thank you, your purchase is complete.</h3>
              <h4 className="mb-2 tex-bold">
                Please fill out the information below to log-in & fill your pod.
              </h4>
              <h4>
                Creating a pod is meaningful and may take time. If you prefer to come back later,
                you can return to this page whenever you are ready to create your pod.
              </h4>
            </div>
          </div>
        )}

        <h3 className="text-h3 text-weight-semibold text-inverted mt-2 mb-1 mx-auto">
          Things that Matter
        </h3>
        <div className={cn('bg-white rounded-5 SignUpPage__formContainer ', styles.formContainer)}>
          {showForgoPassword ? (
            <ForgotPasswordForm onCancel={toggleForgotPassword} />
          ) : (
            <>
              <TabNav
                tabs={authFilteredTabs()}
                tabClass="mr-7 mb-7"
                focused={tab}
                handleChange={handleTabChange}
                signUpTabs
              />
              {tab === LABELS.signup && <SignUpForm onSubmit={handleSignup} />}
              {tab === LABELS.login && (
                <LoginForm onForgotPassword={toggleForgotPassword} onSubmit={handleLogin} />
              )}
            </>
          )}
        </div>

        <div className="d-flex flex-column mt-12 mb-8 mx-9">
          <div className="pb-10">
            <div className={cn([styles.image, 'm-auto d-flex'])}>
              <Family className="m-auto" />
            </div>
            <p className="text-weight-semibold mt-8 mb-3 text-md-left text-center">For Family</p>
            <p className="text-small text-md-left text-center">
              Record messages for loved ones that can live forever.
            </p>
          </div>
          <div className="pb-10">
            <div className={cn([styles.image, 'm-auto d-flex'])}>
              <Friends className="m-auto" />
            </div>
            <p className="text-weight-semibold mt-8 mb-3 text-md-left text-center">For Friends</p>
            <p className="text-small text-md-left text-center">
              Photos and videos of your favorite memories delivered in the future.
            </p>
          </div>
          <div className="pb-10">
            <div className={cn([styles.image, 'm-auto d-flex'])}>
              <Business className="m-auto" />
            </div>
            <p className="text-weight-semibold mt-8 mb-3 text-md-left text-center">For Business</p>
            <p className="text-small text-md-left text-center">
              Ensure your company is prepared for the future.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
