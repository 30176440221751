export const messages = {
  submit: 'Purchase',
  next: 'Next',
  freePod: 'Redeem and create the first pod',
  purchase: 'Purchase & Create Pod',
  cancel: 'Cancel',
  back: 'Back',
  confirmationAlert:
    'Are you sure you want to cancel the pod? \n\n If you cancel your progress will be lost'
};
