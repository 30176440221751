import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { formatDateExtended } from 'utils/dateHelpers';
import Button from 'components/common/Button';
import validations from 'validations/createGiftValidations';
import cn from 'classnames';
import moment from 'moment';

import {
  useDispatch,
  useForm,
  useSession,
  useStatus,
  useTextInputProps,
  useValidation
} from 'hooks';
import { Inputs } from 'components/common/Input';
import { CREATEGIFT_FIELDS } from 'constants/fields';
import { createGift, createGiftClearStatus, actionTypes } from 'actions/giftActions';
import routesPaths from 'constants/routesPaths';
import stylesError from 'components/common/Input/Inputs/Inputs.scss';
import { initialValues, messages } from './constants';
import styles from './Button.scss';

const GiftForm = ({ availablePods }) => {
  const create = useDispatch(createGift);
  const { status, error } = useStatus(actionTypes.CREATE_GIFT);
  const createClearStatus = useDispatch(createGiftClearStatus);
  const validator = useValidation(validations);
  const [selectablePods, setSelectablePods] = useState(0);

  useEffect(() => {
    error && alert(error);

    return () => {
      createClearStatus();
    };
  }, [error, status]);

  const {
    user: { bundleCount, giftCount }
  } = useSession();

  const { values, errors, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      initialValues,
      validator,
      validateOnBlur: true,
      onSubmit: values => {
        create(values);
      }
    },
    []
  );

  const inputTextProps = useTextInputProps(handleValueChange, handleBlur, values);

  if (status === 'SUCCESS') {
    return <Redirect to={routesPaths.index} />;
  }

  return (
    <div>
      <p className="mb-4">
        You have {bundleCount + giftCount} {`pod${bundleCount + giftCount > 1 ? 's' : ''}`} left to
        gift. Please select a pod or pod bundle below to gift from
      </p>

      {availablePods.map((gift, index) => (
        <div className="mb-4" key={index}>
          <label className="d-flex">
            <input
              className="mr-3"
              name="userPodId"
              onChange={e => {
                handleValueChange(e.target.name, e.target.value);
                setSelectablePods(gift.availablePods);
              }}
              type="radio"
              value={gift.id}
            />
            <div className="text-x-small">
              <p className="text-weight-semibold">
                {gift.availablePods} {`Pod${gift.availablePods > 1 ? 's' : ''}`}
              </p>
              <p>
                Exp. Time:{' '}
                {moment(gift.expirationDate).isValid()
                  ? formatDateExtended(gift.expirationDate)
                  : gift.expirationDate}
              </p>
            </div>
          </label>
        </div>
      ))}

      <div className="mb-4">
        <label className="text-x-small text-weight-semibold d-block mb-2">
          Select the amount of pods
        </label>

        <select
          className={`${cn(errors[CREATEGIFT_FIELDS.podCount] && [stylesError.errorinput])} w-100`}
          name={[CREATEGIFT_FIELDS.podCount]}
          onBlur={e => handleBlur(e.target.name)}
          onChange={e => handleValueChange(e.target.name, e.target.value)}
        >
          <option></option>
          {Array.from({ length: selectablePods }, (v, k) => (
            <option key={k} value={k + 1}>
              {k + 1}
            </option>
          ))}
        </select>
        <p className={cn([stylesError.error, 'text-x-small text-weight-semibold mt-1'])}>
          {errors[CREATEGIFT_FIELDS.podCount]}
        </p>
      </div>

      <Inputs
        {...messages[CREATEGIFT_FIELDS.senderName]}
        type="text"
        customError={errors[CREATEGIFT_FIELDS.senderName]}
        input={{ ...inputTextProps(CREATEGIFT_FIELDS.senderName) }}
        className="mb-4"
      />

      <Inputs
        {...messages[CREATEGIFT_FIELDS.recipientName]}
        type="text"
        customError={errors[CREATEGIFT_FIELDS.recipientName]}
        input={{ ...inputTextProps(CREATEGIFT_FIELDS.recipientName) }}
        className="mb-4"
      />

      <Inputs
        {...messages[CREATEGIFT_FIELDS.recipientEmail]}
        type="text"
        customError={errors[CREATEGIFT_FIELDS.recipientEmail]}
        input={{ ...inputTextProps(CREATEGIFT_FIELDS.recipientEmail) }}
        className="mb-4"
      />

      <Inputs
        {...messages[CREATEGIFT_FIELDS.message]}
        type="text"
        customError={errors[CREATEGIFT_FIELDS.message]}
        input={{ ...inputTextProps(CREATEGIFT_FIELDS.message) }}
        className="mb-4"
      />

      <Button
        type="submit"
        styleType="primary"
        onClick={handleSubmit}
        className={cn(styles.button)}
      >
        Submit
      </Button>
    </div>
  );
};

export default GiftForm;
