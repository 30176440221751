import React, { memo, useCallback, useRef, useMemo } from 'react';
import { func, object, array } from 'prop-types';
import cn from 'classnames';
import { cloneDeep } from 'lodash';

import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import ContactSuggest from 'components/contacts/ContactSuggest';
import AddRecipientButton from 'components/createPod/AddRecipientButton';
import { CREATEPOD_FIELDS } from 'constants/fields';

import { messages } from './constants';
import styles from './RecipientsForm.scss';

const RecipientsForm = ({ onChange, onBlur, values, contacts, errors }) => {
  const recipients = useMemo(() => values[CREATEPOD_FIELDS.recipients], [values]);
  const recipientErrors = errors[CREATEPOD_FIELDS.recipients] || [];
  const scrollPanel = useRef();

  const handleChange = useCallback(
    (value, index) => {
      const newValue = recipients.map(cloneDeep);
      newValue[index][CREATEPOD_FIELDS.email] = value;
      onChange(CREATEPOD_FIELDS.recipients, newValue);
    },
    [onChange, recipients]
  );

  const handleBlur = useCallback(() => {
    onBlur([CREATEPOD_FIELDS.recipients]);
  }, [onBlur]);

  const onAdd = useCallback(() => {
    onChange([CREATEPOD_FIELDS.recipients], [...recipients, { [CREATEPOD_FIELDS.email]: null }]);
  }, [onChange, recipients]);

  const onRemove = useCallback(
    index => {
      const newValue = [...recipients];
      newValue.splice(index, 1);
      onChange([CREATEPOD_FIELDS.recipients], newValue, true);
    },
    [onChange, recipients]
  );

  const existsEmpty = useMemo(
    () => recipients.some(recipient => !recipient[CREATEPOD_FIELDS.email]),
    [recipients]
  );

  return (
    <div className="mt-6 mt-md-0">
      {recipients.map((inputVal, index) => {
        const newInputProps = {
          onBlur: handleBlur,
          onChange: value => handleChange(value, index),
          value: inputVal[CREATEPOD_FIELDS.email]
        };
        const error = recipientErrors[index] && recipientErrors[index][CREATEPOD_FIELDS.email];

        const showRemove = index > 0 || recipients.length > 1;
        const isLast = index === recipients.length - 1;

        return (
          <div key={index} className="mb-4 w-100">
            <div className={cn('w-100 position-relative', { [styles.headerLine]: index > 0 })}>
              <div className="d-flex justify-content-between mt-4">
                <span className="text-weight-bold text-gray text-small">
                  RECIPIENT {`${index + 1}`}
                </span>
                {showRemove && <Cancel className="clickable" onClick={() => onRemove(index)} />}
              </div>
              <ContactSuggest
                {...messages[CREATEPOD_FIELDS.email]}
                key={index}
                error={error}
                className="mt-2"
                contacts={contacts}
                isLast={isLast}
                {...newInputProps}
              />
            </div>
          </div>
        );
      })}
      <AddRecipientButton disabled={existsEmpty} onClick={onAdd} />
      <div ref={scrollPanel} />
    </div>
  );
};

RecipientsForm.propTypes = {
  onChange: func.isRequired,
  contacts: array.isRequired,
  onBlur: func,
  values: object,
  errors: object
};

RecipientsForm.defaultProps = {
  values: {},
  errors: {},
  onBlur: () => {}
};

export default memo(RecipientsForm);
