import React from 'react';
import { number, string } from 'prop-types';

const Wallet = ({ width = 209, height = 185, className, scale = 1 }) => (
  <svg
    width={width * scale}
    height={height * scale}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M192.458 45.7605C165.755 25.2104 139.854 41.958 110.964 36.0369C81.8655 30.0724 70.2725 10.2124 37.1301 19.3795C7.77137 27.4998 -10.0426 60.0569 6.09692 87.314C10.6961 95.0812 18.5312 100.941 21.7331 109.632C26.8981 123.651 19.0361 134.415 20.5698 148.193C26.9426 205.439 114.283 183.265 146.42 165.455C196.821 137.522 230.917 75.3577 192.458 45.7605Z"
      fill="#64C1FF"
    />
    <rect x="57" y="65" width="110" height="102" rx="10" fill="#71CBFF" />
    <circle cx="135" cy="30" r="27" fill="#71CBFF" />
    <circle cx="97" cy="64" r="27" fill="#71CBFF" />
    <path
      d="M137.198 27.7557V17.8366C139.553 18.5996 141.211 20.3923 141.211 22.4774C141.211 23.8766 142.345 25.0108 143.744 25.0108C145.144 25.0108 146.278 23.8766 146.278 22.4774C146.278 17.6653 142.387 13.6311 137.198 12.6224V11.884C137.198 10.4848 136.064 9.35059 134.665 9.35059C133.265 9.35059 132.131 10.4848 132.131 11.884V12.6224C126.943 13.6308 123.052 17.665 123.052 22.4771C123.052 27.2892 126.943 31.3234 132.131 32.3318V42.2502C129.776 41.4879 128.118 39.6949 128.118 37.6101C128.118 36.2109 126.984 35.0767 125.585 35.0767C124.185 35.0767 123.051 36.2109 123.051 37.6101C123.051 42.4222 126.942 46.4564 132.131 47.4648V48.2032C132.131 49.6024 133.265 50.7366 134.664 50.7366C136.064 50.7366 137.198 49.6024 137.198 48.2032V47.4648C142.386 46.4564 146.278 42.4222 146.278 37.6101C146.278 32.7983 142.386 28.7638 137.198 27.7557ZM132.131 27.1179C129.776 26.3552 128.118 24.5626 128.118 22.4774C128.118 20.3923 129.776 18.5996 132.131 17.8366V27.1179ZM137.197 42.2509V32.9696C139.553 33.7326 141.211 35.5253 141.211 37.6101C141.211 39.6953 139.553 41.4879 137.197 42.2509Z"
      fill="#2390F3"
    />
    <path
      d="M134.664 0C118.098 0 104.62 13.4775 104.62 30.0442C104.62 31.4434 105.754 32.5776 107.154 32.5776C108.553 32.5776 109.687 31.4434 109.687 30.0442C109.687 16.2719 120.892 5.06646 134.664 5.06646C148.437 5.06646 159.642 16.2712 159.642 30.0442C159.642 43.8168 148.437 55.0216 134.664 55.0216C133.673 55.0216 132.676 54.9631 131.701 54.8479C130.313 54.6849 129.052 55.677 128.888 57.0662C128.724 58.4554 129.717 59.7148 131.106 59.8792C132.278 60.0176 133.475 60.088 134.664 60.088C151.23 60.088 164.708 46.6105 164.708 30.0442C164.708 13.4778 151.231 0 134.664 0Z"
      fill="#2390F3"
    />
    <path
      d="M99.28 60.6838V50.7654C101.635 51.5284 103.293 53.321 103.293 55.4058C103.293 56.805 104.427 57.9392 105.827 57.9392C107.226 57.9392 108.36 56.805 108.36 55.4058C108.36 50.5937 104.469 46.5595 99.2804 45.5511V44.8127C99.2804 43.4135 98.1465 42.2793 96.747 42.2793C95.3475 42.2793 94.2136 43.4135 94.2136 44.8127V45.5511C89.0249 46.5595 85.1338 50.5937 85.1338 55.4058C85.1338 60.218 89.0249 64.2521 94.2136 65.2605V75.1793C91.8585 74.4163 90.2003 72.6236 90.2003 70.5385C90.2003 69.1393 89.0664 68.0051 87.6669 68.0051C86.2674 68.0051 85.1335 69.1393 85.1335 70.5385C85.1335 75.3506 89.0246 79.3848 94.2132 80.3935V81.1319C94.2132 82.5311 95.3471 83.6653 96.7466 83.6653C98.1462 83.6653 99.28 82.5311 99.28 81.1319V80.3935C104.469 79.3851 108.36 75.3506 108.36 70.5385C108.36 65.7264 104.469 61.6918 99.28 60.6838ZM94.2132 60.0463C91.8581 59.2833 90.2 57.4906 90.2 55.4058C90.2 53.3207 91.8578 51.528 94.2132 50.7654V60.0463ZM99.28 75.1793V65.8977C101.635 66.6607 103.293 68.4533 103.293 70.5382C103.293 72.6233 101.635 74.4163 99.28 75.1793Z"
      fill="#2390F3"
    />
    <path
      d="M84.7927 38.0589C84.0732 36.8592 82.5179 36.4698 81.317 37.1889C72.3026 42.5944 66.7023 52.4747 66.7023 62.9728C66.7023 69.4464 68.7297 75.6116 72.5649 80.8026C73.0627 81.4749 73.8287 81.8309 74.6049 81.8309C75.1282 81.8309 75.6555 81.6695 76.1084 81.3348C77.2336 80.5034 77.4717 78.917 76.6403 77.792C73.4535 73.4793 71.7691 68.3551 71.7691 62.9732C71.7691 54.1126 76.3123 46.098 83.9227 41.5342C85.1224 40.8147 85.5118 39.2585 84.7927 38.0589Z"
      fill="#2390F3"
    />
    <path
      d="M96.7469 32.9297C94.6259 32.9297 92.5069 33.1521 90.4499 33.5911C89.0816 33.8833 88.2091 35.2293 88.5009 36.5973C88.7934 37.9653 90.1398 38.8372 91.5078 38.546C93.2178 38.1808 94.9805 37.9962 96.7472 37.9962C110.52 37.9962 121.725 49.2012 121.725 62.9742C121.725 68.3561 120.041 73.48 116.854 77.7931C116.023 78.918 116.261 80.5044 117.386 81.3359C117.839 81.6705 118.366 81.8319 118.889 81.8319C119.665 81.8319 120.432 81.4763 120.928 80.8039C124.764 75.6133 126.791 69.4478 126.791 62.9742C126.791 46.4078 113.313 32.9297 96.7469 32.9297Z"
      fill="#2390F3"
    />
    <path
      d="M162.216 63.3096H131.621C130.221 63.3096 129.088 64.4438 129.088 65.843C129.088 67.2421 130.221 68.3764 131.621 68.3764H162.216C163.194 68.3764 163.99 69.1722 163.99 70.1504V73.388H130.044C128.645 73.388 127.511 74.5223 127.511 75.9214C127.511 77.3206 128.645 78.4548 130.044 78.4548H163.99V80.1651C163.99 81.5643 165.124 82.6985 166.523 82.6985C167.923 82.6985 169.056 81.5643 169.056 80.1651V70.1511C169.056 66.3782 165.988 63.3096 162.216 63.3096Z"
      fill="#2390F3"
    />
    <path
      d="M166.523 107.715C167.922 107.715 169.056 106.58 169.056 105.181V92.535C169.056 87.7325 165.149 83.8258 160.347 83.8258H66.9478C62.6971 83.8258 59.2377 80.3744 59.2234 76.1267V76.0998C59.2234 76.0975 59.2231 76.0952 59.2231 76.0928C59.2264 73.0644 61.0117 70.3025 63.7739 69.0554C65.0489 68.48 65.616 66.9796 65.0406 65.7046C64.4645 64.4296 62.9647 63.8621 61.6894 64.4379C57.1134 66.5038 54.1566 71.0818 54.1566 76.1015C54.1566 76.1101 54.1573 76.1187 54.1573 76.1274V157.207C54.1573 164.261 59.8944 170 66.9471 170H160.347C165.149 170 169.056 166.093 169.056 161.291V144.702C169.056 143.303 167.922 142.169 166.523 142.169C165.123 142.169 163.99 143.303 163.99 144.702V161.291C163.99 163.3 162.355 164.933 160.347 164.933H66.9468C62.6882 164.933 59.2234 161.468 59.2234 157.207V86.2882C61.3713 87.9211 64.0478 88.8923 66.9478 88.8923H160.347C162.356 88.8923 163.99 90.5266 163.99 92.535V105.181C163.99 106.58 165.123 107.715 166.523 107.715Z"
      fill="#2390F3"
    />
    <path
      d="M166.835 109.735H148.781C140.396 109.735 133.575 116.557 133.575 124.942C133.575 133.327 140.396 140.148 148.781 140.148H153.371C154.77 140.148 155.904 139.014 155.904 137.615C155.904 136.216 154.77 135.081 153.371 135.081H148.781C143.19 135.081 138.641 130.532 138.641 124.941C138.641 119.35 143.19 114.801 148.781 114.801H166.835C167.906 114.801 168.777 115.673 168.777 116.744V133.14C168.777 134.21 167.906 135.081 166.835 135.081H161.815C160.415 135.081 159.281 136.216 159.281 137.615C159.281 139.014 160.415 140.148 161.815 140.148H166.835C170.7 140.148 173.844 137.004 173.844 133.14V116.744C173.844 112.879 170.7 109.735 166.835 109.735Z"
      fill="#2390F3"
    />
    <path
      d="M148.499 117.339C144.307 117.339 140.897 120.749 140.897 124.941C140.897 129.133 144.307 132.544 148.499 132.544C152.691 132.544 156.102 129.134 156.102 124.941C156.102 120.749 152.691 117.339 148.499 117.339ZM148.499 127.477C147.1 127.477 145.963 126.34 145.963 124.941C145.963 123.543 147.1 122.405 148.499 122.405C149.898 122.405 151.035 123.543 151.035 124.941C151.035 126.34 149.898 127.477 148.499 127.477Z"
      fill="#2390F3"
    />
    <path
      d="M79.717 12.8145C78.3175 12.8145 77.1836 13.9487 77.1836 15.3479V28.1829C77.1836 29.582 78.3175 30.7163 79.717 30.7163C81.1165 30.7163 82.2504 29.582 82.2504 28.1829V15.3479C82.2504 13.9487 81.1165 12.8145 79.717 12.8145Z"
      fill="#2390F3"
    />
    <path
      d="M92.3816 7.74805C90.9821 7.74805 89.8482 8.88227 89.8482 10.2814V23.1164C89.8482 24.5156 90.9821 25.6498 92.3816 25.6498C93.7811 25.6498 94.915 24.5156 94.915 23.1164V10.2814C94.9147 8.88227 93.7808 7.74805 92.3816 7.74805Z"
      fill="#2390F3"
    />
  </svg>
);

Wallet.propTypes = {
  width: number,
  height: number,
  scale: number,
  className: string
};

export default Wallet;
