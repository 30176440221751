export const stepTexts = {
  size: {
    pricingPlan: {
      title: 'Purchase your Pod',
      description: ''
    },
    basicPodInfo: {
      title: 'Create Pod',
      description:
        'Choosing a title and cover image for your Pod is like placing a label and wrapping paper on a gift.'
    },
    addFiles: {
      title: 'Fill the Pod',
      description:
        'Add photos, videos, and files then make them viewable on the time and date of your choosing.'
    },
    recipients: {
      title: 'Who is it for?',
      description: "Add all the recipients you want. The price won't change!"
    },
    openDate: {
      title: 'Set an Opening Date',
      description:
        'Your Pod will be delivered immediately. However, you decide when it can be opened.'
    },
    review: {
      title: 'Take a final look',
      description:
        'Please confirm pod size, recipients, and open date. If everything looks good, click Next.'
    },
    payment: {
      title: 'Add Payment',
      description: 'This is the last step before your pod is sent...'
    }
  },
  bundle: {
    pricingPlan: {
      title: 'Purchase your Pod',
      description: ''
    },
    basicPodInfo: {
      title: 'Create Pod',
      description:
        'Choosing a title and cover image for your Pod is like placing a label and wrapping paper on a gift.'
    },
    addFiles: {
      title: 'Fill the Pod',
      description:
        'Add photos, videos, and files then make them viewable on the time and date of your choosing.'
    },
    recipients: {
      title: 'Who is it for?',
      description: "Add all the recipients you want. The price won't change!"
    },
    openDate: {
      title: 'Set an Opening Date',
      description:
        'Your Pod will be delivered immediately. However, you decide when it can be opened.'
    },
    review: {
      title: 'Take a final look',
      description:
        'Please confirm pod size, recipients, and open date. If everything looks good, click Next.'
    },
    payment: {
      title: 'Add Payment',
      description:
        "In this step you can finalize the payment after filling out your credit card info. Don't forget to use a discount code if you have one."
    }
  },
  gift: {
    pricingPlan: {
      title: 'Purchase your Pod',
      description: ''
    },
    basicPodInfo: {
      title: 'Create Pod',
      description:
        'Choosing a title and cover image for your Pod is like placing a label and wrapping paper on a gift.'
    },
    addFiles: {
      title: 'Fill the Pod',
      description:
        'Add photos, videos, and files then make them viewable on the time and date of your choosing.'
    },
    recipients: {
      title: 'Who is it for?',
      description: "Add all the recipients you want. The price won't change!"
    },
    openDate: {
      title: 'Set an Opening Date',
      description:
        'Your Pod will be delivered immediately. However, you decide when it can be opened.'
    },
    review: {
      title: 'Take a final look',
      description:
        'Please confirm pod size, recipients, and open date. If everything looks good, click Next.'
    },
    payment: {
      title: 'Add Payment',
      description: 'This is the last step before your pod is sent...'
    }
  }
};
