import React from 'react';
import { string, array, func } from 'prop-types';
import styles from './PodsList.scss';

const PodsList = ({ title, list, ListComponent, status }) =>
  !!list.length && (
    <>
      <p className={styles.listTitle}>{title}</p>
      <div className="row">
        <ListComponent list={list} status={status} />
      </div>
    </>
  );

PodsList.propTypes = {
  title: string,
  list: array,
  status: string,
  ListComponent: func
};

export default PodsList;
