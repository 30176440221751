import React, { memo } from 'react';
import { shape, arrayOf, string, func, bool } from 'prop-types';
import cn from 'classnames';

import Tab from 'components/common/Tab';
import { TAB } from 'constants/shapes';

const TabNav = ({
  tabs,
  focused,
  handleChange,
  tabClass,
  className,
  labelClass,
  blackAccents,
  signUpTabs
}) => (
  <div className={cn(className, 'row tab-row')}>
    {tabs.map(({ id, label, icon, focusedIcon }) => (
      <Tab
        black={blackAccents}
        key={id}
        focused={focused === id}
        className={tabClass}
        onClick={() => handleChange(id)}
      >
        {icon && <div className="mr-md-5"> {focused === id ? focusedIcon : icon} </div>}
        {label && (
          <span
            className={cn(
              labelClass,
              'mt-1 d-md-block',
              { 'text-gray': focused !== id },
              { 'text-weight-bold': signUpTabs && focused === id },
              !blackAccents && { 'text-blue': focused === id },
              { 'd-none': !signUpTabs && focused !== id },
              { 'd-block': !signUpTabs && focused === id }
            )}
          >
            {label}
          </span>
        )}
      </Tab>
    ))}
  </div>
);

TabNav.propTypes = {
  className: string,
  tabs: arrayOf(shape(TAB)),
  focused: string.isRequired,
  handleChange: func.isRequired,
  tabClass: string,
  labelClass: string,
  blackAccents: bool,
  signUpTabs: bool
};

export default memo(TabNav);
