import React, { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { string, number } from 'prop-types';
import cn from 'classnames';

import { useBreakpoint } from 'hooks';
import Button from 'components/common/Button';
import Arrow from 'components/common/Icons/Arrow';
import Tick from 'components/common/Icons/Tick';
import Wallet from 'components/common/Icons/WalletWithBg';
import routes from 'constants/routesPaths';
import { isiOS } from 'utils/helpers';

import { sm } from 'styles/_constants.scss';
import messages from './constants';
import styles from './styles.scss';

const ReferralCard = ({ className, code, amount }) => {
  const url = `${process.env.REFERRAL_URL}/?code=${code}`;

  const [copied, setCopied] = useState(false);
  const isDesktop = useBreakpoint(sm);
  const copy = useCallback(() => {
    setCopied(true);
    const textArea = document.createElement('textArea');
    textArea.readOnly = true;
    textArea.contentEditable = true;
    textArea.value = url;
    document.body.appendChild(textArea);

    if (isiOS()) {
      const range = document.createRange();
      range.selectNodeContents(textArea);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }

    // clipboard.writeText is not supported on iOS
    // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText

    document.execCommand('copy');
    document.body.removeChild(textArea);
  }, [url]);

  return (
    <div className={className}>
      <div
        className={cn([
          styles.topHalf,
          'row align-items-center pt-3 pb-7 pt-sm-11 pb-sm-9 px-sm-9'
        ])}
      >
        <Link to={routes.index}>
          <div
            className={cn(
              styles.backArrow,
              'ml-3 mt-4 mt-sm-8 ml-sm-9 position-absolute clickable'
            )}
          >
            <Arrow className="m-3" colorType="light" type="left" backArrow />
          </div>
        </Link>
        <div
          className={cn(styles.textContainer, 'order-2 order-sm-1 mr-sm-4 col-sm-7 offset-sm-1')}
        >
          <h2 className={cn('text-inverted', { 'text-h2': isDesktop, 'text-h3': !isDesktop })}>
            {messages.header1}
          </h2>
          <h2 className={cn('text-inverted mb-3', { 'text-h2': isDesktop, 'text-h3': !isDesktop })}>
            {messages.header2(amount)}
          </h2>
          <span className="text-inverted">{messages.share}</span>
        </div>
        <Wallet scale={isDesktop ? 1 : 0.6} className="col-sm-3 order-1 order-sm-2 mt-4 mb-4" />
      </div>

      <div className={cn(styles.bottomHalf, 'row pt-9 pb-10 pt-sm-10 pb-sm-12 bg-white px-sm-9')}>
        <div className="col col-sm-10 offset-sm-1">
          <span className="text-x-small text-weight-bold canceButton">{messages.link}</span>
          <div
            className={cn(
              styles.link,
              'bg-gray p-3 mt-3 d-flex align-items-center justify-content-between'
            )}
          >
            <span className={styles.url}>{url}</span>
            <Button onClick={copy} className={cn('p-2', { [styles.copied]: copied })}>
              <span className="text-inverted text-weight-semibold">
                <div className={styles.constantsWidth}>
                  {copied && <Tick className="mr-2" />}
                  {copied ? messages.copied : messages.copy}
                </div>
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ReferralCard.propTypes = {
  className: string,
  code: string.isRequired,
  amount: number.isRequired
};

export default memo(ReferralCard);
