import React, { memo } from 'react';
import { object, string, arrayOf, node } from 'prop-types';
import cn from 'classnames';

import styles from './Inputs.scss';

const Inputs = ({
  input,
  label,
  type,
  icon,
  placeholder,
  meta: { error, touched },
  customError,
  className,
  inputClassName,
  inputMode,
  as
}) => {
  const showError = (touched && error) || customError;

  const inputComponent = React.createElement(as, {
    placeholder,
    type,
    inputMode,
    className: cn(
      'w-100 px-3',
      { [styles.errorinput]: showError },
      { [inputClassName]: !!inputClassName }
    ),
    ...input
  });
  return (
    <div className={className}>
      {label && <label className="text-x-small text-weight-semibold d-block mb-2">{label}</label>}
      <div>
        {icon ? (
          <div className={styles.icon}>
            {inputComponent}
            {icon}
          </div>
        ) : (
          inputComponent
        )}
        {showError && (
          <p className={cn([styles.error, 'text-x-small text-weight-semibold mt-1'])}>
            {customError?.[0] || error}
          </p>
        )}
      </div>
    </div>
  );
};

Inputs.propTypes = {
  input: object,
  label: string,
  type: string,
  icon: node,
  placeholder: string,
  meta: object,
  className: string,
  inputClassName: string,
  customError: arrayOf(string),
  as: node
};

Inputs.defaultProps = {
  label: '',
  type: 'text',
  className: '',
  meta: {},
  as: 'input'
};

export default memo(Inputs);
