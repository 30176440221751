import { ERROR } from 'constants/status';
import { truncateDecimal } from 'utils/helpers';

export const handleImgOrientation = (image, handleLoadingSuccess, handleLoadingError) => {
  const loadImage = require('blueimp-load-image');

  const options = { orientation: true };
  const updateImg = img => {
    if (img.type === ERROR.toLowerCase()) {
      handleLoadingError();
    } else {
      const image64 = img.toDataURL();
      handleLoadingSuccess(image64);
    }
  };
  loadImage(image, updateImg, options);
};

export const multipleFormData = files => {
  const data = new FormData();
  files.forEach(file => {
    data.append('files[]', file);
  });
  return data;
};

export const mimeTypeToType = mimeType => mimeType.match('.*(?=/)');

export const bytesToMBytes = bytes => truncateDecimal(bytes / 2 ** 20, 2);

export const MBytesToBytes = mbytes => mbytes * 2 ** 20;

export const getMinimumSizeMB = size => size || '0.01';
