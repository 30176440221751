import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Check from 'components/common/Icons/CheckCircle';
import Footer from 'components/common/Footer';
import Logo from 'components/common/Icons/Logo';
import { LABELS } from 'pages/SignUpPage/tabs';

import getInfoFromQuery from 'utils/getInfoFromQuery';
import { replaceLandingTab } from 'utils/tabHelpers';

import { messages } from './constants';

const ConfirmEmailPage = () => {
  const { search } = useLocation();
  const { code } = getInfoFromQuery(search, ['code']);

  return (
    <div className="bg-gray min-vh-100 py-4 d-flex">
      <div className="container d-flex flex-column flex-grow-1">
        <div className="mt-md-12 mb-md-12 col-md-8 offset-md-2 bg-white rounded-5 p-4 d-flex flex-column align-items-center justify-content-center flex-grow-1">
          <Logo className="mb-10" width={260} />
          <Check className="mb-7" />
          <h2 className="text-center text-h5">{messages.title}</h2>
          <label className="text-center text-small pt-4">{messages.firstLine}</label>
          <Link
            className="mt-9"
            to={code ? replaceLandingTab(LABELS.login, code) : replaceLandingTab(LABELS.login)}
          >
            <u className="text-center text-blue">{messages.button}</u>
          </Link>
        </div>
        <Footer className="d-none d-md-inline" />
      </div>
    </div>
  );
};

export default memo(ConfirmEmailPage);
