import React, { memo } from 'react';

import { ReactComponent as Parachute } from 'assets/icons/parachute.svg';
import Card from 'components/common/Card';
import Button from 'components/common/LinkButton';
import Header from 'components/common/Header';
import routes from 'constants/routesPaths';

const CreatePodSuccessPagePayment = () => {
  return (
    <div className="d-flex flex-column min-vh-100 h-100">
      <Header />
      <div className="bg-gray h-100">
        <div className="container p-md-9">
          <Card className="container d-flex flex-column pb-2 pb-md-10 px-4 mt-5 rounded-5 background-card">
            <Parachute className="my-8 mx-auto" />
            <h1 className="text-h3 text-center mb-2">Thank you for your purchase!</h1>
            <span className="text-center mb-6">
              You successfully purchased your bundle of pods. You can use them one by one by
              clicking on {'"Create pod"'} on your Skypod profile.
            </span>
            <div className="mt-8 mt-lg-10 px-8 px-md-12">
              <div className="row justify-content-center mx-lg-12">
                <Button
                  inverted
                  route={routes.index}
                  className="mb-5 order-2 col-12 col-lg ml-lg-9 mr-lg-3"
                  label="Back to Home"
                />
                <Button
                  route={routes.createPod}
                  className="mb-5 order-1 col-12 order-lg-3 col-lg ml-lg-3 mr-lg-9"
                  label="Back to Home"
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default memo(CreatePodSuccessPagePayment);
