import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from 'reducers';
import history from 'utils/history';

export default function configureStore(initialState) {
  const middewares = [thunkMiddleware, routerMiddleware(history)];

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['session']
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer(history));
  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middewares))
  );
  const persistor = persistStore(store);

  return { store, persistor };
}
