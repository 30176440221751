export const LABELS = {
  signup: 'signup',
  login: 'login'
};

export const TABS = [
  { id: LABELS.login, label: 'Log in', icon: null, focusedIcon: null },
  { id: LABELS.signup, label: 'Create Account to Fill Your Pod', icon: null, focusedIcon: null }
];

export function authFilteredTabs() {
  if (!localStorage.getItem('register_token')) {
    return TABS.filter(tab => tab.id != LABELS.signup);
  }
  return TABS;
}
