import { CREATEGIFT_FIELDS } from 'constants/fields';

export const messages = {
  [CREATEGIFT_FIELDS.senderName]: {
    label: 'Sender',
    placeholder: 'Your name'
  },
  [CREATEGIFT_FIELDS.recipientName]: {
    label: 'Recipient',
    placeholder: 'Recipient name'
  },
  [CREATEGIFT_FIELDS.recipientEmail]: {
    label: 'Email',
    placeholder: 'Recipient email address'
  },
  [CREATEGIFT_FIELDS.message]: {
    label: 'Message',
    placeholder: 'Include a message with your gifted pod.'
  }
};

export const initialValues = {
  [CREATEGIFT_FIELDS.userPodId]: '',
  [CREATEGIFT_FIELDS.podCount]: '',
  [CREATEGIFT_FIELDS.senderName]: '',
  [CREATEGIFT_FIELDS.recipientName]: '',
  [CREATEGIFT_FIELDS.recipientEmail]: '',
  [CREATEGIFT_FIELDS.message]: ''
};
