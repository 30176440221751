import React, { useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';

import { useDispatch } from 'hooks';
import { getExtraTimeStatusReset } from 'actions/podActions';

import CheckCircle from 'components/common/Icons/CheckCircle';
import Button from 'components/common/Button';
import Card from 'components/common/Card';

import routes from 'constants/routesPaths';
import { DATE_FORMAT } from 'constants/constants';

const ExtraTimeSuccessPage = () => {
  const incomingPod = useSelector(({ pods }) => pods.incomingPod, shallowEqual);

  const resetExtraTimeStatus = useDispatch(getExtraTimeStatusReset);

  const { goBack } = useHistory();

  useEffect(() => resetExtraTimeStatus, [resetExtraTimeStatus]);

  if (!incomingPod) {
    return <Redirect to={routes.index} />;
  }

  return (
    <div className="d-flex flex-column min-vh-100 h-100">
      <div className="bg-gray h-100">
        <div className="container p-md-9">
          <Card className="container d-flex flex-column justify-content-center align-items-center py-11 px-4 mt-5 rounded-5">
            <CheckCircle width={84} height={84} />
            <p className="pt-9 text-center text-weight-semibold">
              You now have {incomingPod.extraMonths} extra month to view the pod content!
            </p>
            <p className="pt-8 text-center">Your new expiration date is:</p>
            <p className="text-weight-semibold">
              {moment(incomingPod.expirationDate).format(DATE_FORMAT)}
            </p>
            <Button onClick={goBack} className="py-4 px-10 mt-11">
              Thanks!
            </Button>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ExtraTimeSuccessPage;
