import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useDispatchOnMount, useDispatch } from 'hooks';

export default (action, paginationSelector, loading) => {
  const dispatch = useDispatch(action);
  useDispatchOnMount(action);

  const { page, totalPages } = useSelector(paginationSelector);
  return useCallback(() => {
    if (page >= totalPages) return;
    loading || dispatch(page + 1);
  }, [dispatch, loading, page, totalPages]);
};
