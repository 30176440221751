import React from 'react';
import { number, string } from 'prop-types';

const Wallet = ({ width = 100, height = 95, className }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M83.8379 24.2497C71.2133 17.1685 61.1659 25.8191 48.4849 25.0594C35.7122 24.294 29.5761 16.5918 15.9846 22.4728C3.94483 27.6822 -1.70515 42.5332 6.81405 53.0903C9.24172 56.0987 12.9357 58.1046 14.8226 61.5881C17.8662 67.2077 15.1567 72.2367 16.6365 77.9734C22.7854 101.809 58.7216 87.1503 71.3664 77.673C91.197 62.8098 102.02 34.4484 83.8379 24.2497Z"
      fill="#54B4FF"
    />
    <rect x="15.8828" y="29.0586" width="49.1765" height="45.6" rx="5" fill="#71CBFF" />
    <circle cx="50.7533" cy="13.412" r="12.0706" fill="#71CBFF" />
    <circle cx="33.7653" cy="28.6123" r="12.0706" fill="#71CBFF" />
    <path
      d="M51.7363 12.4085V7.97406C52.7893 8.31517 53.5305 9.11659 53.5305 10.0488C53.5305 10.6743 54.0374 11.1814 54.6631 11.1814C55.2887 11.1814 55.7956 10.6743 55.7956 10.0488C55.7956 7.89747 54.0561 6.09395 51.7365 5.643V5.31288C51.7365 4.68736 51.2295 4.1803 50.6039 4.1803C49.9782 4.1803 49.4713 4.68736 49.4713 5.31288V5.643C47.1517 6.09381 45.4121 7.89732 45.4121 10.0486C45.4121 12.1999 47.1517 14.0034 49.4713 14.4543V18.8884C48.4183 18.5476 47.6771 17.746 47.6771 16.814C47.6771 16.1884 47.1702 15.6814 46.5446 15.6814C45.9189 15.6814 45.412 16.1884 45.412 16.814C45.412 18.9653 47.1515 20.7688 49.4712 21.2196V21.5497C49.4712 22.1752 49.9781 22.6823 50.6037 22.6823C51.2294 22.6823 51.7363 22.1752 51.7363 21.5497V21.2196C54.0559 20.7688 55.7955 18.9653 55.7955 16.814C55.7955 14.6628 54.0559 12.8591 51.7363 12.4085ZM49.4713 12.1233C48.4183 11.7824 47.6771 10.981 47.6771 10.0488C47.6771 9.11659 48.4183 8.31517 49.4713 7.97406V12.1233ZM51.7362 18.8887V14.7394C52.7892 15.0805 53.5303 15.8819 53.5303 16.814C53.5303 17.7461 52.7892 18.5476 51.7362 18.8887Z"
      fill="#2390F3"
    />
    <path
      d="M50.6036 0C43.1976 0 37.1722 6.02523 37.1722 13.4315C37.1721 14.057 37.6792 14.5641 38.3047 14.5641C38.9303 14.5641 39.4372 14.057 39.4372 13.4315C39.4372 7.27448 44.4464 2.26501 50.6036 2.26501C56.7608 2.26501 61.7701 7.27418 61.7701 13.4315C61.7701 19.5887 56.7608 24.5979 50.6036 24.5979C50.1604 24.5979 49.7146 24.5717 49.2787 24.5202C48.6585 24.4474 48.0944 24.8909 48.0211 25.512C47.9478 26.133 48.3917 26.696 49.0128 26.7695C49.5368 26.8314 50.0719 26.8629 50.6035 26.8629C58.0096 26.8629 64.035 20.8377 64.035 13.4315C64.035 6.02537 58.0097 0 50.6036 0Z"
      fill="#2390F3"
    />
    <path
      d="M34.7849 27.1293V22.6951C35.8378 23.0362 36.5791 23.8377 36.5791 24.7697C36.5791 25.3952 37.086 25.9023 37.7116 25.9023C38.3373 25.9023 38.8442 25.3952 38.8442 24.7697C38.8442 22.6184 37.1047 20.8149 34.785 20.3641V20.0339C34.785 19.4084 34.2781 18.9014 33.6525 18.9014C33.0268 18.9014 32.5199 19.4084 32.5199 20.0339V20.3641C30.2003 20.8149 28.4607 22.6184 28.4607 24.7697C28.4607 26.921 30.2003 28.7245 32.5199 29.1753V33.6096C31.467 33.2685 30.7257 32.4671 30.7257 31.5349C30.7257 30.9094 30.2188 30.4023 29.5931 30.4023C28.9675 30.4023 28.4606 30.9094 28.4606 31.5349C28.4606 33.6862 30.2001 35.4897 32.5197 35.9407V36.2708C32.5197 36.8963 33.0267 37.4034 33.6523 37.4034C34.278 37.4034 34.7849 36.8963 34.7849 36.2708V35.9407C37.1045 35.4899 38.8441 33.6862 38.8441 31.5349C38.8441 29.3836 37.1045 27.5799 34.7849 27.1293ZM32.5197 26.8443C31.4669 26.5031 30.7256 25.7017 30.7256 24.7697C30.7256 23.8375 31.4667 23.0361 32.5197 22.6951V26.8443ZM34.7849 33.6096V29.4602C35.8378 29.8013 36.5791 30.6027 36.5791 31.5347C36.5791 32.4669 35.8379 33.2685 34.7849 33.6096Z"
      fill="#2390F3"
    />
    <path
      d="M28.3082 17.0147C27.9865 16.4784 27.2912 16.3043 26.7543 16.6258C22.7244 19.0423 20.2207 23.4594 20.2207 28.1527C20.2207 31.0468 21.1271 33.803 22.8417 36.1236C23.0642 36.4242 23.4066 36.5834 23.7537 36.5834C23.9876 36.5834 24.2233 36.5112 24.4258 36.3616C24.9288 35.9899 25.0353 35.2807 24.6636 34.7778C23.2389 32.8497 22.4859 30.5589 22.4859 28.1528C22.4859 24.1916 24.5169 20.6087 27.9193 18.5684C28.4556 18.2467 28.6297 17.551 28.3082 17.0147Z"
      fill="#2390F3"
    />
    <path
      d="M33.6523 14.7214C32.704 14.7214 31.7567 14.8209 30.8372 15.0171C30.2254 15.1477 29.8353 15.7495 29.9658 16.3611C30.0966 16.9726 30.6985 17.3624 31.3101 17.2323C32.0745 17.069 32.8626 16.9864 33.6524 16.9864C39.8095 16.9864 44.8189 21.9958 44.8189 28.1531C44.8189 30.5591 44.0659 32.8498 42.6412 34.778C42.2697 35.2809 42.3761 35.9901 42.879 36.3618C43.0815 36.5115 43.3172 36.5836 43.5511 36.5836C43.8982 36.5836 44.2408 36.4246 44.4628 36.124C46.1774 33.8035 47.0838 31.0472 47.0838 28.1531C47.0838 20.747 41.0584 14.7214 33.6523 14.7214Z"
      fill="#2390F3"
    />
    <path
      d="M62.9206 28.303H49.243C48.6174 28.303 48.1104 28.81 48.1104 29.4356C48.1104 30.0611 48.6174 30.5681 49.243 30.5681H62.9206C63.3579 30.5681 63.7139 30.9239 63.7139 31.3612V32.8087H48.5381C47.9124 32.8087 47.4055 33.3157 47.4055 33.9412C47.4055 34.5667 47.9124 35.0738 48.5381 35.0738H63.7137V35.8384C63.7137 36.4639 64.2207 36.971 64.8463 36.971C65.472 36.971 65.9789 36.4639 65.9789 35.8384V31.3615C65.9787 29.6748 64.607 28.303 62.9206 28.303Z"
      fill="#2390F3"
    />
    <path
      d="M64.8463 48.1547C65.472 48.1547 65.9789 47.6476 65.9789 47.0221V41.3686C65.9789 39.2216 64.2322 37.4751 62.0854 37.4751H20.3303C18.43 37.4751 16.8835 35.932 16.8771 34.0331V34.0211C16.8771 34.02 16.8769 34.019 16.8769 34.0179C16.8784 32.664 17.6766 31.4293 18.9114 30.8718C19.4814 30.6146 19.735 29.9438 19.4777 29.3738C19.2202 28.8038 18.5497 28.5501 17.9795 28.8075C15.9338 29.7311 14.6119 31.7777 14.6119 34.0218C14.6119 34.0257 14.6122 34.0295 14.6122 34.0334V70.2808C14.6122 73.4343 17.1771 75.9999 20.33 75.9999H62.0852C64.2322 75.9999 65.9787 74.2533 65.9787 72.1064V64.6903C65.9789 64.0648 65.4718 63.5577 64.8463 63.5577C64.2206 63.5577 63.7137 64.0648 63.7137 64.6903V72.1064C63.7137 73.0045 62.9831 73.7349 62.0852 73.7349H20.3299C18.426 73.7349 16.8771 72.1855 16.8771 70.2808V38.5759C17.8373 39.3059 19.0339 39.7401 20.3303 39.7401H62.0852C62.9833 39.7401 63.7137 40.4707 63.7137 41.3686V47.0221C63.7137 47.6476 64.2206 48.1547 64.8463 48.1547Z"
      fill="#2390F3"
    />
    <path
      d="M64.9859 49.0581H56.9145C53.166 49.0581 50.1163 52.1078 50.1163 55.8562C50.1163 59.6047 53.1658 62.6544 56.9145 62.6544H58.9663C59.592 62.6544 60.0989 62.1473 60.0989 61.5218C60.0989 60.8963 59.592 60.3892 58.9663 60.3892H56.9145C54.4149 60.3892 52.3813 58.3556 52.3813 55.8561C52.3813 53.3566 54.4149 51.323 56.9145 51.323H64.9859C65.4646 51.323 65.854 51.7125 65.854 52.1912V59.5212C65.854 59.9999 65.4645 60.3892 64.9859 60.3892H62.7414C62.1157 60.3892 61.6088 60.8963 61.6088 61.5218C61.6088 62.1473 62.1157 62.6544 62.7414 62.6544H64.9859C66.7136 62.6544 68.119 61.2488 68.119 59.5213V52.1913C68.119 50.4635 66.7136 49.0581 64.9859 49.0581Z"
      fill="#2390F3"
    />
    <path
      d="M56.7887 52.4574C54.9146 52.4574 53.3899 53.9821 53.3899 55.8562C53.3899 57.7302 54.9145 59.2549 56.7887 59.2549C58.6628 59.2549 60.1876 57.7303 60.1876 55.8562C60.1876 53.982 58.6627 52.4574 56.7887 52.4574ZM56.7885 56.9898C56.1633 56.9898 55.6547 56.4812 55.6547 55.856C55.6547 55.2308 56.1633 54.7223 56.7885 54.7223C57.4139 54.7223 57.9224 55.231 57.9224 55.856C57.9224 56.4812 57.4139 56.9898 56.7885 56.9898Z"
      fill="#2390F3"
    />
    <path
      d="M26.0389 5.729C25.4133 5.729 24.9064 6.23607 24.9064 6.86158V12.5996C24.9064 13.2251 25.4133 13.7322 26.0389 13.7322C26.6646 13.7322 27.1715 13.2251 27.1715 12.5996V6.86158C27.1715 6.23607 26.6646 5.729 26.0389 5.729Z"
      fill="#2390F3"
    />
    <path
      d="M31.7008 3.46375C31.0752 3.46375 30.5682 3.97081 30.5682 4.59632V10.3343C30.5682 10.9598 31.0752 11.4669 31.7008 11.4669C32.3265 11.4669 32.8334 10.9598 32.8334 10.3343V4.59632C32.8332 3.97081 32.3263 3.46375 31.7008 3.46375Z"
      fill="#2390F3"
    />
  </svg>
);

Wallet.propTypes = {
  width: number,
  height: number,
  className: string
};

export default Wallet;
