import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch, useStatus } from 'hooks';
import { POD_STATUS, OPENING_DATE_FILTER } from 'constants/constants';

import { getOutgoingPods, actionTypes } from 'actions/outgoingPodActions';

import EmptyState from 'components/common/EmptyState';
import PodsList from 'components/common/PodsList';
import GeneralPodItems from 'components/common/GeneralPodItems';
import GeneralDataHandler from 'components/common/GeneralDataHandler';
import generalStyles from 'components/common/GeneralDataHandler/GeneralDataHandler.scss';
import SortSelect from 'components/pods/SortSelect';

import { sortByDateAttribute } from 'utils/helpers';

import messages from './constants';

const OutgoingPodsComp = outgoingPodsList => {
  const [pendingPods, setPendingPods] = useState(outgoingPodsList?.pendingPods);
  const [acceptedPods, setAcceptedPods] = useState(outgoingPodsList?.acceptedPods);

  const handleSortChange = useCallback(
    newValue => {
      const sortedPending = [...pendingPods];
      const sortedAccepted = [...acceptedPods];

      const ascending = newValue.value === OPENING_DATE_FILTER;
      sortedPending.sort((a, b) => sortByDateAttribute(a, b, newValue.value, ascending));
      sortedAccepted.sort((a, b) => sortByDateAttribute(a, b, newValue.value, ascending));

      setPendingPods(sortedPending);
      setAcceptedPods(sortedAccepted);
    },
    [acceptedPods, pendingPods]
  );

  return (
    <div className={`${generalStyles.listContainer} container`}>
      <div className="d-flex justify-content-flex-start">
        <p className="text-gray text-weight-medium my-auto pr-3">SORT BY</p>
        <SortSelect handleChange={handleSortChange} placeholder="" />
      </div>
      {pendingPods?.length || acceptedPods?.length ? (
        <>
          {pendingPods && (
            <PodsList
              title="PENDING ACCEPTANCE PODS"
              list={pendingPods}
              status={POD_STATUS.PENDING}
              ListComponent={GeneralPodItems}
            />
          )}
          {acceptedPods && (
            <PodsList
              title="ACCEPTED PODS"
              list={acceptedPods}
              status={POD_STATUS.ACCEPTED}
              ListComponent={GeneralPodItems}
            />
          )}
        </>
      ) : (
        <EmptyState
          className="mt-9 mt-md-12"
          title={messages.empty}
          subtitle={messages.emptySubtitle}
        />
      )}
    </div>
  );
};

const OutGoingPods = () => {
  const outgoingPodsList = useSelector(({ pods: { outGoingPods } }) => outGoingPods);

  const { status } = useStatus(actionTypes.GET_OUTGOING_PODS);

  const outgoingPods = useDispatch(getOutgoingPods);
  useEffect(() => {
    outgoingPods();
  }, [outgoingPods]);

  return (
    <GeneralDataHandler
      status={status}
      SuccessComponent={() => OutgoingPodsComp(outgoingPodsList)}
    />
  );
};

export default OutGoingPods;
