import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { func, bool, string } from 'prop-types';
import cn from 'classnames';

import api from 'api';
import { createBundlePayment, actionTypes } from 'actions/podActions';
import useLoading from 'hooks/useLoading';
import { useDispatch, useAuth } from 'hooks';
import parseError from 'utils/parseError';

import Arrow from 'components/common/Icons/Arrow';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import routes from 'constants/routesPaths';

import { messages } from './constants';
import styles from './ButtonPanel.scss';

const ButtonPanel = ({
  className,
  currentStep,
  disabled,
  setSubmitDisabled,
  lastStep,
  onSubmit,
  pricingPlan,
  selectedBundle,
  stripe,
  totalPrice,
  email
}) => {
  const isLoading = useLoading(actionTypes.CREATE);
  const history = useHistory();
  const authenticated = useAuth();

  const onCancel = () => {
    // eslint-disable-next-line no-restricted-globals
    if (window.confirm(messages.confirmationAlert)) {
      history.push(routes.index);
      api.post('/pods/cancel');
    }
  };

  const create = useDispatch(createBundlePayment);

  const createAsyncPayment = async e => {
    setSubmitDisabled(true);
    try {
      await create({
        stripe,
        pricingId: selectedBundle.id,
        promoCode: null,
        totalPrice,
        email
      });
      if (authenticated) {
        onSubmit(e);
      } else {
        history.push(routes.signUp);
      }
    } catch ({ data }) {
      // TODO: display error message
      e.preventDefault();
      alert(parseError(data));
      console.log('data', data);
    }
    setSubmitDisabled(false);
  };

  const isBundlePayment = currentStep === 1 && pricingPlan === 'bundle';
  const isFree = totalPrice === 0 ? messages.freePod : messages.purchase;
  const isFreeGift = pricingPlan === 'gift' && totalPrice === 0;

  const buttonText = {
    false: isBundlePayment ? isFree : messages.next,
    true: isFreeGift ? messages.next : messages.submit
  }[lastStep];

  return (
    <div className={cn([styles.buttonContainer, 'py-4 mt-3', className])}>
      <div className={cn(styles.borderTop, 'd-md-none')} />
      <div className="row">
        <div className="col-12 order-2 order-sm-1 col-sm-6 my-auto pt-4 pt-sm-0">
          <a
            className="text-small text-underline d-block text-center text-sm-left"
            onClick={onCancel}
          >
            {messages.cancel}
          </a>
        </div>
        <div className="col-12 order-1 order-sm-2 col-sm-6">
          <div className="d-flex">
            <Button
              disabled={isLoading || disabled}
              type="submit"
              styleType="primary"
              onClick={e => {
                e.preventDefault();
                if (isBundlePayment) {
                  createAsyncPayment(e);
                } else {
                  onSubmit(e);
                }
              }}
              className={cn(styles.button, { 'opacity-muted': disabled })}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <div className={styles.submitWrapper}>
                  <span className={styles.label}>{buttonText}</span>
                  <Arrow type="right" height={30} colorType="light" />
                </div>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ButtonPanel.propTypes = {
  onSubmit: func.isRequired,
  disabled: bool,
  setSubmitDisabled: func,
  lastStep: bool,
  className: string
};

ButtonPanel.defaultProps = {
  className: '',
  disabled: false
};

export default memo(ButtonPanel);
