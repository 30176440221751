import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { instanceOf, string, number, bool } from 'prop-types';
import cn from 'classnames';
import { replaceLandingTab } from 'utils/tabHelpers';
import { LABELS } from 'pages/SignUpPage/tabs';
import Paper from 'components/common/Paper';
import { parseMonths } from 'utils/dateHelpers';
import { useAuth } from 'hooks';

import SizeItems from './SizeItems';
import BundleItems from './BundleItems';
import { messages } from './constants';
import styles from './Summary.scss';

const Summary = ({
  date: { multiplicate: datePrice },
  size: { value: size, price: sizePrice },
  discount,
  discountType,
  subTotal,
  total,
  priceDisclaimer,
  minimumCost,
  pricingPlan
}) => {
  const months = datePrice % 12;
  const years = Math.floor(datePrice / 12);

  const timeTag = parseMonths(years, months);

  const discountPrefix = discountType === 'amount' ? '$' : '%';
  const discountText =
    discountType === 'amount'
      ? `${discountPrefix} ${discount?.toFixed(2)}`
      : `${discount.toFixed(2)} ${discountPrefix}`;

  return (
    <Paper>
      <div className="p-5">
        <p className="text-weight-semibold text-blue">{messages.title}</p>
        <div className="pt-2">
          {pricingPlan === 'size' && (
            <SizeItems
              size={size}
              sizePrice={sizePrice}
              timeTag={timeTag}
              datePrice={datePrice}
              subTotal={subTotal}
              discount={discount}
              discountText={discountText}
            />
          )}
          {pricingPlan === 'bundle' && (
            <BundleItems
              size={size}
              sizePrice={sizePrice}
              discount={discount}
              discountText={discountText}
            />
          )}
          {pricingPlan === 'gift' && !localStorage.getItem('pod_id') && <p>Free | gift</p>}
        </div>
      </div>
      {!localStorage.getItem('pod_id') && (
        <div className="">
          <div
            className={cn('d-flex flex-column justify-content-between px-5 py-3', styles.border)}
          >
            <div className="d-flex justify-content-between">
              <span className="text-weight-bold text-center">{messages.total}</span>
              <span className="text-weight-bold">$ {Math.floor(total * 100) / 100}</span>
            </div>
            {priceDisclaimer && (
              <p className="text-x-small text-gray pt-3">
                {messages.priceDisclaimer[0] + minimumCost + messages.priceDisclaimer[1]}
              </p>
            )}
          </div>
        </div>
      )}

      {!useAuth() && (
        <div className="text-right p-4">
          <Link to={replaceLandingTab(LABELS.login)} className="clickable">
            <u className="text-blue text-weight-bold">
              Already have an account? Click here to login
            </u>
          </Link>
        </div>
      )}
    </Paper>
  );
};

Summary.propTypes = {
  size: instanceOf({ value: string, price: number }).isRequired,
  date: instanceOf({ value: string, multiplicate: number }).isRequired,
  discount: number,
  total: number,
  subTotal: number,
  priceDisclaimer: bool,
  minimumCost: number.isRequired
};

Summary.defaultProps = {};

export default memo(Summary);
